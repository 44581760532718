import React from "react";
import {getStatus, getSteps} from "../../components/utils";
import {Box, StepLabel} from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Typography from "@mui/material/Typography";

const ProgressStepper = ({
    status
}) => {
    const steps = getSteps().filter(it => it.value !== "INVALID");
    const statusInfo = getStatus(status);
    const activeStep = statusInfo.step;
    return (
        <Box py={4}>
            <Stepper color={"secondary"} sx={{mb: 2, mt: 2, maxWidth: "75%", ml: "12.5%"}}>
                {steps.map((it) => {
                    let {label, description} = it;
                    const index = it.step;

                    const stepProps = {completed: false};
                    const labelProps = {optional: false};
                    if (activeStep === index) {
                        labelProps.optional = (
                            <Typography variant="caption">{description}</Typography>
                        );
                    }
                    if (activeStep > index || statusInfo.value === "COMPLETE") {
                        stepProps.completed = true;
                    }
                    let sx = {};
                    if (it.value === "PENDING_VERIFICATION" && status === "INVALID") {
                        const invalidStep = getStatus("INVALID");
                        label = invalidStep.label;
                        labelProps.error = true;
                        labelProps.optional = (
                            <Typography variant="caption">{invalidStep.description}</Typography>
                        );
                    }
                    return (
                        <Step key={label} active={activeStep === index} {...stepProps} sx={sx}>
                            <StepLabel {...labelProps} sx={sx}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};
export default ProgressStepper;
