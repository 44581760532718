import React from "react";
import { darken } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {FormattedMessage, formatMessage, Paper, Main, Button, Select, Text} from "@armus/armus-dashboard";
import Container from "../../../data/Container";
import UserStore from "../../../data/UserStore";
import { buildUrl } from "../../../../routes";
import history from "../../../lib/history";
import ErrorPage from "../../ErrorPage";
import HomeTable from "./HomeTable";
import api from "../../../lib/api";
import {getSteps} from "../../Signup/components/utils";

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        overflow: "scroll"
    },
    paper: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    },
    tabsContainer: {
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        flexGrow: 1
    },
    tabs: {
        borderBottom: "1px solid " + darken(theme.palette.background.default, 0.25),
        boxShadow: "0 2px 2px rgba(0,0,0,.25)",
        "& .MuiTabs-flexContainer": {
            borderBottom: "3px solid " + theme.palette.background.default

        },
        "& .MuiTab-root.Mui-selected": {
            fontWeight: "bold"
        },
        "& .MuiTabs-indicator": {
            height: 3
        }
    }
}));

export const homeBreadcrumb = (view, year, org = {}, impl = {}) => {
    return {
        label: formatMessage({
            id: "verification.home.breadcrumb",
            defaultMessage: "{year} Verification Manager"},
            {orgName: org.name, implName: impl.name, year}
        ),
        url: buildUrl("VERIFICATION", {view: "verification", year, orgKey: org.key, implKey: impl.key})
    };
};

const Home = ({
    isValidURL,
    view,
    year,
    organization,
    implementation,
    onDetailsClick
}) => {
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const [search, setSearch] = React.useState("");
    const [localSearch, setLocalSearch] = React.useState("");
    const [status, setStatus] = React.useState("PENDING_VERIFICATION");
    const [page, setPage] = React.useState(0);
    const [size, setSize] = React.useState(10);
    const [sort, setSort] = React.useState("orgKey");
    const [order, setOrder] = React.useState("asc");
    const [isLoading, setIsLoading] = React.useState(true);

    const fetchVerificationList = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.loadVerificationListData(
                year,
                organization.key,
                implementation.key,
                search,
                status,
                sort,
                order,
                page,
                size
            );
            if(res.status === 200) {
                setItems(res.data);
            }
        }
        catch(error) {
            alert("Something unexpected happened, Please try again in a few moments.");
        }
        setIsLoading(false);
    }, [
        year,
        organization.key,
        implementation.key,
        search,
        status,
        sort,
        order,
        page,
        size
    ]);

    const handleExport = async () => {
        await api.exportVerificationListData(
            year,
            organization.key,
            implementation.key,
            search,
            status
        );
    };

    React.useEffect(() => {
        fetchVerificationList();
    }, [ fetchVerificationList ]);

    if(!isValidURL) {
        // the url is invalid show 404 page.
        return <ErrorPage statusCode={404} />;
    }

    const breadcrumb = homeBreadcrumb(view, year, organization, implementation);

    const canCreate = view !== "verification";

    const title = view !== "verification" ? "Submitting Entity Signup" : "Verification Manager";

    const statusOptions = getSteps().map(it => ({value: it.value, label: it.label}));
    statusOptions.unshift({value: "", label: "All"});

    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}, breadcrumb]} fullWidth={true} fullHeight={true}>
            <Box className={classes.content}>
                <Paper className={classes.paper}>
                    <Box p={3}>
                        <Grid container spacing={2} flex={true}>
                            <Grid item xs>
                                <Typography component="h1" variant="h4">
                                    <FormattedMessage
                                        id="consent.title"
                                        defaultMessage={`{year} ${title}`}
                                        values={{orgName: organization.name, implName: implementation.name, year}}
                                    />
                                </Typography>
                                <Typography sx={{opacity: .5}}>
                                    Welcome to MIPS {title}.
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Box marginTop={1} textAlign="right">
                                    {canCreate && <Button
                                        color={"primary-alt"}
                                        size={"large"}
                                        onClick={(e, v) => onDetailsClick(v.value)}
                                        options={[
                                            {
                                                label: "Group Submitting Entity",
                                                value: "new-group"
                                            }, {
                                                label: "Individual Submitting Entity",
                                                value: "new-individual"
                                            }
                                        ]}
                                    >
                                        + Add Submitting Entity
                                    </Button>}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <Paper p={2} m={3}>
                    <Text
                        isLoading={false}
                        fullWidth={false}
                        label={"Search"} onChange={(e, v) => setLocalSearch(v || "")}
                        onBlur={(e, v) => setSearch(v || "")}
                        value={localSearch} sx={{mr: 2, minWidth: 300}}
                    />
                    <Select
                        isLoading={false}
                        fullWidth={false}
                        options={statusOptions}
                        label={"Status"}
                        onChange={(e, v) => setStatus(v)}
                        value={status}
                    />
                    <Button color={"primary-alt"} size={"medium"} sx={{ml: 2, mt:1}}>
                        Search
                    </Button>
                    <Button color={"secondary"} size={"medium"} sx={{ml: 1, mt: 1}} onClick={handleExport}>
                        Export
                    </Button>
                </Paper>
                <HomeTable
                    isLoading={isLoading}
                    items={items}
                    view={view}
                    year={year}
                    organization={organization}
                    implementation={implementation}

                    page={page}
                    size={size}
                    order={order}
                    sort={sort}

                    onEditClick={(row) => onDetailsClick(row)}
                    onChangePage={(e, v) => {
                        setPage(v);
                    }}
                    onChangeRowsPerPage={(e) => {
                        setSize(e.target.value);
                    }}
                    onRequestSort={(e, v) => {
                        setSort(v);
                        if(sort === v) {
                            if(order === "asc") {
                                setOrder("desc");
                            }
                            else {
                                setOrder("asc");
                            }
                        }
                        else {
                            setOrder("asc");
                        }
                    }}
                />
            </Box>
        </Main>
    );
};

export default Container(
    Home,
    () => [
        UserStore
    ],
    (state, props) => {
        const params = props.match.params;
        const year = params.year;
        const view = params.view;
        const orgKey = params.orgKey;
        const implKey = params.implKey;
        const org = UserStore.getOrganization(orgKey);
        const impl = UserStore.getImplementation(orgKey, implKey);
        const isValidURL = !!org && !!impl;
        return {
            ...props,
            isValidURL,
            year,
            view,
            organization: org || {},
            implementation: impl || {},
            isLoading: UserStore.isLoading(),
            onDetailsClick: (row) => {
                const url = buildUrl(view.toUpperCase() + "_DETAILS", {view: view, year: year, orgKey: row.orgShortname, implKey: implKey, consentId: row.submitter.id});
                history.push(url);
            }
        };
    }
);
