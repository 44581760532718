import React, {useEffect, useState} from "react";
import api from "../../lib/api";
import {LaunchLayout, Main} from "@armus/armus-dashboard";
import {Box} from "@mui/material";

const HasDRCF = ({year, orgKey, implKey, children}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasDRCF, setHasDRCF] = useState(false);

    const checkDRCF = React.useCallback(async () => {
        setIsLoading(true);
        let res = {status: 500};
        try {
            res = await api.hasDRCF(year, orgKey, implKey);
        }
        catch(error) {}
        if(res.status === 200) {
            console.log(res.data);
            setHasDRCF(res.data.hasDRCF);
        }
        setIsLoading(false);
    }, [year, orgKey, implKey]);

    useEffect(() => {
        checkDRCF();
    },[checkDRCF]);

    if(hasDRCF) {
        return children;
    }

    if(isLoading) {
        return <LaunchLayout />;
    }
    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}]} fullWidth={true} fullHeight={true}>
            <Box p={6} textAlign={"center"}>Please complete the DRCF for this Organization.</Box>
        </Main>
    );
};

export default HasDRCF;
