import React from "react";
import { Slider as BaseSlider } from '@mui/material';
import {
    buildPropTypesFromObject,
    buildPropTypesWithDescriptor
} from "../../lib/propTypeHelpers";
import makeSxStyles from "../../lib/makeSxStyles";
import Spinner from "../common/Spinner";
import Outline from "./components/Outline";

const useStyles = makeSxStyles((theme) => ({
    root: {
        marginTop: 1,
        marginBottom: .5,
        height: 37.13,
        "& .MuiInputBase-root": {
            padding: "4px 18px!important",
        },
        "& .MuiSlider-root": {
            "& .MuiSlider-thumb": {
                width: 22,
                height: 22,
            }
        },
        "& .MuiInputBase-input": {
            height: 29,
        }
    }
}));

const Slider = ({isLoading, value, label, descriptor, disabled, onClick, onChange, onBlur, onFocus, onKeyDown, required, dense, ...extraProps}) => {
    const classes = useStyles();
    const cfg = defaultConfig(descriptor.config);
    const handleChange = (e) => {
        onChange && onChange(e, e.target.value, descriptor);
    };
    label = descriptor.label || label;
    required = descriptor.required === true ? true : required;
    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        disabled = true;
    }
    return (
        <Outline
            sx={classes.root}
            label={(label)}
            margin={(dense || cfg.dense) ? "dense" : undefined}
            title={descriptor.description}
            fullWidth={true}
            required={required}
            disabled={false}
            onClick={(e) => onClick && onClick(e, value, descriptor)}
        >
            <BaseSlider
                id={descriptor.id}
                name={descriptor.id}
                title={descriptor.description}
                required={required}
                disabled={disabled}
                variant="outlined"
                label={label}
                format={cfg.format}
                value={value || ""}
                onChange={handleChange}
                onBlur={(e) => onBlur && onBlur(e, value || null, descriptor)}
                onClick={(e) => onClick && onClick(e, value || null, descriptor)}
                onFocus={(e) => onFocus && onFocus(e, value || null, descriptor)}
                onKeyDown={(e) => onKeyDown && onKeyDown(e, value || null, descriptor)}
                fullWidth={true}
                margin={cfg.dense ? "dense" : undefined}
                InputProps={isLoading ? {
                    ...extraProps.InputProps,
                    endAdornment: (
                        <Spinner size={18} overlay={true} />
                    ),
                } : extraProps.InputProps}
                {...extraProps}
            />
        </Outline>
    );
};

Slider.defaultProps = {
    value: null,
    data: [],
    options: [],
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            dense: true,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Slider.defaultProps.descriptor.config,
    ...config
});

Slider.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));
export default Slider;