import React from "react";
import {Box, Divider, Grid, IconButton} from "@mui/material";
import Typography from "@mui/material/Typography";
import {
    DeleteForever,
    DescriptionOutlined,
    DoNotDisturb,
    FileDownload,
    UploadFile
} from "@mui/icons-material";
import {Button, Text, Typeahead, Paper, DatePicker, useTheme, Select} from "@armus/armus-dashboard";
import {getTypeLabel} from "../../components/utils";
import api from "../../../../lib/api";
import {alpha} from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Link from "@mui/material/Link";

const getNpiShape = () => {
    return {
        id: "",
        npi: "",
        name: "",
        invalid: false,
        isLoading: false
    };
};

const StatusNew = ({
    isLoading,
    year,
    orgKey,
    implKey,
    data,
    onClose,
    onSave,
    onLoad
}) => {
    const {theme} = useTheme();
    const [localData, setLocalData] = React.useState(data);
    const [progress, setProgress] = React.useState(0);
    const [dropZoneStatus, setDropZoneStatus] = React.useState(0);
    const [signupDocuments, setSignupDocuments] = React.useState(false);
    const [physicianOptions, setPhysicianOptions] = React.useState([]);
    const [loadingPhysicians, setLoadingPhysicians] = React.useState(true);


    const loadPhysicianOptions = React.useCallback(async () => {
        setLoadingPhysicians(true);
        const res = await api.loadConsentPhysicianListData(year, orgKey, implKey);
        if(res.status === 200) {
            setPhysicianOptions(res.data.map(it => ({value: it.npi, label: `${it.name} (${it.npi})`})));

        }
        setLoadingPhysicians(false);
    }, [year, orgKey, implKey]);

    const loadNpiInfo = async (npis = []) => {
        npis = npis.filter(it => (it || "").trim());
        if(npis.length === 0) {
            // nothing to do
            return;
        }
        setLocalData((old) => {
            // set isLoading for npi field
            const nextNpiList = old.npiList.map((it) => {
                const nextNpi = {isLoading: false, ...it };
                if(npis.includes(nextNpi.npi)) {
                    nextNpi.isLoading = true;
                    nextNpi.name = "";
                }
                return nextNpi;
            });
            return {
                ...old,
                npiList: nextNpiList
            };
        });

        const res = await api.loadSubmitCMSNPIData(npis);
        if(res.status === 200) {
            setLocalData((localData) => {
                const nppes = res.data.data;
                const nextNpiList = localData.npiList.map(it => ({...it}));
                nextNpiList.forEach((nextNpi) => {
                    const it = nppes.find(it => it.npi === nextNpi.npi) || {error: {message: "Please supply a valid NPI"}};
                    delete nextNpi.isLoading;
                    if(it && !it.error) {
                        nextNpi.name = [it.firstName, it.middleName, it.lastName].join(" ");
                        nextNpi.invalid = false;
                    }
                    else if (npis.includes(nextNpi.npi)) {
                        nextNpi.invalid = it.error.message;
                        nextNpi.name = "";
                    }
                });
                return {
                    ...localData,
                    npiList: nextNpiList
                };
            });
        }
        else {
            // error
            setLocalData((localData) => {
                // remove isLoading
                const nextNpiList = localData.npiList.map(it => ({...it}));
                nextNpiList.forEach((nextNpi) => {
                    if (npis.includes(nextNpi.npi)) {
                        delete nextNpi.isLoading;
                        nextNpi.invalid = "An error occurred during validation";
                    }
                });
                return {
                    ...localData,
                    npiList: nextNpiList
                };
            });
        }
    };

    const handleNext = async () => {
        // clean localData
        const saveData = {
            ...localData,
            npiList: localData.npiList.map(it => {
                const newNpi = {...it};
                delete newNpi.isLoading;
                delete newNpi.invalid;
                return newNpi;
            })
        };
        await onSave(saveData);
        if(localData.status === "COMPLETE") {
            onClose();
        }
        else {
            setSignupDocuments(true);
        }
    };

    const handleSubmit = async () => {
        const saveData = {
            ...localData,
            status: "PENDING_VERIFICATION"
        };
        await onSave(saveData);
        setSignupDocuments(false);
        onClose();
    };

    const handleFileUpload = async (file) => {
        setProgress(1);
        try {
            const res = await api.uploadConsentDocument(
                year,
                orgKey,
                implKey,
                localData.id,
                file,
                (percent) => {
                    setProgress(percent);
                }
            );
            if (res.status === 200) {
                onLoad(false); // refresh without showing loading spinner.
            }
        }
        catch {
            alert("Something unexpected happened, Please try again in a few moments.");
        }
        setProgress(0);
    };

    const handleDropZoneDrop = (e) => {
        e.preventDefault();
        const items = e.dataTransfer.items;
        if(items.length === 1 && items[0].type === 'application/pdf') {
            const file = e.dataTransfer.files[0];
            handleFileUpload(file);
        }
        else {
            setDropZoneStatus(0);
        }
    };

    const handleDropZoneDragOver = (e, isOver) => {
        e.preventDefault();
        if(isOver) {
            const items = e.dataTransfer.items;
            if(items.length === 1 && items[0].type === 'application/pdf') {
                setDropZoneStatus(1);
                return;
            }
            setDropZoneStatus(-1);
        }
        else {
            setDropZoneStatus(0);
        }
    };

    const handleFileUploadClick = (e) => {
        e.preventDefault();
        const el = document.createElement("INPUT");
        el.type = "file";
        el.accept = "application/pdf";
        el.addEventListener('change', () => {
            if (el.files.length) {
                const file = el.files[0];
                handleFileUpload(file);
            }
        });
        el.click();
    };


    const handleDelete = (index) => {
        setLocalData((localData) => {
            return {
                ...localData,
                npiList: localData.npiList.filter((it, i) => i !== index)
            };
        });
    };

    const handleAdd = () => {
        setLocalData((localData) => {
            return {
                ...localData,
                npiList: [
                    ...(localData.npiList || []),
                    getNpiShape()
                ]
            };
        });
    };

    const handleChange = (key, value) => {
        setLocalData((old) => ({...old, [key]: value}));
    };

    const handleNpiChange = (index, key, value) => {
        let hasInvalid = false;
        setLocalData((old) => {
            const npiList = old.npiList;
            npiList[index] = {
                ...npiList[index],
                [key]: value
            };
            if(key === "npi") {
                npiList[index].name = "";
                if(value === "") {
                    npiList[index].invalid = "NPI can not be blank.";
                    hasInvalid = true;
                }
                else if(npiList.filter(it => it.npi === value).length > 1) {
                    npiList[index].invalid = "NPI is already entered. (No duplicates)";
                    hasInvalid = true;
                }
            }
            return ({
                ...old,
                npiList: npiList
            });
        });
        if(key === "npi" && value !== "" && !hasInvalid) {
             loadNpiInfo([value]);
        }
    };

    // MS-363 - Disabling because CMS only allows for 10 results, and we may have up to 100 npi?
    // React.useEffect(() => {
    //     loadNpiInfo(data.npiList.map(it => it.npi));
    // }, [data.npiList]);

    React.useEffect(() => {
        loadPhysicianOptions();
    }, [loadPhysicianOptions]);

    React.useEffect(() => {
        setLocalData(data);
    }, [data]);

    const npis = localData.npiList.length ? localData.npiList : [getNpiShape()];
    const isValidTin = localData.tin && localData.tin.length === 9;
    const canSubmit = signupDocuments === true ? (
            !!data.uploadedVerificationDocumentBucketKey
        ) : (
            !npis.filter(it => !it.npi || !!it.invalid || it.isLoading).length
            && !!localData.tinDataBoundStartDate
            && !!localData.tinDataBoundEndDate
            && isValidTin
            && (localData.entityType === "individual" || (localData.entityType === "group" && !!localData.groupName))
            && (localData.entityType === "individual" || (localData.entityType === "group" && localData.npiList.length >= 2))
        );

    let dropZoneColor;
    let DownloadIcon;
    switch(dropZoneStatus) {
        case 1:
            dropZoneColor = theme.palette.success.main;
            DownloadIcon = FileDownload;
            break;
        case -1:
            dropZoneColor = theme.palette.error.main;
            DownloadIcon = DoNotDisturb;
            break;
        case 0:
        default:
            dropZoneColor = "#888888";
            DownloadIcon = UploadFile;
    }
    return (
        <Paper sx={{maxWidth: "75%", ml: "12.5%"}}>
            <Box p={4}>
                {signupDocuments === true ? (
                    <>
                        <Typography variant={"h3"}>{getTypeLabel(localData.entityType)} Signup Supporting Documents</Typography>
                        <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>
                            {localData.entityType === "group" ?
                                `Please upload your 1500 form for MIPS Submission and TIN verification. This should be the TIN that is used by an individual provider in the group that reflects the TIN that all providers use to bill for their professional services.`
                                :
                                `Please upload your 1500 form for MIPS Submission and TIN verification. This should reflect the TIN that is used to bill for the indicated Provider's professional services.`
                            }
                        </Typography>

                        <Download1500Form year={year} orgKey={orgKey} implKey={implKey} data={localData} />

                        {localData.status !== "COMPLETE" && (
                            <Box
                                id="drop_zone"
                                onDrop={handleDropZoneDrop}
                                onDragOver={(e) => handleDropZoneDragOver(e, true)}
                                onDragLeave={(e) => handleDropZoneDragOver(e, false)}
                                sx={{
                                    border: !dropZoneStatus ? "1px solid " + dropZoneColor : "1px solid " + dropZoneColor,
                                    background: alpha(dropZoneColor, .05),
                                    px: 10,
                                    py: 4,
                                    pb: 2,
                                    borderRadius: 4,
                                    textAlign: "center"
                                }}
                            >
                                <DownloadIcon sx={{fontSize: 100, color: dropZoneColor, opacity: .35}}/>
                                <Typography variant={"subtitle1"} sx={{my: 2}}>
                                    Drag & Drop your supporting 1500 Form here. <br/>Only PDF files are accepted.<br/>
                                </Typography>
                                <Button size={"large"} color={"default"} onClick={handleFileUploadClick}>Upload
                                    File</Button>
                                <LinearProgress
                                    variant={"determinate"}
                                    value={progress}
                                    sx={{mt: 2, opacity: progress ? 1 : 0}}/>
                            </Box>
                        )}
                    </>
                ) : (
                    <>
                        <Typography variant={"h2"}>{getTypeLabel(localData.entityType)} Submitting Entity Form</Typography>
                        <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>Please fill out the requested
                            information to be considered for MIPS Submission.</Typography>
                        <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>TIN Information</Typography>
                        <Box ml={4}>
                            {localData.entityType === "group" && <Grid container spacing={2} mb={2}>
                                <Grid item xs={5}>
                                    <Text
                                        label={"Group Name"}
                                        value={localData.groupName || ""}
                                        size={"large"}
                                        onChange={(e, v) => handleChange("groupName", v)}
                                        disabled={localData.status === "COMPLETE"}
                                    />
                                </Grid>
                                <Grid item xs={7} alignSelf={"center"}>
                                    <Box pl={2}>Provide the group name for the submitting TIN and NPIs.</Box>
                                </Grid>
                            </Grid>}
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={5}>
                                    <Text
                                        label={"TIN"}
                                        value={localData.tin || ""}
                                        size={"large"}
                                        onChange={(e, v) => handleChange("tin", v)}
                                        disabled={localData.status === "COMPLETE"}
                                        error={localData.tin && !isValidTin}
                                    />
                                </Grid>
                                <Grid item xs={7} alignSelf={"center"}>
                                    <Box pl={2}>
                                        Reporting is done under the TIN which the eligible clinician reporting use to bill for
                                        professional services. This is typically the Practice TIN, not the Facility TIN.
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={5}>
                                    <Box ml={2} mb={0}>
                                        <Typography fontWeight={600}>Submission Data Start Date:</Typography>
                                    </Box>
                                    <DatePicker
                                        //label={"Submission Data Start Date"}
                                        value={localData.tinDataBoundStartDate}
                                        size={"large"}
                                        onChange={(e, v) => handleChange("tinDataBoundStartDate", v.format("YYYY-MM-DD"))}
                                        disabled={localData.status === "COMPLETE"}
                                    />
                                </Grid>
                                <Grid item xs={7} alignSelf={"center"}>
                                    <Box pl={2} mt={3}>Provide the date range for which this TIN was used in the performance year.</Box>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} mb={2}>
                                <Grid item xs={5}>
                                    <Box ml={2} mb={0}>
                                        <Typography fontWeight={600}>Submission Data End Date:</Typography>
                                    </Box>
                                    <DatePicker
                                        //label={"Submission Data End Date"}
                                        value={localData.tinDataBoundEndDate}
                                        size={"large"}
                                        onChange={(e, v) => handleChange("tinDataBoundEndDate", v.format("YYYY-MM-DD"))}
                                        disabled={localData.status === "COMPLETE"}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider sx={{my: 4}}/>
                        <Typography variant={"h3"} fontWeight={500} sx={{mt: 2}}>NPI Information</Typography>
                        {localData.entityType === "group" && <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>Please list all MIPS-eligible clinicians under the group's TIN, regardless of their participation status in the GIQuIC registry in terms of data submission.</Typography>}

                        <Grid container spacing={2} mb={0}>
                            <Grid item xs={0}>
                                <Box sx={{mt: 3}} minWidth={8*2} textAlign={"right"}>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant={"h5"} fontWeight={500} sx={{mt: 2}}>Registry NPI</Typography>
                                <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>Physician name is from the registry.</Typography>
                            </Grid>
                            {localData.entityType === "group" && <Grid item xs={0} sx={{float: "right"}}>
                                <Box
                                    sx={{mt: 2.25, textAlign: "left", opacity: .75, width: 35}}
                                >
                                </Box>
                            </Grid>}
                            <Grid item xs={5}>
                                <Typography variant={"h5"} fontWeight={500} sx={{mt: 2}}>CMS NPI</Typography>
                                <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>Physician name or info is directly from CMS.</Typography>
                            </Grid>
                        </Grid>
                        {npis.map((it, index) => (
                            <Grid key={index + it.npi} container spacing={2} mb={1}>
                                <Grid item xs={0}>
                                    <Box sx={{mt: 3}} minWidth={8*2} textAlign={"right"}>

                                        {(localData.entityType === "group" ? `${index + 1}.` : null)}
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    {localData.entityType === "individual" ? (
                                        <Select
                                            isLoading={it.isLoading || loadingPhysicians}
                                            label={"NPI"}
                                            size={"large"}
                                            value={it.npi || ""}
                                            options={physicianOptions}
                                            onChange={(e, v) => !it.isLoading && handleNpiChange(index, "npi", v || "")}
                                            error={!!it.invalid}
                                        />
                                    ) : (
                                        <Typeahead
                                            isLoading={it.isLoading || loadingPhysicians}
                                            label={"NPI"}
                                            size={"large"}
                                            value={it.npi || ""}
                                            options={[{label: (it.npi || ""), value: it.npi || ""}].concat(physicianOptions)}
                                            freeSolo={localData.entityType !== "individual"}
                                            disableClearable={false}
                                            autoSelect={false}
                                            groupBy={undefined}
                                            onChange={(e, v) => !it.isLoading && handleNpiChange(index, "npi", v || "")}
                                            onBlur={(e) => handleNpiChange(index, "npi", e.target.value || "")}
                                            error={!!it.invalid}
                                            getOptionLabel={(option) => option.value || ""}
                                        />
                                    )}
                                </Grid>
                                {localData.entityType === "group" && <Grid item xs={0} sx={{float: "right"}}>
                                    <Box
                                        sx={{mt: 2.25, textAlign: "left", opacity: .75, width: 35}}
                                    >
                                        <IconButton
                                            onClick={() => handleDelete(index)}
                                        >
                                            <DeleteForever color={"black"} />
                                        </IconButton>
                                    </Box>
                                </Grid>}
                                <Grid item xs={5} alignSelf={"center"}>
                                    {it.name} {(!!it.invalid ? <Typography component={"span"} color={"error"}>{`${!!it.name ? ' - ' : ''}${it.invalid}`}</Typography> : "")}
                                </Grid>
                            </Grid>
                        ))}
                        {localData.entityType === "group" && (
                            <>
                                <Divider sx={{my: 2}}/>
                                <Button
                                    color={"secondary"}
                                    size={"medium"}
                                    fullWidth={true}
                                    sx={{mb: 2}}
                                    onClick={handleAdd}
                                >
                                    + Add Another NPI
                                </Button>
                            </>
                        )}
                    </>
                )}

                {localData.status === "COMPLETE" && <Download1500Form year={year} orgKey={orgKey} implKey={implKey} data={localData} /> }

                <Box textAlign={"right"} mt={4}>
                    {signupDocuments === true ? <>
                        <Button
                            color={"secondary"}
                            size={"large"}
                            onClick={() => setSignupDocuments(false)}
                        >
                            Back
                        </Button> &nbsp;
                        <Button
                            color={"primary-alt"}
                            size={"large"}
                            onClick={handleSubmit}
                            disabled={!canSubmit}
                        >
                            Submit
                        </Button>
                    </> : <>
                        <Button color={"secondary"} size={"large"} onClick={() => onClose()}>Cancel</Button> &nbsp;
                        <Button
                            color={"primary-alt"}
                            size={"large"}
                            onClick={handleNext}
                            disabled={!canSubmit}
                        >
                            { localData.status === "COMPLETE" ? `Submit` : `Next` }
                        </Button>
                    </>}
                </Box>
            </Box>
        </Paper>
    );
};

export default StatusNew;



export const Download1500Form = ({year, orgKey, implKey, data}) => {
    const handleDownload = () => {
        api.getConsentDocument(year, orgKey, implKey, data.id);
    };
    if(!data.uploadedVerificationDocumentBucketKey) {
        return null;
    }
    return (
        <>
            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>1500 Form</Typography>
            <Box
                sx={{
                    border: "1px solid #dddddd",
                    background: alpha("#888888", .05),
                    padding: 4,
                    borderRadius: 4,
                    textAlign: "center",
                    mb: 2
                }}
            >
                <Link onClick={handleDownload} underline={"hover"}>
                    <DescriptionOutlined sx={{fontSize: 50, color: "inherit", opacity: 1}} />
                    <br/>
                    1500Form.pdf
                </Link><br/>
                Use the link above to download your previously submitted 1500 Form.
            </Box>
        </>
    );
};
