import config from "../../config";
import axios from "axios";
import {formatMessage, cookie} from "@armus/armus-dashboard";
import {cachePromise, debouncePromise, chunkArray} from "./utils";
import queryString from "query-string";

// Create an axios instance for api requests
const api = axios.create({
    baseURL: config.endpoints.apiRoot,
    timeout: 60000,
    headers: { "X-Custom-Header": "armus" }
});

// Set up Axios Interceptors.
// These functions will get called before every request/response.
if(process.env.NODE_ENV === "development") {
    if (config.mocks.useMocks) {
        require("./mocks").mockInterceptor(api); // adds the mock interceptor
    }
}

api.interceptors.request.use(function (request) {
    switch(request.method) {
        case "put":
        case "post":
            request.headers = {
                ...(request.headers || {}),
                "Content-Type": "application/json",
                [config.session.CSRF_POST_KEY]: cookie.get(config.session.CSRF_COOKIE_KEY) // add CSRF token to header.
            };
            break;
        default:
    }
    return request;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    // Do something with Status Codes in the 200's range
    switch(response.status) {
        case 200:
        case 302:
            break;
        default:
            const error = {
                response,
                message: formatMessage({id: "apiErrorMessage"})
            };
            return Promise.reject(error);
    }
    return response;
}, function (error) {
    const response = error.response || {};
    let message = "";
    // Do something with all other Status Codes.
    if([undefined, 401, 403].includes(response.status)) {
        // status code 4xx or (canceled/aborted undefined)
        message = "You are not authorized to make that request.";
        if(response.status === undefined) {
            // set the status code to 401
            response.status = 401;
        }
        else if(response.status >= 500) {
            message = formatMessage({id: "apiErrorMessage"});
        }
        console.error(`API Error: Status Code "${response.status}"`, error.message, response.config);
    }
    else {
        message = "Something unexpected happened, Please try again in a few moments.";
        console.error(`API Error: Status Code "${response.status}"`, error.message, response.config);
    }
    console.log(message);
    return Promise.reject(error);
});

// now for the actual API helper functions.
const loadUserData = () => {
    // request the user data
    return api.get("/user");
};

const logoutUser = () => {
    // create form and submit post to logout
    var form = document.createElement("form");
    form.method = "POST";
    form.action = config.endpoints.logout;

    // Add the CSRF token
    var _csrf = document.createElement("input");
    _csrf.setAttribute("type", "hidden");
    _csrf.setAttribute("name", "_csrf");
    _csrf.setAttribute("value", cookie.get(config.session.CSRF_COOKIE_KEY));
    form.appendChild(_csrf);

    document.body.appendChild(form);
    form.submit();
    // cleanup DOM
    form.parentNode.removeChild(form);
    return Promise.resolve(true);
};

const loadPerformanceYearsMetaData = cachePromise(() => {
    return api.get(`/mips/py`);
});


// Submit API Requests
const loadSubmitPerformanceYearMetaData = cachePromise((year) => {
    return api.get(`/mips/py/${year}`);
});

const loadSubmitNPIData = (year, orgKey, implKey) => {
    // request the npi data
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}`);
};

const loadSubmitCMSNPIData = cachePromise((npis) => {
    // this api call can only return 10 npis at a time.
    // so we will batch the requests into one promise and
    // return the final result after all chunks have completed
    const chunks = chunkArray(npis, 10);
    let res = {status: 500};
    return new Promise(async (resolve, reject) => {
        let results = [];
        for (let i = 0; i < chunks.length; i++) {
            const chunk = chunks[i];
            try {
                res = await axios.get(`${config.endpoints.cmsRoot}/api/eligibility/npis/${chunk.join(",")}`);
            }
            catch(error) {
                reject(error);
            }
            results = results.concat(res?.data?.data || []);
        }
        resolve({
            ...res,
            status: 200,
            data: {
                data: results
            }
        });
    });
});

const loadSubmitMeasuresMetaData = cachePromise((year, orgKey, implKey) => {
    // request the measures meta data
    return api.get(`/mips/py/${year}/${implKey}`);
});


const loadSubmitDraftData = (year, orgKey, implKey, npiId) => {
    // request the draft data
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/${npiId}/draft`);
};

const loadSubmitScoreData = (year, orgKey, implKey, npiId) => {
    // request the draft data
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/${npiId}/draft/score`);
};

const saveSubmitDraftData = (year, orgKey, implKey, npiId, measurementSet) => {
    // save the draft data
    return api.put(`/mips/py/${year}/${implKey}/${orgKey}/${npiId}/draft/${measurementSet.id}`, measurementSet);
};

const submitSubmitToMips = (year, orgKey, implKey, npiId) => {
    // post the draft data for submission
    return api.post(`/mips/py/${year}/${implKey}/${orgKey}/${npiId}/submission`);
};

const loadSubmitReceiptData = (year, orgKey, implKey, npiId, receiptId) => {
    // post the draft data for submission
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/${npiId}/submission/${receiptId}`);
};


// Dashboard API Requests
const loadDashboardFilters = (year, orgKey, implKey) => {
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/filters`)
        .then((res) => {
            return Promise.resolve({data: res.data.values});
        })
        .catch((err) => {
            // error!
            return Promise.reject(err);
        });
};

const loadDashboardMeasuresMeta = (year, orgKey, implKey, filters) => {
    if(!filters.year) {
        return Promise.reject("filters.year is blank!");
    }
    const payload = {
        year: filters.year
    };
    const qs = queryString.stringify(payload);
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/measures?${qs}` , {})
        .then((res) => {
            const data = {};
            // create a lookup table
            res.data.values.forEach((it) => {
                data[it.id] = it;
            });
            return Promise.resolve({data});
        })
        .catch((err) => {
            // error!
            return Promise.reject(err);
        });
};

const loadDashboardMeasuresYearData = (year, orgKey, implKey, filters) => {
    if(!filters.year) {
        return Promise.reject("filters.year is blank!");
    }
    const qs = queryString.stringify(filters);
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/measuresYearData?${qs}` , {})
        .then((res) => {
            return Promise.resolve({data: res.data.values});
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

const loadDashboardMeasureYearDetailData = debouncePromise((year, orgKey, implKey, measure, period, filters, limit = 10, offset = 0) => {
    if(!filters.year) {
        return Promise.reject("filters.year is blank!");
    }
    const payload = {
        ...filters,
        period,
        measure,
        limit,
        offset
    };
    const qs = queryString.stringify(payload);

    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/measureYearDetail?${qs}` , {})
        .then((res) => {
            return Promise.resolve({data: res.data});
        })
        .catch((err) => {
            return Promise.reject(err);
        });
}, 500);

const loadDashboardCaseVolumeData = (year, orgKey, implKey, filters) => {
    if(!filters.year) {
        return Promise.reject("filters.year is blank!");
    }
    const qs = queryString.stringify(filters);
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/caseVolume?${qs}` , {})
        .then((res) => {
            return Promise.resolve({data: res.data.values});
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

const loadDashboardDecileData = (year, measureId) => {
    // This data is requested directly from CMS
    return cachePromise(axios.get)(
        config.endpoints.CMSApi + "submissions/public/benchmarks",
        {
            params: {
                measureId: measureId,
                performanceYear: year
            }
        })
        .then((res) => {
            if(
                res?.data?.data
                && Array.isArray(res?.data?.data?.benchmarks)
                && res?.data?.data?.benchmarks?.length >= 1
            ) {
                const data = {
                    data: res.data?.data?.benchmarks.find((it) => it.submissionMethod === "registry") || []
                };
                return Promise.resolve(data);
            }
            // insufficient data
            return Promise.resolve(false);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};


// CONSENT REQUESTS

const loadConsentListData = (year, orgKey, implKey) => {
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/submitter`);
};

const loadVerificationListData = (year, orgKey, implKey, search = "", status = "", sort = "orgKey", order = "asc", page = 0, size = 10, options = {}) => {
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/drcf-report`, {
        ...options,
        params: {
            search,
            status,
            page,
            size,
            sort,
            order
        }
    });
};

const exportVerificationListData = (year, orgKey, implKey, search = "", status = "") => {
    return loadVerificationListData(year, orgKey, implKey, search, status, "orgKey", "asc", 0, 1000)
        .then((res) => {
            const columnInfo = {
                "orgKey": "Organization",
                "drcfUserName":  "User Name",
                "drcfEmail":  "User Email",
                "entityType": "Type",
                "groupName": "Group Name",
                "status": "Status",
                "tinDataBoundStartDate": "TIN Start Date",
                "tinDataBoundEndDate": "TIN End Date",
                "tin": "TIN",
                "npiList": "NPI(s)", // npi, name
                "signerName": "Physician / Authorized Signer",
                "signerEmail": "Physician / Authorized Signer Email",
                "envelopeId": "Docusign ID",
                "uploadedVerificationDocumentBucketKey": "1500 Form",
                "verificationMessage": "Verification Message"
            };
            const columnKeys = Object.keys(columnInfo);

            const rows = [columnKeys.map((key) => {
                return '"' + columnInfo[key] + '"';
            }).join(",")].concat(res.data.map(it => {
                return columnKeys.map((key) => {
                    if(key === "orgKey") {
                        const v = it[key];
                        if(v) {
                            return '"' + v + '"';
                        }
                    }
                    else {
                        const v = it.submitter[key];
                        if (key === "npiList") {
                            return '"' + v.map(npi => `${npi.npi} (${npi.name})`).join(", ") + '"';
                        } else if (v) {
                            return '"' + v + '"';
                        }
                    }
                    return "";
                }).join(",");
            }));

            const csvString = rows.join("\n");

            const url = window.URL.createObjectURL(new Blob([csvString]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Verification Manager Export.csv');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
        });
};

const loadConsentPhysicianListData = (year, orgKey, implKey) => {
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/physician`);
};

const createConsentData = (year, orgKey, implKey, data) => {
    return api.post(`/mips/py/${year}/${implKey}/${orgKey}/submitter`, data);
};

const loadConsentDetailsData = (year, orgKey, implKey, id) => {
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/submitter/${id}`);
};

const updateConsentData = (year, orgKey, implKey, id, data) => {
    return api.put(`/mips/py/${year}/${implKey}/${orgKey}/submitter/${id}`, data);
};


const getConsentDocument = (year, orgKey, implKey, id) => {
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/submitter/${id}/form1500.pdf`,{
        responseType: 'blob'
    }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', '1500Form.pdf');
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    });
};

const uploadConsentDocument = (year, orgKey, implKey, id, file, onProgress = (percent) => {}) => {
    // create upload stream
    const data = new FormData();
    data.append("file", file);
    return api.post(
        `/mips/py/${year}/${implKey}/${orgKey}/submitter/${id}/form1500.pdf`,
        data,
        {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: (e) => {
                const { loaded, total } = e;
                let percent = Math.floor((loaded * 100) / total);
                onProgress(percent);
            }
        });
};


const hasDRCF = (year, orgKey, implKey) => {
    return api.get(`/mips/py/${year}/${implKey}/${orgKey}/has-drcf`);
};

const servicesAPI = {
    // Basic API
    api,
    loadUserData,
    logoutUser,
    loadPerformanceYearsMetaData,
    hasDRCF,
    
    //Dashboard API
    loadDashboardFilters,
    loadDashboardCaseVolumeData,
    loadDashboardMeasuresMeta,
    loadDashboardMeasuresYearData,
    loadDashboardMeasureYearDetailData,
    loadDashboardDecileData,

    // Submit API
    loadSubmitPerformanceYearMetaData,
    loadSubmitNPIData,
    loadSubmitCMSNPIData,
    loadSubmitMeasuresMetaData,
    loadSubmitDraftData,
    loadSubmitScoreData,
    saveSubmitDraftData,
    submitSubmitToMips,
    loadSubmitReceiptData,

    // Consent / Verification Manager API
    loadConsentListData, // Consent

    loadVerificationListData, // Verification
    exportVerificationListData, // Verification

    loadConsentPhysicianListData, // Consent
    createConsentData, // Consent
    loadConsentDetailsData, // Consent
    updateConsentData, // Consent / Verification
    getConsentDocument,  // Consent / Verification
    uploadConsentDocument // Consent
};

export default servicesAPI;
