import React from "react";
import Outline from "./components/Outline";
import makeSxStyles from "../../lib/makeSxStyles";
import { Box, Slider } from "@mui/material";

const useStyles = makeSxStyles(theme => ({
    root: {
        height: 66
    },
    slider: {
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
    },
    label: {
        "&:first-letter": {
            textTransform: "capitalize"
        }
    }
}));

// This controls text only works for the past at the moment

const DateRangeSlider = ({ label = "", minDistance = 1, ...props }) => {
    const {min = 0, max = 100, value, onChange, descriptor, isLoading } = props;
    const [state, setState] = React.useState((value || [0, 100]));
    const classes = useStyles();

    label = descriptor.label || label;
    const handleChange = (event, v) => {
        if (!Array.isArray(v)) {
            return;
        }
        if (Math.abs(v[0] - v[1]) < minDistance) {
            v = [state[0], state[1]];
        }
        setState(v);
    };

    const handleChangeCommitted = (event) => {
        const v = [...state];
        if(v[0] === min) {
            v[0] = "";
        }
        if(v[1] === max) {
            v[1] = "";
        }
        onChange(event, v);
    };

    let message = "";

    if(state[0] !== min) {
        message += `after ${Math.abs(state[0])}`;
    }

    if(state[0] !== min && state[1] !== max) {
        message += " days ago and ";
    }

    if(state[1] !== max) {
        message += `before ${Math.abs(state[1])}`;
    }
    message += " days ago.";
    if(state[0] === min && state[1] === max) {
        message = `Any ${label.toLowerCase()}.`;
    }

    let required = descriptor.required === true ? true : props.required;
    let disabled = descriptor.enabled === false ? true : props.disabled;
    if(isLoading) {
        disabled = true;
    }

    return (
        <Outline label={label} fullWidth={true} margin={"dense"}>
            <Box sx={classes.root}>
                <Box sx={classes.label}>
                    {message}{required ? "*" : ""}
                </Box>
                <Box sx={classes.slider}>
                    <Slider
                        {...props}
                        disabled={disabled}
                        required={required}
                        value={state}
                        onChange={handleChange}
                        valueLabelDisplay="off"
                        onChangeCommitted={handleChangeCommitted}
                    />
                </Box>
            </Box>
        </Outline>
    );
};

export default DateRangeSlider;