import { createBrowserHistory } from 'history';
import config from "../../config";
import { dispatchLocationChange } from "../data/LocationActions";

const history = createBrowserHistory({
    basename: config.contextPath
});

history.listen((location) => {
    // perform location changes on the next tick. This is any redirects from the
    // client router may cause dispatch within another dispatch.
    setTimeout(() => {
        dispatchLocationChange(location);
    });
});

export default history;
