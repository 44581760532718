import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {EnhancedTable, Paper} from "@armus/armus-dashboard";
import { makeStyles } from "@mui/styles";
import Typography from "@mui/material/Typography";
import {getStatus, getTypeLabel} from "../components/utils";
import {Person} from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/People";

const useStyles = makeStyles((theme) => ({
    scoreChip: {
        minWidth: 50,
        fontWeight: "bold",
        fontSize: 14
    },
    enhancedTable: {
        "& > div": {
            minHeight: 0
        }
    }
}));

const buildTableColumns = () => {
    return [
        {
            id: "icon",
            label: " ",
            isSortable: false,
            props: {width: "1%", align: "center", sx: {pl: 4, minWidth: 50, color: '#888888' }},
            Content: ({row}) => (<>
                {row.entityType === "group" ? <GroupIcon fontSize="large" /> : <Person fontSize="large" />}
            </>)
        },
        {
            id: "name",
            label: "Name",
            isSortable: false,
            props: {width: "60%", align: "left"},
            Content: ({row}) => (<Box p={2}>
                <h3>{row.npiList.map(it => it.name).join(", ")}</h3>

                    {row.entityType === "group" && <>Group Name: <strong>{row.groupName}</strong><br/></>}
                    TIN: <strong>{row.tin}</strong>&nbsp;<br/>
                    NPI: <strong>{row.npiList.map(it => it.npi).join(", ")}</strong>
                </Box>)
        },
        {
            id: "entityType",
            label: "Type",
            isSortable: false,
            props: {width: "20%", align: "center"},
            Content: ({row}) => <Typography fontWeight={500}>{getTypeLabel(row.entityType)}</Typography>
        },
        {
            id: "status",
            label: "Status",
            isSortable: false,
            props: {width: "20%", align: "center"},
            Content: ({row}) => {
                const statusInfo = getStatus(row.status);
                return (
                    <Typography fontWeight={500} color={statusInfo.color}>{statusInfo.label}</Typography>
                );
            }
        }
    ];
};

const HomeTable = ({isLoading, items = [], onEditClick, year, organization, view, implementation, type = "individual"}) => {
    const classes = useStyles();
    const columns = buildTableColumns(classes, type);
    columns.push(
        {
            id: "action",
            isSortable: false,
            label: "",
            props: { align: "right" },
            Content: ({row}) => {
                let buttonLabel = "View Details";
                if(row.status === "COMPLETE") {
                    if(row.entityType === "group") {
                        buttonLabel = "Edit NPIs";
                    }
                    else {
                        return null;
                    }
                }
                return (
                    <Button
                        dense="small"
                        color="secondary"
                        variant="contained"
                        onClick={() => onEditClick(row)}
                    >
                        {buttonLabel}
                    </Button>
                );
            }
        }
    );
    return (
        <Paper m={3}>
            <div className={classes.enhancedTable}>
                <EnhancedTable
                    isLoading={isLoading}
                    rows={items}
                    showHeader={true}
                    columns={columns}
                    dense={false}
                    hover={false}
                    pagination={false}
                    emptyMessage={
                        <Box p={8} py={14} textAlign={"center"}>
                            {view === "verification" && <Typography>
                                 There are no Submitting Entity awaiting TIN verification for {year} - {organization.name}.
                            </Typography>}
                            {view !== "verification" && <Typography variant={"subtitle1"} sx={{opacity: .5}}>
                            Click the "Add Submitting Entity" button in the top right to begin registration.
                          </Typography>}
                        </Box>
                    }
                />
            </div>
        </Paper>
    );
};

HomeTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onReceiptClick: PropTypes.func.isRequired
};

export default HomeTable;
