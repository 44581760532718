import React from "react";
import { BarChart } from "@armus/armus-dashboard";

const Chart = (props) => {
    const {
        title = "",
        description = "",
        data = [],
        height = 50,
        width = 300,
        type = "bar",
        config = {}
    } = props;

    return (
        <div style={{margin: "auto", height: height, width: width}}>
            <BarChart
                descriptor={{
                    id: title,
                    label: title,
                    description: description,
                    dataKey: "data",
                    type: type,
                    config: {
                        ...config,
                        legend: false,
                        subLegend: false,
                        showZoom: false,
                        showToolbox: false,
                        showAxisX: false,
                        showAxisY: false,
                        showValues: false
                    }
                }}
                onClick={() => {}}
                data={data}
            />
        </div>
    );
};
export default Chart;
