import React from "react";
import { DateTimePicker as DateTimePickerControl, DatePicker as DatePickerControl } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import {
    buildPropTypesFromObject,
    buildPropTypesWithDescriptor
} from "../../lib/propTypeHelpers";
import Spinner from "../common/Spinner";
import moment from "moment";
import { Box } from "@mui/material";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        width: "100%", 
        "& .MuiFormControl-root": {
            width: "100%",
            marginTop: 1,
            marginBottom: .5,
        }
    }
}));

const DatePicker = (props) => {
    const classes = useStyles();
    const {isLoading, value = null, descriptor, onChange, onClick, onBlur, onFocus, onKeyDown} = props;
    const label = descriptor.label || props.label;
    const cfg = defaultConfig(descriptor.config);
    let Control = DatePickerControl;
    let showToolbar = cfg.showToolbar;
    const views = [];
    if(!cfg.disableYearView) views.push("year");
    if(!cfg.disableMonthView) views.push("month");
    if(!cfg.disableDayView) views.push("day");
    if(!cfg.disableTime || cfg.format.indexOf("HH:mm") !== -1) {
        Control = DateTimePickerControl;
        showToolbar = true;
        cfg.hideTabs = false;
        views.push("hours");
        views.push("minutes");
    }
    const handleChange = (v) => {
        onChange && onChange({target: {value: v}}, v, descriptor);
    };

    const newProps = {
        ...props
    };

    delete newProps.descriptor;
    delete newProps.isLoading;
    delete newProps.disabled;
    delete newProps.required;

    let disabled = descriptor.enabled === false ? true : props.disabled;
    let required = descriptor.required === true ? true : props.required;
    if(isLoading) {
        disabled = true;
    }
    return (
        <Box sx={classes.root}>
            <Control
                key={descriptor.id}
                id={descriptor.id}
                name={descriptor.id}
                title={descriptor.description}
                disabled={disabled}
                loading={isLoading}
                showToolbar={showToolbar}
                showDaysOutsideCurrentMonth={true}
                renderLoading={() => <Spinner size={18} overlay={true} />}
                disableFuture={cfg.disableFuture}
                disablePast={cfg.disablePast}
                openTo={cfg.openTo}
                label={label}
                inputFormat={cfg.format}
                value={value !== undefined ? value : null}
                minDate={moment(cfg.min)}
                maxDate={moment(cfg.max)}
                ampm={cfg.ampm}
                hideTabs={false}
                views={views}
                required={required}
                renderInput={isLoading ? (inputProps) => {
                    return (
                        <TextField
                            {...inputProps}
                            required={required}
                            disabled={disabled}
                            fullWidth={true}
                            InputProps={{
                                ...inputProps.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {inputProps.InputProps.endAdornment}
                                        <Spinner size={18} overlay={true} />
                                    </React.Fragment>
                                ),
                            }}
                        />
                    );
                } : undefined}
                {...newProps}
                onChange={handleChange}
                onClick={(e) => onClick && onClick(e, value, descriptor)}
                onBlur={(e) => onBlur && onBlur(e, value, descriptor)}
                onFocus={(e) => onFocus && onFocus(e, value, descriptor)}
                onKeyDown={(e) => onKeyDown && onKeyDown(e, value, descriptor)}
            />
        </Box>
    );
};

DatePicker.defaultProps = {
    value: null,
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            options: [],
            openTo: "date", // date | month | year
            showToolbar: false,
            ampm: false,
            format: "MM/DD/YYYY",
            min: "1900-01-01",
            max: "2099-12-31",
            disableFuture: false,
            disablePast: false,
            disableDayView: false,
            disableMonthView: true,
            disableYearView: true,
            disableTime: true,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...DatePicker.defaultProps.descriptor.config,
    ...config
});

DatePicker.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));
export default DatePicker;