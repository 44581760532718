import FiltersStore from "../FiltersStore";
import MeasuresMetaStore from "../MeasuresMetaStore";
import MeasureProxy from "../utils/MeasureProxy";

export const getFilteredMeasureProxies = () => {
    const measures = MeasuresMetaStore.getAllMeasuresMeta().map(m => new MeasureProxy(m.id));
    const filters = FiltersStore.getAllFiltersValue();
    return measures.filter((measureProxy) => {
        if(measureProxy.meta.displayType === "component") {
            return false;
        }
        if(filters.implementations.length === 0) {
            return true;
        }
        else {
            for(let i in measureProxy.meta.implementations) {
                const implementations = measureProxy.meta.implementations[i];
                if(filters.implementations.includes(implementations)) {
                    return true;
                }
            }
        }
        return false;
    });
};
