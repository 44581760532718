import { matchPath } from "react-router-dom";
import history from "./app/lib/history";
import ErrorPage from "./app/views/ErrorPage";
import config from "./config";

import SubimitHome from "./app/views/Submit/Home/Home";
import SubmitDraft from "./app/views/Submit/Draft/Draft";
import SubmitReceipt from "./app/views/Submit/Receipt/Receipt";
import Dashboard from "./app/views/Dashboard/Dashboard";
import VerificationHome from "./app/views/Verification/Home/Home";
import SignupHome from "./app/views/Signup/Home/Home";
import SignupDetails from "./app/views/Signup/Details/Details";

const yearFormat = ":year(20[2-9][0-9])"; // 2020 or greater
const npiIdFormat = ":npiId(\\d+)"; // a number
const receiptIdFormat = ":receiptId(\\d+)"; // a number

export const getApplicationRoutes = (asArray = true) => {
    const routes = {
        ERROR: { // error
            key: "ERROR",
            exact: true,
            path: `/error`,
            component: ErrorPage
        }
    };

    if(config.ui.dashboardEnabled) {
        routes.DASHBOARD = { // Dashboard
            key: "DASHBOARD",
            exact: true,
            path: `/dashboard/${yearFormat}/:implKey/:orgKey/`,
            component: Dashboard
        };
    }

    if(config.ui.submitEnabled) {
        routes.DRAFT = { // Edit Submission
            key: "DRAFT",
            exact: true,
            path: `/mips/${yearFormat}/:implKey/:orgKey/:type(individual|group)/${npiIdFormat}/draft`,
            component: SubmitDraft
        };
        routes.RECEIPT = { // SubmitReceipt
            key: "RECEIPT",
            exact: true,
            path: `/mips/${yearFormat}/:implKey/:orgKey/:type(individual|group)/${npiIdFormat}/receipt/${receiptIdFormat}?`,
            component: SubmitReceipt
        };
        routes.MIPS = { // SubimitHome
            key: "MIPS",
            exact: true,
            path: `/mips/${yearFormat}/:implKey/:orgKey/`,
            component: SubimitHome
        };
    }

    if(config.ui.signupEnabled) {
        routes.CONSENT = { // Signup
            key: "CONSENT",
            exact: true,
            path: `/:view(consent)/${yearFormat}/:implKey/:orgKey/`,
            component: SignupHome
        };
        routes.CONSENT_DETAILS = { // Signup details
            key: "CONSENT_DETAILS",
            exact: true,
            path: `/:view(consent)/${yearFormat}/:implKey/:orgKey/details/:consentId/`,
            component: SignupDetails
        };
    }

    if(config.ui.verificationEnabled) {
        routes.VERIFICATION = { // Verification
            key: "VERIFICATION",
            exact: true,
            path: `/:view(verification)/${yearFormat}/:implKey/:orgKey/`,
            component: VerificationHome
        };
        routes.VERIFICATION_DETAILS = { // Verification details
            key: "VERIFICATION_DETAILS",
            exact: true,
            path: `/:view(verification)/${yearFormat}/:implKey/:orgKey/details/:consentId/`,
            component: SignupDetails
        };
    }

    if(asArray) {
        return Object.values(routes);
    }
    return routes;
};

export const matchRoute = (location) => {
    location = location || history.location;
    const routes = getApplicationRoutes();
    for(let i = 0; i < routes.length; i++) {
        const route = routes[i];
        const match = matchPath(location.pathname, routes[i]);
        if(match !== null) {
            return {route, match}; // return first match.
        }
    }
    return {route: null, match: null};
};

export const getRouteByKey = (routeKey) => {
    return getApplicationRoutes(false)[routeKey];
};

export const buildUrl = (routeKey, params) => {
    const route = getRouteByKey(routeKey);
    let url = route.path;
    Object.keys(params).forEach(key => {
        const value = params[key];
        const reg = new RegExp(`:${key}[^/]*`, 'g');
        url = url.replace(reg, (value === null || value === undefined ? "" : value).toString());
    });
    return url;
};

export const getRouteKeyFromImplKey = (key) => {
    const view = ((key || "").split("-")[0] || "").toUpperCase();
    switch(view) {
        case "CONSENT":
            return "CONSENT";
        case "VERIFICATION":
            return "VERIFICATION";
        case "DASHBOARD":
            return "DASHBOARD";
        case "MIPS":
            return "MIPS";
        default:
        return "ERROR";
    }
};
