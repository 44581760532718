import MeasureMetaStore from "../MeasuresMetaStore";
import MeasureDataStore from "../MeasuresDataStore";
import FiltersStore from "../FiltersStore";
import { getBenchmarkColor } from "../../../lib/color";
import { titleCase } from "@armus/armus-dashboard";
import debug from "../../../lib/debug";

// {
//     get meta() {}, // always accesses latest meta from the MeasureMetaStore
//     get data() {}, // always accesses latest data from the MeasureDataStore
//     id: number
// }

function MeasureProxy(id) {
    const _this = this;
    _this.id = id;
    Object.defineProperty(_this, "meta", {
        get: function () {
            return MeasureMetaStore.getMeasureMetaById(_this.id);
        }
    });
    Object.defineProperty(_this, "data", {
        get: function () {
            return MeasureDataStore.getMeasureDataById(_this.id);
        }
    });
    _this.getColumn = (columnId) => {
        const columns = this.data || {};
        const found  = columns[columnId];
        if(!found) {
            debug.log(`MeasureProxy.getColumn(): unknown column id "${columnId}"`, Object.keys(columns));
            return false;
        }
        return found;
    };
    _this.title = () => {
        // some titles are not title case.
        return titleCase(_this.meta.title);
    };
    _this.getPerformanceRate = (key, useSmartPerformance = false) => {
        const column = _this.getColumn(key);
        if(useSmartPerformance && _this.isSmartPerformance()) {
            return column.smartPerformanceRate;
        }
        return column.performanceRate;
    };
    _this.getBenchmark = () => {
        const benchmark = _this.meta.cmsBenchmark || null;
        if(benchmark === null) {
            return null;
        }

        return _this.isSmartPerformance() ? Math.round((100-benchmark) * 1000) / 1000 : benchmark;
    };
    _this.getComponents = () => {
        const meta = _this.meta;
        if(_this.isMultiComponent()) {
            return meta.components.map((mId) => new MeasureProxy(mId));
        }
        return [];
    };
    _this.getColumnColor = (key, useSmartPerformance = false) => {
        if(key === "agg") {
            return {
                color: "inherit",
                backgroundColor: "inherit"
            };
        }
        return getBenchmarkColor(
            _this.getPerformanceRate(key, useSmartPerformance),
            _this.getBenchmark(),
            useSmartPerformance && _this.isSmartPerformance() ? false : _this.isInverse()
        );
    };
    _this.isMultiComponent = () => {
        return _this.meta.displayType === "multiComponent";
    };
    _this.isSmartPerformance = () => {
        return FiltersStore.getFilterValue("smartPerformance") && _this.meta.isInverse;
    };
    _this.isInverse = () => {
        return _this.meta.isInverse;
    };
    _this.isSufficient = (columnId) => {
        let cell;
        if(columnId) {
            cell = _this.getColumn(columnId);
        }
        else {
            cell = _this.getColumn("ytd");
        }
        return cell.reportingRate >= 75;
    };
    _this.canSubmit = (columnId) => {
        let cell;
        if(columnId) {
            cell = _this.getColumn(columnId);
        }
        else {
            cell = _this.getColumn("ytd");
        }
        return cell.eligible >= 20;
    };
    _this.hasData = () => {
        return MeasureDataStore.hasMeasureDataById(_this.id);
    };
}

export default MeasureProxy;
