import React from "react";
import { Header, Footer } from "@armus/armus-dashboard";
import ErrorPage from "../ErrorPage";
import config from "../../../config";
import Notifications from "./Notifications";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.error(error);
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <React.Fragment>
                    <Header
                        isLoading={false}
                        leftNav={[]}
                        rightNav={[]}
                        profileNav={[]}
                        userName={undefined}
                        loginUrl={config.endpoints.login}
                        logoUrl={<>MIPS&nbsp;Submit</>}
                    />

                    <ErrorPage statusCode={999} />

                    <Footer navLinks={[]} />
                    <Notifications />
                </React.Fragment>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
