import React from "react";
import {buildPropTypesFromObject, buildPropTypesWithDescriptor} from "../../lib/propTypeHelpers";
import {
    Checkbox as BaseCheckbox,
    FormControlLabel,
} from "@mui/material";
import Outline from "./components/Outline";
import Spinner from "../common/Spinner";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    root: {
        minHeight: 36.13,
        marginTop: 1,
        marginBottom: .5,
        "& .MuiCheckbox-root .hc-checkbox-overlay": {
            minWidth: 18,
            width: 18,
            height: 18,
        },
        "& .MuiInputBase-root": {
            padding: "7.7px 14px",
        },
        "& .MuiCheckbox-root": {
            padding: "0px 9px 0px 9px",
        },
        "& .MuiFormControlLabel-root": {
            "& > .MuiFormControlLabel-label": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
            }
        }
    }
}));

const Checkbox = ({isLoading, descriptor, data = [], value, label, disabled, options, onClick, onChange, onBlur, onFocus, onKeyDown, inputProps, required, ...extraProps}) => {
    const [focused, setFocused] = React.useState(false);
    const classes = useStyles();
    const cfg = defaultConfig(descriptor.config);
    label = descriptor.label || label;
    let mergedOptions = [];
    if(data || options) {
        mergedOptions = (data || []).concat(options || []);
        cfg.options.forEach(a => {
            if (data.findIndex(b => a.label === b.label) !== -1) return;
            mergedOptions.push(a);
        });
    }

    required = descriptor.required === true ? true : required;
    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        mergedOptions = [];
        disabled = true;
    }

    const multivalued = mergedOptions.length > 0;
    if(multivalued) {
        value = !Array.isArray(value) ? [] : value;
    }
    else {
        if(value === null || value === undefined) {
            value = cfg.uncheckedValue;
        }
        value = (cfg.checkedValue === value ? true : cfg.uncheckedValue === value ? false : value);
    }
    const isSelected = (v) => {
        if(multivalued) {
            return value.includes(v);
        }
        return value;
    };

    const getEventValue = (e, it, v) => {
        if (multivalued) {
            if (v === true) {
                // add value
                v = [...value, it.value];
            }
            else {
                // remove value
                v = value.filter((o) => o !== it.value);
            }
        }
        else {
            v = v ? (cfg.checkedValue !== true ? cfg.checkedValue : v) : (cfg.uncheckedValue !== false ? cfg.uncheckedValue : v);
        }
        return v;
    };

    const handleOnChange = (e, v) => {
        onChange && onChange(e, v, descriptor);
    };

    if(!multivalued) {
        mergedOptions = [{
            label: label,
            value: true
        }];
    }

    return (
        <Outline
            sx={classes.root}
            focused={focused}
            label={(multivalued ? label : "")}
            title={descriptor.description}
            fullWidth={true}
            required={required}
            disabled={disabled}
            {...extraProps}
        >
            {isLoading ? <Spinner size={18} overlay={true} /> :null}
            {mergedOptions.map((it) =>
                <FormControlLabel
                    key={descriptor.id + it.label +(multivalued ? "multi" : "single")}
                    size={cfg.dense ? "small" : "large"}
                    control={
                        <BaseCheckbox
                            disabled={disabled || it.disabled}
                            id={it.id || descriptor.id}
                            name={it.id || descriptor.id}
                            color="primary"
                            checked={isSelected(it.value)}
                            onClick={(e) => onClick && onClick(e, getEventValue(e, it, value), descriptor)}
                            onChange={(e, v) => handleOnChange(e, getEventValue(e, it, v))}
                            onBlur={(e) => {
                                setFocused(false);
                                onBlur && onBlur(e, getEventValue(e, it, value), descriptor);
                            }}
                            onFocus={(e) => {
                                setFocused(true);
                                onFocus && onFocus(e, getEventValue(e, it, value), descriptor);
                            }}
                            onKeyDown={(e) => onKeyDown && onKeyDown(e, getEventValue(e, it, value), descriptor)}
                            {...inputProps}
                        />
                    }
                    label={it.label}
                    title={it.label}
                />
            )}
        </Outline>
    );
};

Checkbox.defaultProps = {
    value: null,
    data: [],
    options: [],
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            options: [],
            dense: true,
            checkedValue: true,
            uncheckedValue: false,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Checkbox.defaultProps.descriptor.config,
    ...config
});

Checkbox.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));

export default Checkbox;