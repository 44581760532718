const statusCodes = {
  "NEW": {
    step: 0, // add or edit submitter NPI/TIN
    value: "NEW",
    label: "Signup & Supporting Documents",
    description: "New Authorized Representative Signup",
    color: "rgba(127,127,127,.5)"
  },
  "INVALID": {
    step: 0, // <-- kick back to step 0
    value: "INVALID",
    label: "Verification Failed",
    description: "Try Again",
    color: "error.main"
  },
  "PENDING_VERIFICATION": {
    step: 1, // data-manager waits for verification-manager to approve / reject
    value: "PENDING_VERIFICATION",
    label: "Verification Pending",
    description: "Awaiting Approval",
    color: "rgba(127,127,127,.5)"
  },
  "VERIFIED": { // different action items for Group / Individual data-manager
    step: 2,
    value: "VERIFIED",
    label: "Needs Signer Info",
    description: "Who is signing?",
    color: "info.main"
  },
  "PENDING_SIGNATURE": {
    step: 3, // data-manager goes through docusign, query api for instant results.
    value: "PENDING_SIGNATURE",
    label: "Awaiting Signature",
    description: "Almost done...",
    color: "rgba(127,127,127,.5)"
  },
  "COMPLETE": {
    step: 4, // all done for individual, group has option to add/remove npi / go back to step 1!
    value: "COMPLETE",
    label: "Done",
    description: "All steps completed!",
    color: "success.main"
  }
};


export const getSteps = () => {
  return Object.keys(statusCodes).map(it => statusCodes[it]);
};

export const getStatus = (status) => {
  return statusCodes[status || "new"] ;
};

export const getTypeLabel = (type) => {
  switch(type) {
    case "group":
      return "Group";
    case "individual":
    default:
      return "Individual";
  }
};
