import React from "react";
import api from "../../../lib/api";
import {Spinner, titleCase, formatValue, KeyValueTable, FormattedMessage} from "@armus/armus-dashboard";
import {getColumn} from "../../../lib/columns";
import {getBenchmarkColor} from "../../../lib/color";
import Chart from "../../components/Chart";

const TabDeciles = ({isLoading, isError, data, activeCell}) => {
    if(isLoading) {
        return (
            <Spinner variant={"linear"} />
        );
    }
    if(isError) {
        return <FormattedMessage id="apiErrorMessage" />;
    }
    if(!data) {
        return <FormattedMessage id="insufficientData" />;
    }
    const {measureProxy, columnId} = activeCell;
    const column = getColumn(columnId);
    const performanceRate = measureProxy.getPerformanceRate(columnId, false);
    const ytdPerformanceRate = measureProxy.getPerformanceRate("ytd", false);
    const aggPerformanceRate = measureProxy.getPerformanceRate("agg", false);

    /* data shape from CMS
    {
        benchmarkYear: 2020
        deciles: [
          100,
          1.9,
          0,
          0,
          0,
          0,
          0,
          0,
          0
        ],
        measureId: "IGR10"
        performanceYear: 2020
        status: "currentInsufficientData" or "historical"
        submissionMethod: "registry"
    }

    // NEW data shape from CMS 2024 format
    {
        "benchmarkYear": 2022,
        "performanceYear": 2024,
        "metricType": "singlePerformanceRate",
        "status": "historical",
        "isInverse": true,
        "isToppedOut": false,
        "isToppedOutByProgram": false,
        "isHighPriority": true,
        "percentiles": {
            "1": 0,
            "10": 0,
            "20": 0,
            "30": 0,
            "40": 0,
            "50": 0,
            "60": 0,
            "70": 0,
            "80": 0,
            "90": 0.09,
            "99": 3.03
        },
        "submissionMethod": "registry",
        "measureId": "439"
    },
    {
        "benchmarkYear": 2022,
        "performanceYear": 2024,
        "metricType": "singlePerformanceRate",
        "status": "historical",
        "isInverse": false,
        "isToppedOut": true,
        "isToppedOutByProgram": true,
        "isHighPriority": true,
        "percentiles": {
            "1": 100,
            "10": 100,
            "20": 100,
            "30": 100,
            "40": 100,
            "50": 100,
            "60": 100,
            "70": 100,
            "80": 100,
            "90": 100,
            "99": 100
        },
        "submissionMethod": "claims",
        "measureId": "320"
    },
    */

    const performance = {
        "Status": `${titleCase(data.status)} ${data.status === "historical" ? data.benchmarkYear : ""}`,
        [`${column.title} Performance Rate`]: formatValue("percent", performanceRate/100),
        "Year To Date Performance Rate": formatValue("percent", ytdPerformanceRate/100),
        "Agg Performance Rate": formatValue("percent", aggPerformanceRate/100)
    };

    let deciles = processDeciles(Object.values(data.percentiles || {}), measureProxy.isInverse());

    const colors = [];
    const markers = [];
    const chartData = deciles.map((it) => {
        const [startPercent] = [
            [  1,  1], //  1
            // ?? missing deciles info 0-10
            [ 10, 10], // 10
            [ 20, 20], // 20
            [ 30, 30], // 30
            [ 40, 40], // 40
            [ 50, 50], // 50
            [ 60, 60], // 60
            [ 70, 70], // 70
            [ 80, 80], // 80
            [ 90, 90], // 90
            [ 99, 99]  // 99
        ][it.index];
        const c = getBenchmarkColor(startPercent, aggPerformanceRate, measureProxy.isInverse());
        colors.push(c.backgroundColor);
        const label = `${startPercent}%`;
        return {
            label,
            value: it.value/100
        };
    });

    return (
        <div>
            <KeyValueTable
                data={performance}
                showHeader={false}
            />
            {chartData.length > 0 ? <React.Fragment>
                <Chart
                    data={chartData}
                    title="CMS Measure Comparison Deciles"
                    type="bar"
                    config={{
                        dense: true,
                        colorScheme: colors,
                        xLabel: "",
                        yLabel: "",
                        legend: false,
                        formatValue: "percent",
                        markers
                    }}
                />
                <p style={{textAlign: "center"}}>
                    {measureProxy.isSmartPerformance() && <FormattedMessage id="decileSmartPerformanceWarning" />}
                    {measureProxy.isInverse() ?
                        <FormattedMessage id="decileInverseMessageOn" />
                        : <FormattedMessage id="decileInverseMessageOff" />
                    }
                </p>
            </React.Fragment> : "No deciles data available."
            }
        </div>
    );
};

const processDeciles = (rawDeciles, inverse ) => {
    if(!Array.isArray(rawDeciles)) {
        return [];
    }
    let lastDecile;
    let deciles = [...rawDeciles];
    deciles = deciles.map((it, index) => {
        const ret = it-lastDecile;
        const range = lastDecile + "% to " + it + "%";
        lastDecile = it;
        return {
            index,
            value: it,
            width: ret,
            range
        };
    });
    return deciles;
};

export default (props) => {
    const {filters, activeCell} = props;
    const measureProxy = activeCell.measureProxy;
    const [isLoading, setIsLoading] = React.useState(true);
    const [isError, setIsError] = React.useState(false);
    const [data, setData] = React.useState(undefined);
    React.useEffect(() => {
        setIsLoading(true);
        api.loadDashboardDecileData(
            filters.year,
            measureProxy.meta.measureId
        )
            .then((res)=> {
                setIsLoading(false);
                setIsError(false);
                setData(res.data);
            })
            .catch(() => {
                setIsLoading(false);
                setIsError(true);
            });
    }, [filters.year, measureProxy.meta.measureId]);

    return (
        <TabDeciles {...props} isLoading={isLoading} isError={isError} data={data} />
    );
};
