import { makeStyles } from "@mui/styles";
import TooltipMUI from "@mui/material/Tooltip";
import HelopIcon from "@mui/icons-material/Help";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    moreinfo: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    icon: {
        "&:hover": {
            color: "rgba(127,127,127,1)"
        },
        color: "rgba(127,127,127,.7)",
        fontSize: 17,
        marginLeft: theme.spacing(1)/2
    }
}));

const SmartTooltip = (props) => {
    const classes = useStyles();
    const {children, useIcon = true} = props;
    const newProps = {...props};
    delete newProps.useIcon;
    if(!useIcon) {
        return (
            <TooltipMUI
                arrow
                placement="top"
                enterDelay={500}
                leaveDelay={500}
                {...newProps}
            >
                <span>{children}</span>
            </TooltipMUI>
        );
    }
    return (
        <span className={classes.moreinfo}>
            {children}
            <TooltipMUI
                arrow
                placement="top"
                enterDelay={500}
                leaveDelay={500}
                {...newProps}>
                <HelopIcon className={classes.icon} />
            </TooltipMUI>
        </span>
    );
};

SmartTooltip.propTypes = {
    children: PropTypes.any
};

export default SmartTooltip;
