import React from 'react';
import { makeStyles } from "@mui/styles";
import { Gauge } from "@armus/armus-dashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    categorySummaryContainer: {
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "top",
        textAlign: "center",
        "&::after": {
            content: "'+'",
            fontSize: 60,
            paddingTop: 50,
            display: "inline-block",
            color: theme.palette.primary.main
        },
        "&:nth-last-child(2)::after": {
            content: "'='",
            fontSize: 60,
            paddingTop: 50,
            display: "inline-block",
            color: theme.palette.primary.main
        },
        "&:last-child":{
            "& .MuiCategoryContainer": {
                //marginTop: 0,
            },
            "& .MuiGaugeContainer": {
                marginTop: 8,
                marginBottom: 8
            },
            "&::after": {
                content: "' '",
                fontSize: 60,
                paddingTop: 50,
                display: "inline-block",
                color: theme.palette.primary.main
            }
        },
        "& .MuiCategoryContainer": {
            display: "inline-block",
            width: 200,
            textAlign: "center"
            //marginTop: theme.spacing(2),
        },
        "& .MuiGaugeContainer": {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4)
        }

    },
    weightedScore: {
        fontWeight: "bold",
        fontSize: theme.spacing(2.5),
        color: theme.palette.primary.main
    }
}));

const SummaryGauges = ({items, prefixScore = "Preview "}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            {items.map((item, index) => {
                const lastItem = index === items.length-1;
                const percent = item.score / item.maxScore * 100;
                return (
                    <div key={index} className={classes.categorySummaryContainer}>
                        <div className={"MuiCategoryContainer"}>
                            <strong>{item.label}</strong>
                            <div className={"MuiGaugeContainer"}>
                                <Gauge
                                    color={"primary"}
                                    variant="determinate"
                                    size={lastItem ? 140 : 90}
                                    thickness={lastItem ? 8 : 4}
                                    value={percent}
                                >
                                    <div title={`${item.score} out of ${item.maxScore} possible points.`}>
                                        <div className={classes.weightedScore}>{Math.round( ( item.score + Number.EPSILON ) * 100 ) / 100}</div>
                                        <div>{Math.round( ( percent + Number.EPSILON ) * 10 ) / 10}%</div>
                                    </div>
                                </Gauge>
                            </div>
                            {item.score !== undefined && <div>{prefixScore}Score: <strong>{item.score}</strong> / {item.maxScore}</div>}
                            {item.unweightedScore !== undefined && <div>Unweighted Score: <strong>{item.unweightedScore}</strong> / {item.unweightedMaxScore}</div>}
                            {item.bonusScore !== undefined && <div>Bonus Score: {item.bonusScore}</div>}
                            {item.selectedMeasuresCount !== undefined && <div>Selected Measures: {item.selectedMeasuresCount}</div>}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};


export default SummaryGauges;
