import React from "react";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import DownloadIcon from "@mui/icons-material/GetApp";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { titleCase, AlertBar, InfoChip } from "@armus/armus-dashboard";
import MeasureChips from "./MeasureChips";
import Container from "../../../../data/Container";
import MeasuresMetaStore from "../../../../data/submit/MeasuresMetaStore";
import MeasuresDraftStore from "../../../../data/submit/MeasuresDraftStore";
import MeasuresScoreStore from "../../../../data/submit/MeasuresScoreStore";
import { dispatchOnMeasureChange } from "../../../../data/submit/MeasuresDraftActions";
import BooleanControl from "./BooleanControl";
import ProportionControl from "./ProportionControl";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginBottom: theme.spacing(1),
        position: "relative",
        "&:first-child": {
            marginTop: theme.spacing(1)
        },
        "&.MuiPaper-error": {
            borderColor: theme.palette.error.main
        },
        "&.MuiPaper-warning": {
            borderColor: theme.palette.warning.main
        },
        "&.MuiPaper-disabled": {
            borderColor: theme.palette.grey["200"],
            color: theme.palette.grey["500"]
        }
    },
    infoContainer: {
        padding: theme.spacing(2)
    },
    controlContainer: {
        padding: theme.spacing(2),
        backgroundColor: "rgba(0, 0, 0, .01)",
        borderLeft: "solid 1px " + theme.palette.divider,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        "& .MuiTextField-root:first-child": {
            marginBottom: theme.spacing(1)
        },
        "& input": {
            background: theme.palette.background.paper,
            fontWeight: "bold",
            textAlign: "center",
            fontSize: theme.spacing(2)
        },
        "& .Mui-disabled": {
            color: "rgba(127,127,127,.5)",
            "& input": {
                background: "transparent"
            }
        }
    },
    title: {
        fontWeight: "bold"
    },
    id: {
        color: "#888",
        fontSize: "80%"
    },
    score: {
        color: "#888",
        fontSize: "80%",
        textAlign: "right"
    },
    badges: {
        // marginLeft: theme.spacing(1),
        paddingBottom: "2px"
    },
    exclusion: {
        borderTop: "1px solid " + alpha(theme.palette.divider,.05),
        "& .MuiSvgIcon-root": {
            height: 25,
            width: 25
        }
    },
    qualityContainer: {
        padding: theme.spacing(0, 2)
    },
    downloadIcon: {
        marginLeft: 4,
        verticalAlign: "middle"
    },
    performanceRate: {
        color: theme.palette.primary.main,
        fontSize: "120%"
    }
}));

const buildIsDisabled = (disabled, selectedMeasure, isSubstituted) => {
    return (id) => {
        if(disabled) {
            return true;
        }
        if(isSubstituted) {
            return true;
        }
        return !!selectedMeasure && selectedMeasure.id !== id;
    };
};

const MeasureResolver = ({
    measureProxy,
    measureSetProxy,
    onChange,
    disabled,
    isReview,
    selectedMeasure,
    draftData,
    messages
}) => {
    let extraPaperClass = "";
    const [state ,setState] = React.useState({isValid: true});
    const [descriptionExpanded, setExpandDescription] = React.useState(false);
    const classes = useStyles();
    const exclusions = measureProxy.getExclusions();
    const isSubstituted = measureProxy.isSubstituted();
    let control = null;
    const isDisabled = buildIsDisabled(disabled, selectedMeasure, isSubstituted);
    messages = [...messages];
    if(measureProxy.meta.isRequired && draftData === null && !isSubstituted) {
        extraPaperClass = "MuiPaper-warning";
        messages.push({
            type: "warning",
            title: `This measure is required.`,
            details: `In order to receive a score for ${measureSetProxy.title()} all required fields must be submitted.`
        });
    }
    if(isSubstituted) {
        extraPaperClass = "MuiPaper-disabled";
        const selectedSubstitutions = measureProxy.getSelectedSubstitutions();
        messages.push({
            type: "info",
            title: `This measure has been substituted with ${selectedSubstitutions.map(mp => mp.meta.measureId).join(", ")}.`,
            details: `This measure can only be enabled by un-selecting ${selectedSubstitutions.map(mp => mp.meta.measureId).join(", ")}.`
        });
    }
    switch(measureProxy.meta.metricType) {
        case "proportion":
            if(!state.isValid) {
                extraPaperClass = "MuiPaper-error";
                messages.push({
                    type: "error",
                    title: "There is a problem with the data you entered. This measure will not be included for submission until the issue is corrected.",
                    details: "Verify the following are true: Numerator >= 0 and Denominator > Numerator."
                });
            }
            control = (
                <ProportionControl
                    isReview={isReview}
                    measureProxy={measureProxy}
                    disabled={isDisabled(measureProxy.id)}
                    onChange={(e, value) => {
                        onChange(
                            measureProxy.meta.category,
                            measureProxy.meta.measureId,
                            measureProxy.meta.metricType,
                            value,
                            measureProxy.meta.exclusion
                        );
                    }}
                    onValidate={(isValid) => {
                        if(isValid !== state.isValid) {
                            setState({...state, isValid});
                        }
                    }}
                />
            );
            break;
        case "singlePerformanceRate":
        case "registrySinglePerformanceRate":
        case "registryMultiPerformanceRate":
        case "boolean":
            control = (
                <BooleanControl
                    isReview={isReview}
                    measureProxy={measureProxy}
                    disabled={isDisabled(measureProxy.id)}
                    onChange={(e, value) => onChange(
                        measureProxy.meta.category,
                        measureProxy.meta.measureId,
                        measureProxy.meta.metricType,
                        value,
                        measureProxy.meta.exclusion
                    )}
                />
            );
            break;
        default:
            console.log("UNKNOWN METRIC TYPE: ", measureProxy.meta.metricType);
            control = measureProxy.meta.metricType;
    }
    const maxDescriptionLength = 150;
    const showDescriptopnExpando = measureProxy.meta.description.length > maxDescriptionLength;
    const qualityMeasureData = measureProxy.getQualityMeasureData();
    // const getScoreData = measureProxy.getScoreData();
    return (
        <Paper id={measureProxy.meta.measureId} className={classes.paper + " " + extraPaperClass} variant="outlined">
            <Grid container>
                <Grid item xs={10} className={classes.infoContainer}>
                    <Grid container>
                        <Grid item xs={11}>
                            <div className={classes.id}>{measureProxy.meta.measureId}</div>
                            <div className={classes.title}>
                                {measureProxy.title()}
                                {!!measureProxy.meta.specificationLink && (
                                    <Link className={classes.downloadIcon} href={measureProxy.meta.specificationLink} target={"_blank"}>
                                        <DownloadIcon />
                                    </Link>
                                )}
                            </div>
                            <div>
                                <span className={classes.badges}>
                                    <MeasureChips measureProxy={measureProxy} measureSetProxy={measureSetProxy} />
                                </span>
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            <div className={classes.score}>
                                Score: {measureProxy.getScore()} <br/>
                                {measureProxy.isSelected() ? (measureProxy.isPicked() ?
                                    <InfoChip size="xs" color={"success"} label={"Picked"} variant={"outlined"} />
                                    : <InfoChip size="xs" color={"default"} label={"Skipped"} disabled variant={"outlined"}/>)
                                    : <InfoChip size="xs" color={"default"} label={"Not Selected"} disabled variant={"outlined"}/>
                                }
                            </div>
                        </Grid>
                    </Grid>

                    <div>
                        <Grid container>
                            <Grid item xs={measureProxy.meta.category === "quality" ? 6 : 12}>
                                {descriptionExpanded || !showDescriptopnExpando ? measureProxy.meta.description : measureProxy.meta.description.substr(0, maxDescriptionLength) + "..."}
                                &nbsp;
                                <Link
                                    href="src/app/views/Submit/Draft/components#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setExpandDescription(!descriptionExpanded);}
                                    }
                                    tabIndex={-1}
                                >
                                    {showDescriptopnExpando && (descriptionExpanded ? "show less" : "show more")}
                                </Link>
                            </Grid>
                            {measureProxy.meta.category === "quality" && (
                                <Grid item xs={6}>
                                    {qualityMeasureData && (<Grid container className={classes.qualityContainer}>
                                    {[
                                        "performanceRate",
                                        "performanceMet",
                                        "performanceNotMet",
                                        "eligiblePopulationExclusion",
                                        "eligiblePopulationException",
                                        "eligiblePopulation"
                                    ].map((key) => {
                                            return (
                                                <Grid key={key} item xs={6}>
                                                    {key === "performanceRate" ? (
                                                        <div className={classes.performanceRate}>
                                                            <strong>{titleCase(key)}</strong>: {qualityMeasureData[key]}%
                                                        </div>
                                                    ) : (
                                                        <React.Fragment>
                                                            <strong>{titleCase(key)}</strong>: {qualityMeasureData[key]}
                                                        </React.Fragment>
                                                    )}
                                                </Grid>
                                            );
                                        })
                                    }

                                    </Grid>
                                    )}
                                </Grid>
                            )}
                        </Grid>
                        {messages.length > 0 && (
                            <div className={classes.messagesContainer}>
                                {messages.map((msg, index) => (
                                    <div key={index}>
                                        <AlertBar
                                            severity={msg.type}
                                            label={msg.title}
                                            dense={"dense"}
                                            size={"small"}
                                        >
                                            {msg.details}
                                        </AlertBar>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={2} className={classes.controlContainer}>
                    {control}
                </Grid>
                {!isReview && exclusions.map((mp) => {
                    return (
                        <React.Fragment key={mp.id}>
                            <Grid item xs={10} className={classes.infoContainer + " " + classes.exclusion}>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <div className={classes.id}>EXCLUSION</div>
                                        {mp.meta.description}
                                    </Grid>
                                    <Grid item className={classes.score} xs={2}>
                                         {mp.meta.measureId}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={2} className={classes.controlContainer+ " " + classes.exclusion}>
                                <BooleanControl
                                    measureProxy={mp}
                                    disabled={isDisabled(mp.id)}
                                    onChange={(e, value) => onChange(
                                        mp.meta.category,
                                        mp.meta.measureId,
                                        mp.meta.metricType,
                                        value,
                                        [...measureProxy.meta.exclusion.filter(it => it !== mp.id), measureProxy.id]
                                    )}
                                />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </Paper>
    );

};

export default Container(
    MeasureResolver,
    () => [
        MeasuresMetaStore,
        MeasuresDraftStore,
        MeasuresScoreStore
    ],
    (state, props) => {
        const exclusions = props.measureProxy.getExclusions();
        let selectedMeasure = null;
        if(exclusions.length > 0) {
            // only one exclusion/measure can be selected at a time.
            // disable the other options until all options are null.
            selectedMeasure = [props.measureProxy, ...exclusions].find(mp => mp.getValue() !== null) || null;
        }
        return {
            ...props,
            messages: props.measureProxy.getMessages(),
            draftData: props.measureProxy.getValue() || selectedMeasure,
            selectedMeasure,
            disabled: props.disabled || MeasuresDraftStore.isLoading(),
            onChange: dispatchOnMeasureChange
        };
    }
);
