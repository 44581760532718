import React from 'react';
import Container from "../../../data/Container";
import {getFilteredMeasureProxies} from "../../../data/dashboard/selectors/measures";
import MeasuresMetaStore from "../../../data/dashboard/MeasuresMetaStore";
import MeasuresDataStore from "../../../data/dashboard/MeasuresDataStore";
import {
    CircularProgress,
    Grid,
    IconButton,
    Icon
} from '@mui/material';
import { makeStyles } from "@mui/styles";
import {EnhancedTable, Paper, Spinner, formatValue, titleCase, FormattedMessage} from "@armus/armus-dashboard";
import {getColumn, getColumns} from "../../../lib/columns";
import MiniChart from "../../components/MiniChart";

const useStyles = makeStyles(theme => ({
    ...theme.spreadIt,
    paper: {
        position: "relative",
        height: 200
    },
    cellContainer: {
        whiteSpace: "nowrap"
    },
    cellText: {
        fontWeight: "bold"
    },
    cellSecondaryText: {
        fontSize: "90%",
        color: "#888"
    },
    id: {
        color: "#888",
        paddingBottom: "2px"
    },
    title: {
        color: theme.palette.primary.main,
        fontWeight: "bold"
    },
    badges: {
        float: "right"
    },
    chip: {
        marginRight: theme.spacing(.5),
        fontSize: 8,
        padding: "2px 4px",
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
        verticalAlign: "middle",
        textTransform: "capitalize"
    },
    chipOutline: {
        marginRight: theme.spacing(.5),
        fontSize: 8,
        padding: "1px 4px",
        borderRadius: 5,
        //backgroundColor: "#fff",
        color: theme.palette.primary.main,
        verticalAlign: "middle",
        border: "1px solid " + theme.palette.primary.main,
        textTransform: "capitalize"
    },
    sufficientBadge: {
        fontSize: "130%",
        fontWeight: "bold"
    }
}));

const MeasuresTable = ({
    onCellClick = () => {},
    onResetActiveCell = () => {},
    activeCell = {},

    isMeasuresLoaded,
    isMeasuresError,
    measures,
    filters,

    isDataLoaded,
    isDataError,
    data
}) => {
    const classes = useStyles();
    const [sort, setSort] = React.useState({orderBy: "measureId", order: "asc"});
    const [expandedIds, setExpandedIds] = React.useState([]);
    const columns = getColumns(filters.granularity);
    if(filters.granularity === "ytd") {
        columns.push({
            key: "months",
            title: "Months",
            shortTitle: "Months",
            isSortable: false
        });
    }
    columns.push(getColumn("ytd"));
    columns.push(getColumn("cmsBenchmark"));
    columns.push(getColumn("sufficient"));

    React.useEffect(() => {
        if(activeCell.measureProxy) {
            onCellClick({}, activeCell.measureProxy, "ytd", columns.length-2, activeCell.y);
        }
    //eslint-disable-next-line
    }, [filters.granularity]);

    if(isMeasuresError) {
        return (
            <Paper className={classes.paper}>
                <FormattedMessage id="apiErrorMessage" data={["measures"]} />
            </Paper>
        );
    }
    if(!isMeasuresLoaded) {
        return (
            <Paper className={classes.paper}>
                <Spinner variant={"linear"} />
            </Paper>
        );
    }

    if(!Array.isArray(measures) || measures.length === 0) {
        return (
            <Paper className={classes.paper}>
                <FormattedMessage id="insufficientData" />
            </Paper>
        );
    }

    const handleExpand = (id, forceOpen = false) => {
        if(expandedIds.indexOf(id) !== -1 && !forceOpen) { // collapse
            setExpandedIds(expandedIds.filter((it) => id !== it));
        }
        else { // expand
            setExpandedIds([...expandedIds, id]);
        }
    };

    const handleRequestSort = (e, columnid) => {
        let order = sort.order;
        if(columnid === sort.orderBy && sort.order === "asc") {
            order = "desc";
        }
        else {
            order = "asc";
        }
        setSort({orderBy: columnid, order: order});
    };
    const measuresSorted = [...measures];
    measuresSorted.sort(
        (a, b) => {
            switch(sort.orderBy) {
                case "sufficient":
                    const aSufficient =  a.isSufficient() && a.canSubmit();
                    const bSufficient =  b.isSufficient() && b.canSubmit();
                    return aSufficient === bSufficient ? 0 : aSufficient > bSufficient ? -1 : 1;
                case "measureId":
                    return a.meta[sort.orderBy].toString().localeCompare(b.meta[sort.orderBy].toString(), navigator.languages[0] || navigator.language, {
                        numeric: true,
                        ignorePunctuation: true
                    });
                default:
                    // sorting by smart performance rate so inverse measures are not last.
                    const aPRate = a.getColumn(sort.orderBy).smartPerformanceRate;
                    const bPRate = b.getColumn(sort.orderBy).smartPerformanceRate;
                    return aPRate === bPRate ? 0 : aPRate > bPRate ? 1 : -1;
            }
        }
    );
    if(sort.order === "desc") {
        measuresSorted.reverse();
    }
    if(activeCell.measureProxy && (activeCell.measureProxy !== measuresSorted[activeCell.y])) {
        const y = measuresSorted.findIndex((mp) => activeCell.measureProxy.id === mp.id);
        if(y !== -1) {
            activeCell.y = y;
        }
    }

    const headerCells = [{
        id: "measureId",
        props: {align: "left", width: "500"},
        disablePadding: false,
        isSortable: true,
        label: "Measure",
        Content: (props) => {
            const measureProxy = props.row;
            const isParent = measureProxy.isMultiComponent();
            const isOpen = expandedIds.indexOf(measureProxy.id) !== -1;
            const children = measureProxy.getComponents();
            return (
                <Grid
                    container
                    direction="row"
                    spacing={0}
                    alignItems="stretch"
                >
                    {(isParent) &&
                    <Grid item xs={1}>
                        <IconButton size="small" onClick={() => handleExpand(measureProxy.id)}>
                            {isOpen ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
                        </IconButton>
                    </Grid>
                    }
                    {(!isParent) && <Grid item xs={1} />}
                    <Grid item xs={(isParent) ? 11 : 11}>
                        <div className={classes.title}>{measureProxy.title()}</div>
                        <div className={classes.id} title={measureProxy.title()}>
                            #{measureProxy.meta.measureId}
                            <span className={classes.badges}>
                                {(!measureProxy.isSmartPerformance() && measureProxy.isInverse()) && <span title="Lower numbers are better." className={classes.chip}>Inverse</span>}
                                {measureProxy.isSmartPerformance() && <span title="Inverse values are normalized." className={classes.chip}>Smart Performance</span>}
                                {measureProxy.meta.isHighPriority && <span className={classes.chipOutline}>High Priority</span>}
                                {measureProxy.meta.measureType && <span className={classes.chipOutline}>{titleCase(measureProxy.meta.measureType)}</span>}
                            </span>
                        </div>
                        {isOpen && children.map((child) => (
                            <div key={child.meta.id} className={classes.id} title={"#" + child.meta.measureId}>
                                {child.title()}
                            </div>
                        ))}
                    </Grid >
                </Grid>
            );
        }
    }];

    columns.forEach((col, x) => {
        headerCells.push({
            id: col.key,
            props: {align: "center", width: col.key === "months" ? "35%" : undefined},
            disablePadding: true,
            isSortable: col.isSortable === undefined ? true : col.isSortable,
            label: col.shortTitle,
            Content: (props) => {
                const measureProxy = props.row;
                if(!isDataLoaded) {
                    return (
                        <CircularProgress size={20} style={{color: "#ccc"}} />
                    );
                }
                if(isDataError) {
                    return (
                        <Icon style={{color: "#ccc"}} >warning</Icon>
                    );
                }
                if(filters.granularity === "ytd" && col.key === "months") {
                    return <SparkGraph measureProxy={measureProxy} />;
                }

                if(col.key === "cmsBenchmark") {
                    const benchmark = measureProxy.getBenchmark();
                    return (
                        <div className={classes.cellText}>
                            {benchmark !== null ? benchmark + "%" : "N/A"}
                        </div>
                    );
                }

                if(col.key === "sufficient") {
                    return (
                        <div
                            className={classes.sufficientBadge}
                            title={`Eligible Population >= 20 ? ${measureProxy.canSubmit() ? "Yes": "No"},\n Data Completeness >= 75% ? ${measureProxy.isSufficient()? "Yes": "No"}`}
                        >
                            {measureProxy.isSufficient() && measureProxy.canSubmit() ?
                                <Icon fontSize={"large"} style={{color: "#1a9850"}}>check_circle_outline_outlined</Icon>
                                : <Icon fontSize={"large"} style={{color: "#aaaaaa"}}>remove_circle_outline_outlined</Icon>
                            }
                        </div>
                    );
                }
                const cell = measureProxy.getColumn(col.key);
                const isParent = measureProxy.isMultiComponent();
                const isOpen = expandedIds.indexOf(measureProxy.id) !== -1;
                const children = measureProxy.getComponents();
                const colorBucket = measureProxy.getColumnColor(col.key, true);

                return (
                    <div className={classes.cellContainer}>
                        <div
                            title={"Performance Rate " + colorBucket.subTitle + " " + colorBucket.title}
                            className={classes.cellText}
                            style={{color: colorBucket.color, backgroundColor: colorBucket.backgroundColor}}
                        >
                            {formatValue("percent", measureProxy.getPerformanceRate(col.key, true) / 100)}
                        </div>
                        <div title="Data Completeness Rate" className={classes.cellSecondaryText}>{formatValue("percent", cell.reportingRate / 100)}</div>
                        {isParent && isOpen && children.map((childMeasureProxy) => {
                            const childColorBucket = childMeasureProxy.getColumnColor(col.key, true);
                            return (
                                <div
                                    key={childMeasureProxy.meta.id}
                                    title={childMeasureProxy.title() + " Performance Rate " + childColorBucket.subTitle + " " + childColorBucket.title}
                                    className={classes.cellText}
                                    style={{color: childColorBucket.color, backgroundColor: childColorBucket.backgroundColor}}
                                >
                                    {formatValue("percent", childMeasureProxy.getPerformanceRate(col.key, true) / 100)}
                                </div>
                            );
                        })}
                    </div>
                );
            }
        });
    });

    return (
        <React.Fragment>
            <Paper style={{padding: 0}}>
                <EnhancedTable
                    rows={measuresSorted}
                    columns={headerCells}
                    dense={true}
                    hover={true}
                    selectedCell={{x: activeCell.x, y: activeCell.y}}
                    onRowClick={() => {}}
                    onCellClick={(event, measureProxy, cell, x, y) => {
                        let cellId;
                        if (x > 0 && !["agg","months","sufficient"].includes(cell.id)) {
                            cellId = cell.id;
                        }
                        else {
                            cellId = "ytd";
                            x = headerCells.length-3;
                        }
                        onCellClick(event, measureProxy, cellId, x, y);
                    }}
                    onRequestSort={handleRequestSort}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    pagination={false}
                    style={{maxHeight: 420}}
                />
            </Paper>
        </React.Fragment>
    );
};

const SparkGraph = ({measureProxy}) => {
    const chartData = [
        {
            label: "Performance Rate",
            value: []
        }
    ];
    const colors = [];
    const aggCell = measureProxy.getColumn("agg");
    const columns = getColumns("months");
    columns.forEach((col) => {
        const color = measureProxy.getColumnColor(col.key, true).backgroundColor;
        chartData[0].value.push({
            label: col.title,
            value: (measureProxy.getPerformanceRate(col.key, true) || 0 ) / 100,
            color
        });
        colors.push(color);
    });
    return (
        <MiniChart
            data={chartData}
            format={"percent"}
            width={250}
            height={50}
            onClick={() => {}}
            config={{
                colorScheme: colors,
                min: 0,
                max: 1,
                markers: [
                    {
                        value: ((measureProxy.isSmartPerformance() ? aggCell.smartPerformanceRate : aggCell.performanceRate) || 0)/100,
                        label: 'CMS Benchmark'
                    }
                ]
            }}
        />
    );
};

export default Container(
    MeasuresTable,
    () => [
        MeasuresMetaStore,
        MeasuresDataStore
    ],
    (state, props) => ({
        ...props,
        isMeasuresLoaded: MeasuresMetaStore.isLoaded(),
        isMeasuresError:  MeasuresMetaStore.isError(),
        measures: getFilteredMeasureProxies(),
        isDataLoaded: MeasuresDataStore.isLoaded(),
        isDataError:  MeasuresDataStore.isError()
    })
);
