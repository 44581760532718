import MeasureMetaStore from "../MeasuresMetaStore";
import MeasursDraftStore from "../MeasuresDraftStore";
// import MeasureScoreStore from "../MeasureScoreStore";
import { titleCase } from "@armus/armus-dashboard";
import MeasuresScoreStore from "../MeasuresScoreStore";

// {
//     get meta() {}, // always accesses latest meta from the MeasureMetaStore
//     id: string
// }

function MeasureProxy(id) {
    const _this = this;
    _this.id = id;

    Object.defineProperty(_this, "meta", {
        get: function () {
            return MeasureMetaStore.getMeasureMetaById(_this.id);
        }
    });

    _this.title = () => {
        // some titles are not title case.
        return titleCase(_this.meta.title);
    };

    _this.getExclusions = () => {
        if(Array.isArray(_this.meta.exclusion)) {
            return _this.meta.exclusion.map((id) => getOrCreateMeasureProxy(id));
        }
        return [];
    };

    _this.hasExclusions = () => {
        return Array.isArray(_this.meta.exclusion);
    };

    _this.isExclusion = () => {
        return _this.meta.reportingCategory === "exclusion";
    };

    _this.getSubstitutions = () => {
        if(Array.isArray(_this.meta.substitute)) {
            return _this.meta.substitute.map((id) => getOrCreateMeasureProxy(id));
        }
        return [];
    };

    _this.hasSubstitutions = () => {
        return Array.isArray(_this.meta.substitute);
    };

    _this.getSelectedSubstitutions = () => {
        const substitutes = _this.getSubstitutions();
        return substitutes.filter(mp => {
            if(mp.hasExclusions()) {
                if(mp.getExclusions().filter(it => it.isSelected()).length) {
                    return true;
                }
            }
            return mp.isSelected();
        });
    };

    _this.isSubstituted = () => {
        return _this.getSelectedSubstitutions().length > 0;
    };

    _this.isSelected = () => {
        const value = MeasursDraftStore.getMeasureValue(_this.meta.category, _this.meta.measureId);
        return value !== null;
    };

    _this.getScoreData = () => {
        return (MeasuresScoreStore.getMeasureScoreData(_this.meta.category, _this.id) || {});
    };

    _this.isPicked = () => {
        return (_this.getScoreData().status || "SKIPPED") === "PICKED" ;
    };

    _this.getMaxScore = (unweighted = false) => {
        const scoreData = _this.getScoreData();
        return (unweighted ? scoreData.unweightedMaxScore : scoreData.maxScore) || 0;
    };

    _this.getScore = (unweighted = false) => {
        const scoreData = _this.getScoreData();
        let score = (unweighted ? scoreData.unweightedScore : scoreData.score) || 0;
        score = Math.round( ( score + Number.EPSILON ) * 100 ) / 100;
        return score;
    };

    _this.getBonusScore = () => {
        const scoreData = _this.getScoreData();
        return scoreData.bonusScore || 0;
    };

    _this.getMessages = () => {
        const messages = [...(_this.getScoreData().messages || [])];
        const order = {
            error: 0,
            warning: 1,
            info: 2,
            success: 3
        };
        messages.sort((a, b) => {
            return a.type === b.type ? 0 : order[a.type] > order[b.type] ? 1 : -1;
        });
        return messages;
    };

    _this.getValue = () => {
        return MeasursDraftStore.getMeasureValue(_this.meta.category, _this.meta.measureId);
    };

    _this.getQualityMeasureData = () => {
        return MeasursDraftStore.getQualityMeasureData(_this.meta.measureId);
    };
}

export default MeasureProxy;

const measureProxyCache = {};
export const getOrCreateMeasureProxy = (id) => {
    if(!measureProxyCache[id]) {
        // only need to create this once because no data is stored in a
        // MeasureProxy other then the id.
        measureProxyCache[id] = new MeasureProxy(id);
    }
    return measureProxyCache[id];
};
