import React from 'react';
import {Notifications as NotificationsView, useNotifications} from "@armus/armus-dashboard";

const Notifications = (props) => {
    const {queue, onCleanup, onHide} = useNotifications();
    return (
        <NotificationsView
            {...props}
            queue={queue}
            onCleanupNotifications={() => {
                onCleanup();
            }}
            onHideNotification={(index) => {
                const lastItem = queue.length > 0 ? queue.length-1 : 0; // remove oldest item on the queue.
                onHide(index !== undefined ? index : lastItem);
            }}
        />
    );
};

Notifications.propTypes = {
    ...Notifications.propTypes
};

export default Notifications;
