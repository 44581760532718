import React from "react";
import { darken } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FormattedMessage, formatMessage, Paper, Main, Button } from "@armus/armus-dashboard";
import Container from "../../../data/Container";
import UserStore from "../../../data/UserStore";
import { buildUrl } from "../../../../routes";
import history from "../../../lib/history";
import ErrorPage from "../../ErrorPage";
import HomeTable from "./HomeTable";
import api from "../../../lib/api";
import config from "../../../../config";

const useStyles = makeStyles((theme) => ({
    content: {
        height: "100%",
        overflow: "scroll"
    },
    paper: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    subtitle: {
        marginBottom: theme.spacing(2)
    },
    tabsContainer: {
        marginLeft: -theme.spacing(2),
        marginRight: -theme.spacing(2),
        flexGrow: 1
    },
    tabs: {
        borderBottom: "1px solid " + darken(theme.palette.background.default, 0.25),
        boxShadow: "0 2px 2px rgba(0,0,0,.25)",
        "& .MuiTabs-flexContainer": {
            borderBottom: "3px solid " + theme.palette.background.default

        },
        "& .MuiTab-root.Mui-selected": {
            fontWeight: "bold"
        },
        "& .MuiTabs-indicator": {
            height: 3
        }
    }
}));


export const consentHomeBreadcrumb = ( year, org = {}, impl = {}) => ({
    label: formatMessage({
        id: "consent.home.breadcrumb",
        defaultMessage: "{year} Submitting Entity Signup"},
        {orgName: org.name, implName: impl.name, year}
    ),
    url: buildUrl("CONSENT", {view: "consent", year, orgKey: org.key, implKey: impl.key})
});

export const verificationHomeBreadcrumb = ( year, org = {}, impl = {}) => ({
    label: formatMessage({
        id: "verification.home.breadcrumb",
        defaultMessage: "{year} Verification Manager"},
        {orgName: org.name, implName: impl.name, year}
    ),
    url: buildUrl("VERIFICATION", {view: "verification", year, orgKey: org.key, implKey: impl.key})
});

export const homeBreadcrumb = (view, year, org = {}, impl = {}) => {
    switch(view) {
        case "verification":
            return verificationHomeBreadcrumb( year, org, impl);
        case "consent":
        default:
            return consentHomeBreadcrumb(year, org, impl);
    }
};

const Home = ({
    isValidURL,
    view,
    year,
    organization,
    implementation,
    onDetailsClick
}) => {
    const classes = useStyles();
    const [items, setItems] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);

    const fetchConsentList = React.useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.loadConsentListData(year, organization.key, implementation.key);
            if(res.status === 200) {
                setItems(res.data);
            }
        }
        catch(error) {
            alert("Something unexpected happened, Please try again in a few moments.");
        }
        setIsLoading(false);
    }, [year, organization.key, implementation.key]);

    React.useEffect(() => {
        fetchConsentList();
    }, [fetchConsentList]);

    if(!isValidURL) {
        // the url is invalid show 404 page.
        return <ErrorPage statusCode={404} />;
    }

    const breadcrumb = homeBreadcrumb(view, year, organization, implementation);

    const canCreate = view !== "verification";

    const title = view !== "verification" ? "Submitting Entity Signup" : "Verification Manager";

    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}, breadcrumb]} fullWidth={true} fullHeight={true}>
            <Box className={classes.content}>
                <Paper className={classes.paper}>
                    <Box p={3}>
                        <Grid container spacing={2} flex={true}>
                            <Grid item xs>
                                <Typography component="h1" variant="h4">
                                    <FormattedMessage
                                        id="consent.title"
                                        defaultMessage={`{year} ${title}`}
                                        values={{orgName: organization.name, implName: implementation.name, year}}
                                    />
                                </Typography>
                                {config.ui.signupClosedBanner ? (
                                    <Typography color={"error.main"}>
                                        The deadline to register to report to MIPS via the GIQuIC 2024 QCDR has passed. <br/>
                                        Data Release Consent Forms added at this time will not be accepted.
                                    </Typography>
                                ) : (
                                    <Typography sx={{opacity: .5}}>Welcome to MIPS {title}. </Typography>
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                <Box marginTop={1} textAlign="right">
                                    {canCreate && <Button
                                        color={"primary-alt"}
                                        size={"large"}
                                        onClick={(e, v) => onDetailsClick(v.value)}
                                        options={[
                                            {
                                                label: "Group Submitting Entity",
                                                value: "new-group"
                                            }, {
                                                label: "Individual Submitting Entity",
                                                value: "new-individual"
                                            }
                                        ]}
                                    >
                                        + Add Submitting Entity
                                    </Button>}
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Paper>
                <HomeTable
                    isLoading={isLoading}
                    items={items}
                    view={view}
                    year={year}
                    organization={organization}
                    implementation={implementation}
                    onEditClick={(v) => onDetailsClick(v.id)}
                />
            </Box>
        </Main>
    );
};

export default Container(
    Home,
    () => [
        UserStore
    ],
    (state, props) => {
        const params = props.match.params;
        const year = params.year;
        const view = params.view;
        const orgKey = params.orgKey;
        const implKey = params.implKey;
        const org = UserStore.getOrganization(orgKey);
        const impl = UserStore.getImplementation(orgKey, implKey);
        const isValidURL = !!org && !!impl;
        return {
            ...props,
            isValidURL,
            year,
            view,
            organization: org || {},
            implementation: impl || {},
            isLoading: UserStore.isLoading(),
            onDetailsClick: (id) => {
                const url = buildUrl(view.toUpperCase() + "_DETAILS", {view: view, year: year, orgKey: orgKey, implKey: implKey, consentId: id});
                history.push(url);
            }
        };
    }
);
