import {
    asyncActionWrapper,
    asyncStatus,
    buildAsyncActions
} from "./utils/utils/asyncReducer";
import Dispatcher from "../Dispatcher";

const actions = buildAsyncActions("submit/measuresDraft");
actions.SAVED = 'submit/measuresDraft/SAVED';
actions.ON_MEASURE_CHANGE = 'submit/measuresDraft/ON_MEASURE_CHANGE';
actions.ON_MEASURE_SET_CHANGE = 'submit/measuresDraft/ON_MEASURE_SET_CHANGE';
actions.SUBMIT = 'submit/measuresDraft/SUBMIT';
export default actions;


export const dispatchloadSubmitDraftData = () => {
    Dispatcher.dispatch({
        type: actions.LOAD
    });
};

export const dispatchOnMeasureChange = (category, measureId, metricType, value, exclusions = []) => {
    Dispatcher.dispatch({
        type: actions.ON_MEASURE_CHANGE,
        category,
        measureId,
        metricType,
        value,
        exclusions
    });
};

export const dispatchOnMeasureSetChange = (category, value) => {
    Dispatcher.dispatch({
        type: actions.ON_MEASURE_SET_CHANGE,
        category,
        value
    });
};

export const dispatchSubmit = () => {
    Dispatcher.dispatch(
        asyncActionWrapper(
            {
                type: actions.SUBMIT
            },
            asyncStatus.LOADING
        )
    );
};
