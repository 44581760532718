import {Dispatcher} from 'flux';

class ApplicationDispatcher extends Dispatcher {

    // add any dispatch middlewares here...
    dispatch(payload) {
        if(process.env.NODE_ENV === "development") {
            console.log(`Dispatcher.dispatch(): "${payload.type}"`, payload);
        }
        super.dispatch(payload);
    }

}

export default new ApplicationDispatcher();
