import React from "react";
import { TextField } from '@mui/material';
import {
    buildPropTypesFromObject,
    buildPropTypesWithDescriptor
} from "../../lib/propTypeHelpers";
import Spinner from "../common/Spinner";

const Text = ({isLoading, value, label, descriptor, disabled, onClick, onChange, onBlur, onFocus, onKeyDown, required, ...extraProps}) => {
    const cfg = defaultConfig(descriptor.config);
    const handleChange = (e) => {
        onChange && onChange(e, e.target.value, descriptor);
    };
    label = descriptor.label || label;
    required = descriptor.required === true ? true : required;
    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        disabled = true;
    }
    return (
        <TextField
            id={descriptor.id}
            name={descriptor.id}
            title={label}
            required={required}
            disabled={disabled}
            variant="outlined"
            label={label}
            format={cfg.format}
            value={value || ""}
            onChange={handleChange}
            onBlur={(e) => onBlur && onBlur(e, value || null, descriptor)}
            onClick={(e) => onClick && onClick(e, value || null, descriptor)}
            onFocus={(e) => onFocus && onFocus(e, value || null, descriptor)}
            onKeyDown={(e) => onKeyDown && onKeyDown(e, value || null, descriptor)}
            fullWidth={true}
            margin={cfg.dense ? "dense" : undefined}
            InputProps={isLoading ? {
                ...extraProps.InputProps,
                endAdornment: (
                    <Spinner size={18} overlay={true} />
                ),
            } : extraProps.InputProps}
            {...extraProps}
        />
    );
};

Text.defaultProps = {
    value: null,
    data: [],
    options: [],
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            dense: true,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Text.defaultProps.descriptor.config,
    ...config
});

Text.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));
export default Text;