import React from "react";
import { makeStyles } from "@mui/styles";
import { AlertBar } from "@armus/armus-dashboard";
import PerformanceDateInput from "./components/PerformanceDateInput";
import MeasuresContainer from "./components/MeasuresContainer";
import CehrtInput from "./components/CehrtInput";
import Container from "../../../data/Container";
import MeasuresMetaStore from "../../../data/submit/MeasuresMetaStore";
import MeasuresDraftStore from "../../../data/submit/MeasuresDraftStore";
import MeasuresScoreStore from "../../../data/submit/MeasuresScoreStore";

const useStyles = makeStyles((theme) => ({
    messagesContainer: {
        marginBottom: theme.spacing(1)
    }
}));

const EditMeasureSet = ({
    stickyRef,
    measureSetProxy,
    onMeasureChange,
    onMeasureSetChange,
    yearInfo
}) => {
    const classes = useStyles();
    const messages = measureSetProxy.getMessages();
    return (
        <React.Fragment>
            {messages.length > 0 && (
                <div className={classes.messagesContainer}>
                    {messages.map((msg, index) => (
                        <div key={index}>
                            <AlertBar
                                severity={msg.type}
                                label={msg.title}
                                dense={"dense"}
                                size={"small"}
                            >
                                {msg.details}
                            </AlertBar>
                        </div>
                    ))}
                </div>
            )}
            {measureSetProxy.isPerformanceDateRequired() && <PerformanceDateInput yearInfo={yearInfo} measureSetProxy={measureSetProxy} onChange={onMeasureSetChange} />}
            {measureSetProxy.isCehrtIdRequired() && <CehrtInput measureSetProxy={measureSetProxy} onChange={onMeasureSetChange} />}
            <MeasuresContainer
                stickyRef={stickyRef}
                measureSetProxy={measureSetProxy}
                onMeasureChange={onMeasureChange}
            />
        </React.Fragment>
    );

};

export default Container(
    EditMeasureSet,
    () => [
        MeasuresMetaStore,
        MeasuresDraftStore,
        MeasuresScoreStore
    ],
    (state, props) => props,
    {pure: false}
);
