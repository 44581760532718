import React from "react";
import {Container} from 'flux/utils';


export default (View, getStores, getState, options = {}) => Container.createFunctional(
    (props) => <View {...props}/>,
    getStores,
    getState,
    {
        withProps: true,
        ...options
    }
);
