import React from 'react';
import {makeStyles} from '@mui/styles/';
import Grid from '@mui/material/Grid';
import DatePicker from '../../../components/DatePicker';
import { isValidPerformaceDate } from '../../../../data/submit/utils/MeasureSetProxy';
import moment from 'moment';
import { FormattedMessage } from "@armus/armus-dashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        textAlign: "left",
        "&.MuiTextField-warning": {
            "& .MuiTextField-root": {
                "& label": {
                    color: theme.palette.warning.dark + " !important"
                },
                "& fieldset": {
                    borderColor: theme.palette.warning.main
                }
            }
        },
        "&.MuiTextField-error": {
            "& .MuiTextField-root": {
                "& label": {
                    color: theme.palette.error.main + " !important"
                },
                "& fieldset": {
                    borderColor: theme.palette.error.main
                }
            }
        },
        "& .MuiTextField-root": {
            width: 226,//"inherit !important",
            margin: theme.spacing(1)/2,
            marginLeft: 0
        }
    },
    required: {
        color: theme.palette.error.main
    }
}));

const PerformanceDateInput = ({measureSetProxy, yearInfo, onChange}) => {
    const value = measureSetProxy.getValue();
    const [state, setState] = React.useState({
        performanceStart: value.performanceStart,
        performanceEnd: value.performanceEnd
    });
    const minDate = moment(yearInfo.year + "-01-01T00:00:00.000Z").utc();
    const maxDate = moment(yearInfo.year + "-12-31T23:59:59.999Z").utc();
    const classes = useStyles();
    const handleChange = (key, v) => {
        const nextState = {
            ...state,
            [key]: !!v ? v.utc() : v
        };
        const valid = isValidPerformaceDate(yearInfo.year, nextState.performanceStart, nextState.performanceEnd, false);
        setState(nextState);
        let performanceStart;
        let performanceEnd;
        if(valid) {
            performanceStart = moment(nextState.performanceStart).toISOString();
            performanceEnd = moment(nextState.performanceEnd).toISOString();
        }
        else {
            performanceStart = null;
            performanceEnd = null;
        }
        if(value.performanceStart !== performanceStart || value.performanceEnd !== performanceEnd) {
            onChange(
                measureSetProxy.id,
                {
                    performanceStart,
                    performanceEnd
                }
            );
        }
    };
    const errorClass = isValidPerformaceDate(yearInfo.year, state.performanceStart, state.performanceEnd, true) ? "" : " MuiTextField-error";
    const requiredLabel = (label) => <React.Fragment>{label} <span className={classes.required}>*</span></React.Fragment>;
    return (
            <Grid container className={classes.root + errorClass}>
                <Grid item xs={6}>
                    <p>
                        <FormattedMessage
                            id="draft.performancePeriodInput.message"
                            defaultMessage="Start by selecting your performance period."
                        />
                    </p>
                </Grid>
                <Grid item xs={6}>
                    <DatePicker
                        id="performanceStart"
                        label={requiredLabel("Performance Start Date")}
                        onChange={(e, v) => handleChange("performanceStart", v)}
                        value={state.performanceStart}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                    <DatePicker
                        id="performanceEnd"
                        label={requiredLabel("Performance End Date")}
                        onChange={(e, v) => handleChange("performanceEnd", v)}
                        value={state.performanceEnd}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </Grid>
            </Grid>
    );
};

export default PerformanceDateInput;
