import {ReduceStore} from 'flux/utils';
import Dispatcher from './Dispatcher';
import {
    buildAsyncReducer,
    asyncInitialState,
    asyncStatus,
    isLoading,
    isError,
    isLoaded,
    asyncReducerStatus
} from "./submit/utils/utils/asyncReducer";
import UserActions from './UserActions';
import api from "../lib/api";
import config from "../../config";
import {getRouteKeyFromImplKey} from "../../routes";

const initialState = {
    ...asyncInitialState,
    data: {
        firstname: null,
        lastname: null,
        username: null,
        email: null,
        organizations: []
    },
    status: asyncStatus.LOADING
};

const userAsyncReducer = buildAsyncReducer(UserActions, initialState);

export const getOrganizations = (state) => {
    return (state.data.organizations || []).map(it =>
        ({
            ...it,
            // remove any implementations from the user object that are not config enabled.
            implementations: it.implementations.filter(it => {
                const view = getRouteKeyFromImplKey(it.key);
                return (
                    (view === "CONSENT" && config.ui.signupEnabled) ||
                    (view === "VERIFICATION" && config.ui.verificationEnabled) ||
                    (view === "DASHBOARD" && config.ui.dashboardEnabled) ||
                    (view === "MIPS" && config.ui.submitEnabled)
                );
            })
        })
    );
};

export const getDefaultOrgKey = (state) => {
    const orgs = getOrganizations(state);
    return orgs.length ? orgs[0].key : "";
};

export const getOrganization = (state, orgKey) => {
    return getOrganizations(state).find((org) => org.key === orgKey);
};

export const getImplementations = (state, orgKey) => {
    const org = getOrganization(state, orgKey);
    if(!org) {
        return [];
    }
    return org.implementations;
};

export const getDefaultImplementationKey = (state, orgKey) => {
    const impls = getImplementations(state, orgKey);
    return impls.length ? impls[0].key : "";
};

export const getImplementation = (state, orgKey, impKey) => {
    const impls = getImplementations(state, orgKey);
    return impls.find((imp) => imp.key === impKey);
};

const requestUserData = () => {
    return api.loadUserData()
        .then((res) => {
            const data = res.data;
            Dispatcher.dispatch({ type: UserActions.LOADED, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            // User is most likely not logged in...
            // Kick the user over to sing-in page.
            window.location = config.endpoints.login;
            return Promise.reject(err);
        });
};

// immediately kick off the initial user data load.
requestUserData();

class UserStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {...initialState};
    }

    reduce(state, action) {
        // standard async actions
        switch(action.type) {
            case UserActions.LOAD:
                requestUserData();
                // start the loading
                return {...state, status: asyncStatus.LOADING};
            case UserActions.PING:
                requestUserData();
                // does nothing to the state ...
                // but the result of the request will might kick them out of
                // the app.
                return state;
            case UserActions.LOGOUT:
                if(action.force) {
                    api.logoutUser();
                }
                else {
                    // do some checks if the user tries to leave in the middle of edits
                    api.logoutUser();
                }
                return state;
            default:
                return userAsyncReducer(state, action);
        }
    }

    getUser() {
        return this.getState().data;
    }

    isLoading() {
        return isLoading(this.getStatus());
    }

    isError() {
        return isError(this.getStatus());
    }

    isLoaded() {
        return isLoaded(this.getStatus());
    }

    getStatus() {
        return asyncReducerStatus(this.getState());
    }

    getOrganizations() {
        return getOrganizations(this.getState());
    }

    getDefaultOrgKey() {
        return getDefaultOrgKey(this.getState());
    }

    getOrganization(orgKey) {
        return getOrganization(this.getState(), orgKey);
    }

    getImplementations(orgKey) {
        return getImplementations(this.getState(), orgKey);
    }

    getDefaultImplementationKey(orgKey) {
        return getDefaultImplementationKey(this.getState(), orgKey);
    }

    getImplementation(orgKey, impKey) {
        return getImplementation(this.getState(), orgKey, impKey);
    }
}

export default new UserStore();
