import React from "react";
import {
    MenuItem,
    Menu,
    Button as BaseButton,
    IconButton,
    Divider,
    Icon,
} from "@mui/material";
import Spinner from "../common/Spinner";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles(() => ({
    root: {
    },
    medium: {
        marginTop: 1,
        height: "37px!important"
    },
    large: {
        marginTop: 0,
        height: 52
    }
}));


const Button = (props) => {
    const classes = useStyles();
    const buttonRef = React.useRef();
    const [isOpen, setOpen] = React.useState(false);
    const [isExited, setExited] = React.useState(true);
    const {descriptor = {}, onClick, options = [], data, onFocus, onBlur, onKeyDown, value, children, dense, isLoading, showCarrot = true, isResolver} = props;
    const label = descriptor.label || props.label;
    let disabled = props.disabled;
    const cfg = defaultConfig(descriptor.config);
    // merge options
    let mergedOptions = [];
    if(data || options || cfg.options) {
        mergedOptions = [...(data || options || [])];
        cfg.options.forEach(a => {
            if (Array.isArray(data) && data.findIndex(b => a.label === b.label) !== -1) return;
            mergedOptions.push(a);
        });
    }
    const newProps = {
        ...props
    };
    delete newProps.isLoading;
    delete newProps.showCarrot;
    delete newProps.descriptor;
    delete newProps.options;
    delete newProps.data;
    delete newProps.children;
    delete newProps.dense;
    delete newProps.isResolver;

    let BtnComponent = BaseButton;
    if(props.variant === "iconButton") {
        BtnComponent = IconButton;
        delete newProps.variant;
    }

    if(!!props.component) {
        delete newProps.component;
        BtnComponent = props.component;
    }

    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        disabled = true;
    }
    let content = null;
    if(mergedOptions.length <= 1 ) {
        const v = mergedOptions.length === 0 ? value : mergedOptions[0];
        content = (
            <BtnComponent
                id={descriptor.id}
                name={descriptor.id}
                sx={isResolver ? {...classes.root, ...(dense || cfg.dense ? classes.medium : classes.large)} : props.sx || {}}
                title={descriptor.description}
                color={cfg.colorScheme}
                variant="contained"
                disableElevation
                {...newProps}
                startIcon={isLoading ? <Spinner size={14} color={"white"} overlay={false} /> : newProps.startIcon}
                disabled={disabled}
                size={newProps.size ? newProps.size : dense || cfg.dense ? "small" : "medium"}
                onClick={(e) => onClick && onClick(e, v, descriptor)}
                onFocus={(e) => {onFocus && onFocus(e, v, descriptor);}}
                onBlur={(e) => {onBlur && onBlur(e, v, descriptor);}}
                onKeyDown={(e) => {onKeyDown && onKeyDown(e, v, descriptor);}}
            >
                {children || label || value}
            </BtnComponent>
        );
    }
    else {
        content = (
            <React.Fragment>
                <BtnComponent
                    ref={buttonRef}
                    id={descriptor.id}
                    name={descriptor.id}
                    sx={isResolver ? {...classes.root, ...(dense || cfg.dense ? classes.medium : classes.large)} : props.sx || {}}
                    title={descriptor.description}
                    color={cfg.colorScheme}
                    variant="contained"
                    disableElevation
                    {...newProps}
                    startIcon={isLoading ? <Spinner size={18} color={"white"}
                                                    overlay={false}/> : newProps.startIcon}
                    disabled={disabled}
                    size={newProps.size ? newProps.size : dense || cfg.dense ? "small" : "medium"}
                    onClick={() => {
                        setExited(false);
                        setOpen(true);
                    }}
                    onFocus={(e) => {
                        onFocus && onFocus(e, value, descriptor);
                    }}
                    onBlur={(e) => {
                        !isOpen && onBlur && onBlur(e, value, descriptor);
                    }}
                    onKeyDown={(e) => {
                        onKeyDown && onKeyDown(e, value, descriptor);
                    }}
                >
                    {children || label || value}
                    {(props.variant !== "iconButton" && showCarrot) &&
                    <Icon>keyboard_arrow_down_icon</Icon>}
                </BtnComponent>
                <Menu
                    anchorEl={buttonRef.current}
                    keepMounted={false}
                    open={isOpen}
                    onClose={() => setOpen(false)}
                    onExited={() => setExited(true)}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left'}}
                    anchorReference={buttonRef.current}

                >
                    {!isExited && mergedOptions.map((o, i) => o === null ? (
                        <Divider key={i}/>) : (
                        <MenuItem
                            key={o.label}
                            onClick={(e) => {
                                setOpen(false);
                                onClick && onClick(e, o, descriptor);
                            }}
                            label={o.label}
                            selected={o.active}
                        >
                            {o.label}
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>
        );
    }
    return content;
};

Button.defaultProps = {
    value: null,
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            options: [],
            dense: true,
            colorScheme: 'primary',
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Button.defaultProps.descriptor.config,
    ...config
});

export default Button;