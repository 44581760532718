import {ReduceStore} from 'flux/utils';
import ActiveMeasureActions from './ActiveMeasureActions';
import FiltersActions from './FiltersActions';
import Dispatcher from '../Dispatcher';
import {getFilteredMeasureProxies} from './selectors/measures';

class ActiveMeasureStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            selected: {
                measureProxy: undefined,
                columnId: undefined,
                x: undefined,
                y: undefined
            }
        };
    }

    reduce(state, action) {
        switch (action.type) {
            case ActiveMeasureActions.select: {
                return {
                    ...state,
                    selected: {
                        measureProxy: action.measureProxy,
                        columnId: action.columnId,
                        x: action.x,
                        y: action.y
                    }
                };
            }
            case FiltersActions.change: {
                if( state.selected.measureProxy !== undefined &&
                    action.filterKey === "implementations") {
                    // user changed the implementation filter
                    // check if measure is still present in the filtered list.
                    // if not reset the selected measure to nothing.
                    const found = getFilteredMeasureProxies()
                        .findIndex(it => it.id === state.selected.measureProxy.id) !== -1;
                    if (!found) {
                        return this.getInitialState();
                    }
                }
                return state;
            }
            case ActiveMeasureActions.reset: {
                return this.getInitialState();
            }
            default:
                return state;
        }
    }

    getActiveMeasure() {
        return this.getState().selected;
    }
}

export default new ActiveMeasureStore();
