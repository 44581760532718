import React from 'react';
import Dispatcher from '../../data/Dispatcher';
import Container from '../../data/Container';
import Filters from './components/Filters';
import MeasuresTable from './components/MeasuresTable';
import BottomTabs from './components/BottomTabs';
import ActiveMeasureActions from "../../data/dashboard/ActiveMeasureActions";
import ActiveMeasureStore from "../../data/dashboard/ActiveMeasureStore";
import UserStore from "../../data/UserStore";
import FiltersStore from "../../data/dashboard/FiltersStore";
import { withTheme, TitleBar, formatMessage, Main } from "@armus/armus-dashboard";
import { makeStyles } from "@mui/styles";
import ColorLegend from "./components/ColorLegend";
import {Box} from "@mui/material";
import { buildUrl } from "../../../routes";
import HasDRCF from "../components/HasDRCF";

const useStyles = makeStyles((theme) => ({
    dashboard: {
        padding: theme.spacing(3),
        height: "100%",
        overflow: "auto"
    }
}));

export const dashboardBreadcrumb = (year, org = {}, impl = {}) => ({
    label: formatMessage({
        id: "dashboard.breadcrumb",
        defaultMessage: "{year} {orgName} {implName}"},
        {orgName: org.name, implName: impl.name, year}
    ),
    url: buildUrl("DASHBOARD", {year, orgKey: org.key, implKey: impl.key})
});

function Dashboard({
    isValidURL,
    year,
    organization,
    implementation,
    activeCell,
    onSetActiveCell,
    onResetActiveCell,
    filters
}) {





    const classes = useStyles();
    const crumb = dashboardBreadcrumb(year, organization, implementation);
    return (
        <Main breadcrumbs={[{label: "Home", url: "/"}, crumb]} fullWidth={true} fullHeight={true}>
            <Box id="Dashboard" className={classes.dashboard}>
                <Box mb={2}>
                    <TitleBar title={crumb.label}>
                        <Filters />
                    </TitleBar>
                </Box>
                <Box mb={2}>
                    <MeasuresTable
                        activeCell={activeCell}
                        onCellClick={onSetActiveCell}
                        onResetActiveCell={onResetActiveCell}
                        filters={filters}
                    />
                </Box>
                <Box mb={2}>
                    <ColorLegend filters={filters}/>

                </Box>
                <Box mb={2}>
                    <BottomTabs
                        activeCell={activeCell}
                        filters={filters}
                    />
                </Box>
            </Box>
        </Main>
    );
}

const WrappedDashboard = (props) => {
    return (
        <HasDRCF year={props.year} orgKey={props.organization.key} implKey={props.implementation.key}>
            <Dashboard {...props} />
        </HasDRCF>
    );
};


export default Container(
    withTheme(WrappedDashboard),
    () => [
        UserStore,
        ActiveMeasureStore,
        FiltersStore
    ],
    (state, props) => {
        const params = props.match.params;
        const year = params.year;
        const orgKey = params.orgKey;
        const implKey = params.implKey;
        const org = UserStore.getOrganization(orgKey);
        const impl = UserStore.getImplementation(orgKey, implKey);
        const isValidURL = !!org && !!impl;
        return {
            filters: FiltersStore.getAllFiltersValue(),
            activeCell: ActiveMeasureStore.getActiveMeasure(),
            isValidURL,
            year,
            organization: org || {},
            implementation: impl || {},
            isLoading: UserStore.isLoading(),
            onSetActiveCell: (event, measureProxy, columnId, x, y) => {
                Dispatcher.dispatch({
                    type: ActiveMeasureActions.select,
                    measureProxy,
                    columnId,
                    x,
                    y
                });
            },
            onResetActiveCell: () => {
                Dispatcher.dispatch({
                    type: ActiveMeasureActions.reset
                });
            }
        };
    }
);
