import {ReduceStore} from 'flux/utils';
import Dispatcher from '../Dispatcher';
import {
    asyncActionStatus, asyncReducerStatus,
    buildAsyncReducer, isError, isLoaded,
    isLoading
} from './utils/utils/asyncReducer';
import { asyncInitialState, asyncStatus } from "./utils/utils/asyncReducer";
import SubmissionReceiptActions from './SubmissionReceiptActions';
import MeasuresDraftActions from './MeasuresDraftActions';
import api from "../../lib/api";
import history from "../../lib/history";
import { buildUrl } from "../../../routes";
import UserActions from "../UserActions";
import LocationActions from "../LocationActions";
import LocationStore from "../LocationStore";
import MeasuresDraftStore from "./MeasuresDraftStore";

const initialState = {
    ...asyncInitialState,
    data: {},
    status: asyncStatus.LOADING,
    isSubmission: false
};
const submissionReceiptAsyncReducer = buildAsyncReducer(SubmissionReceiptActions, initialState);

const requestReceiptData = (params) => {
    const {year, orgKey, implKey, npiId, receiptId} = params;
    return api.loadSubmitReceiptData(year, orgKey, implKey, npiId, receiptId)
        .then((res) => {
            const data = res.data;
            Dispatcher.dispatch({ type: SubmissionReceiptActions.LOADED, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            Dispatcher.dispatch({ type: SubmissionReceiptActions.ERROR });
            return Promise.reject(err);
        });
};

class SubmissionReceiptStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {...initialState};
    }

    reduce(state, action) {
        let match;
        switch(action.type) {
            case UserActions.LOADED:
            case LocationActions.LOCATION_CHANGED:
                this.__dispatcher.waitFor([
                    MeasuresDraftStore.getDispatchToken(),
                    LocationStore.getDispatchToken()
                ]);
                if(!["RECEIPT"].includes(LocationStore.getRouteKey())) {
                    // we only need this data for the above views.
                    return this.getInitialState();
                }
                else if(state.isSubmission) {
                    return state;
                }
                match = LocationStore.getMatch();
                if(match.params.receiptId !== undefined) {
                    requestReceiptData(LocationStore.getParams());
                    return {...state, data: {}, status: asyncStatus.LOADING, isSubmission: false};
                }
                return {...state, data: {}, status: asyncStatus.ERROR};
            case MeasuresDraftActions.SUBMIT:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                match = LocationStore.getMatch();
                state = {...state, data: {}, status: asyncActionStatus(action), isSubmission: true};
                if(asyncActionStatus(action) === asyncStatus.LOADED) {
                    state.data = action.data;
                    // make sure the URL is updated once the receipt id is returned
                    const url = buildUrl("RECEIPT", {...match.params, receiptId: state.data.id});
                    history.replace(url);
                }
                return state;
            default:
                return submissionReceiptAsyncReducer(state, action);
        }
    }
    isLoading() {
        return isLoading(this.getStatus());
    }

    isError() {
        return isError(this.getStatus());
    }

    isLoaded() {
        return isLoaded(this.getStatus());
    }

    getStatus() {
        return asyncReducerStatus(this.getState());
    }
    isSubmission() {
        return this.getState().isSubmission;
    }
    getReceipt() {
        return this.getState().data;
    }
}

export default new SubmissionReceiptStore();
