import {ReduceStore} from 'flux/utils';
import Dispatcher from '../Dispatcher';
import api from '../../lib/api';
import {
    asyncReducerStatus,
    buildAsyncReducer,
    isError,
    isLoaded,
    isLoading
} from './utils/utils/asyncReducer';
import { asyncInitialState, asyncStatus } from "./utils/utils/asyncReducer";
import MeasuresMetaActions from './MeasuresMetaActions';
import UserActions from "../UserActions";
import LocationActions from "../LocationActions";
import LocationStore from "../LocationStore";

const initialState = {
    ...asyncInitialState,
    paramHash: "",
    data: {}
};
const measureMetaAsyncReducer = buildAsyncReducer(MeasuresMetaActions, initialState);

export const getAllMeasuresMeta = (state, category) => {
    const measuresMap = state.data;
    const measures = Object.keys(measuresMap).map(key => measuresMap[key]);
    if(category) {
        return measures.filter(meta => (meta.category === category));
    }
    return measures;
};

export const getMeasureMetaById = (state, id) => {
    const measuresMap = state.data;
    if(!measuresMap.hasOwnProperty(id)) {
        console.log(`MeasuresMetaStore.getMeasureMetaById(): unknown measure id "${id}"`, Object.keys(measuresMap));
        return false;
    }
    return measuresMap[id];
};

const requestMeasuresMetaData = (year, orgKey, implKey) => {
    return api.loadSubmitMeasuresMetaData(year, orgKey, implKey)
        .then((res) => {
            const data = {};
            // create a lookup table
            res.data.measures.forEach((it) => {
                data[it.measureId] = it;
            });
            Dispatcher.dispatch({ type: MeasuresMetaActions.LOADED, data: data });
            return Promise.resolve(res);
        })
        .catch((err) => { // error!
            Dispatcher.dispatch({ type: MeasuresMetaActions.ERROR });
            return Promise.reject(err);
        });
};

class MeasuresMetaStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {...initialState};
    }

    reduce(state, action) {
        let match;
        switch(action.type) {
            case UserActions.LOADED:
            case LocationActions.LOCATION_CHANGED:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                if(!["MIPS", "DRAFT", "RECEIPT"].includes(LocationStore.getRouteKey())) {
                    return state; // we only need this data for the above views.
                }
                match = LocationStore.getMatch();
                if (match && match.params) {
                    const year = match.params.year;
                    const orgKey = match.params.orgKey;
                    const implKey = match.params.implKey;
                    const nextHash = [year, orgKey, implKey].join("/");
                    if (
                        state.paramHash === nextHash &&
                        state.status === asyncStatus.LOADED
                    ) {
                        // data is already loaded for this hash.
                        return state;
                    }
                    requestMeasuresMetaData(
                        year,
                        orgKey,
                        implKey
                    );
                    // start the loading
                    return {
                        ...(state.paramHash !== nextHash ? this.getInitialState() : state),
                        paramHash: nextHash, // only reset state when the url params change.
                        status: asyncStatus.LOADING
                    };
                }
                // something went wrong with the url params.
                return state;
            default:
                return measureMetaAsyncReducer(state, action);
        }
    }

    isLoading() {
        return isLoading(this.getStatus());
    }

    isError() {
        return isError(this.getStatus());
    }

    isLoaded() {
        return isLoaded(this.getStatus());
    }

    getStatus() {
        return asyncReducerStatus(this.getState());
    }

    getAllMeasuresMeta(category) {
        return getAllMeasuresMeta(this.getState(), category);
    }

    getMeasureMetaById(id) {
        return getMeasureMetaById(this.getState(), id);
    }
}

export default new MeasuresMetaStore();
