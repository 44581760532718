import React from "react";
import Spinner from "../common/Spinner";
import Outline from "./components/Outline";
import makeSxStyles from "../../lib/makeSxStyles";
import { Box } from "@mui/material";

const useStyles = makeSxStyles((theme) => ({
    root: {
        minHeight: 37.13,
        marginTop: 1,
        marginBottom: .5,
    }
}));

const Label = (props) => {
    const {descriptor = {}, onClick, options = [], data, value, dense, required, isLoading} = props;
    const label = descriptor.label || props.label;
    const classes = useStyles();
    const cfg = defaultConfig(descriptor.config);
    // merge options
    let mergedOptions = [];
    if(data || options) {
        mergedOptions = (data || []).concat(options || []);
        cfg.options.forEach(a => {
            if (data.findIndex(b => a.label === b.label) !== -1) return;
            mergedOptions.push(a);
        });
    }

    let displayValue = value;
    if(mergedOptions.length > 0) {
        displayValue = (mergedOptions.find((it) => it.value === value) || {}).label || value;
    }

    return (
        <Outline
            sx={classes.root}
            label={label}
            margin={(dense || cfg.dense) ? "dense" : undefined}
            title={descriptor.description}
            fullWidth={true}
            required={required}
            disabled={false}
            onClick={(e) => onClick && onClick(e, value, descriptor)}
        >
            {isLoading ? <Spinner size={18} overlay={true} /> :null}
            <Box className="MuiInputBase-input">{displayValue}</Box>
        </Outline>
    );
};

Label.defaultProps = {
    value: null,
    data: [],
    options: [],
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            options: [],
            colorScheme: 'primary',
            dense: true,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Label.defaultProps.descriptor.config,
    ...config
});

export default Label;