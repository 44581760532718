import React from 'react';
import { dataWithInfo } from "../../../lib/utils";
import { formatValue } from "../../../lib/number";
import { Chart } from '../ChartThemeProvider';
import { Box } from '@mui/material';
import { getColorScheme } from "../../../lib/theme";
import { useTheme } from "@healthcatalyst/react-cashmere";

export const propsFromData = (theme, data, cfg) => {
    // reformat our standard to apache eChart
    const ret = {
        legendLabels: [],
        axisLabels: [],
        data: [], // orderd by legend
        isGroupedData: false
    };
    if (!data.info) {
        data = dataWithInfo(data);
    }
    if (data && data.length > 0) {
        if (Array.isArray(data[0].value)) {
            let colorConfig = {
                cfg,
                colorScheme: cfg.colorScheme,
                steps: data[0].value.length
            };
            const colorScheme = getColorScheme(theme, colorConfig);
            ret.isGroupedData = true;
            ret.legendLabels = data.map(it => it.label);
            ret.axisLabels = data[0].value.map(it => it.label);
            ret.data = [];
            data.forEach((series, index) => {
                const c = colorScheme(series);
                ret.data.push({
                    name: series.label,
                    data: data[index].value.map((it) => {
                        return ({
                            name: it.label,
                            value: it.value,
                            itemStyle: { color: it.color || series.color || c },
                            lineStyle: { color: it.color || series.color || c },
                            areaStyle: { color: it.color || series.color || c, opacity: 0.25 }
                        });
                    })
                });
            });
        }
        else {
            let colorConfig = {
                cfg,
                colorScheme: cfg.colorScheme,
                steps: data.length
            };
            const colorScheme = getColorScheme(theme, colorConfig);
            ret.isGroupedData = false;
            ret.legendLabels = data.map(it => it.label);
            ret.axisLabels = data.map(it => it.label);
            ret.data = [{
                data: data.map((it, index) => {
                    const c = colorScheme(it);
                    return ({
                        name: it.label,
                        value: it.value,
                        itemStyle: { color: it.color || c },
                        lineStyle: { color: it.color || c },
                        areaStyle: { color: it.color || c, opacity: 0.25 }
                    });
                })
            }];
        }
    }
    return ret;
};

export const convertDataToParentChild = (data) => {
    return data.map(it => {
        const ret = {
            name: it.label
        };
        if(Array.isArray(it.value)) {
            ret.children = convertDataToParentChild(it.value);
        }
        else {
            ret.value = it.value;
        }
        return ret;
    });
};

export const convertDataToHeatmap = (data) => {
    const ret = {
        xLabels: [],
        yLabels: [],
        data: [],
    };
    data.forEach((xIt, xIndex) => {
        if(!ret.xLabels.includes(xIt.label)) {
            ret.xLabels.push(xIt.label);
        }
        xIt.value.forEach((yIt, yIndex) => {
            if(!ret.yLabels.includes(yIt.label)) {
                ret.yLabels.push(yIt.label);
            }
            ret.data.push([xIndex, yIndex, yIt.value]);
        });
        
    });
    return ret;
};


const buildConfigFromData = (theme, initialData, descriptor) => {
    const config = defaultConfig(descriptor.config);
    let { legendLabels, axisLabels, data, isGroupedData } = propsFromData(theme, initialData, config);
    let { showZoom, showValues, markers, showColorRange, layoutMode, groupMode, legendPosition, legend, min, max, showToolbox, showAxisX, showAxisY, zoomDataIndex, zoomStart, zoomStartValue, zoomEnd, zoomEndValue } = config;
    let chartType = descriptor.type;
    let extras = {};

    if(isGroupedData && ['line', 'pie'].includes(chartType)) {
        if(!!data && !!data[0] && data[0].data && data[0].data.length === 1) {
            // ungroup the data
            isGroupedData = false;

            legendLabels.legendLabels = data[0].data.map(it => it.name);
            axisLabels.axisLabels = data[0].data.map(it => it.name);
            data = [{
                data: data.map(it => ({ name: it.name, value: it.data[0].value, itemStyle: { color: it.data[0].color }, lineStyle: {color: it.data[0].color} }))
            }];
            legendLabels = data[0].data.map(it => it.name);
            axisLabels = data[0].data.map(it => it.name);
        }
    }



    const labelOption = {
        show: showValues,
        rotate: 0,
        align: 'center',
        verticalAlign: 'middle',
        position: 'top',
        distance: 15,
        formatter: (params) => {
            return formatValue(config.formatValue, params.value);
        },
        fontSize: 12,
        rich: {
            name: {}
        }
    };

    let seriesData = data.map(it => ({
        type: chartType,
        BasicChartGap: 0,
        radius: '65%',
        label: labelOption,
        emphasis: {
            focus: 'series'
        },
        stack: groupMode === "stacked",
        areaStyle: it?.data[0]?.areaStyle?.color ? it?.data[0]?.areaStyle : undefined,
        lineStyle: it?.data[0]?.lineStyle?.color ? it?.data[0]?.lineStyle : {},
        ...it, // name and data
    }));
    if(markers.length) {
        seriesData.push({
            type: "line",
            BasicChartGap: 0,
            radius: '65%',
            emphasis: {
                focus: 'series'
            },
            markLine: {
                silent: true,
                animation: false,
                name: "Benchmark",
                label: {
                    show: true,
                    position: "end",
                },
                symbol: ["none", "none"],
                lineStyle: {
                    type: "dashed",
                },
                data: markers.map(({ label, value, color, ...extra }) => (
                    {
                        name: label,
                        value: value,
                        label: {
                            show: true,
                            position: "insideEndTop",
                            formatter: (it) => `${it.name} - ${it.value}`
                        },
                        // 'average', 'min', and 'max' are supported
                        [layoutMode === 'horizontal' ? "xAxis" : "yAxis"]: value,
                        lineStyle: {
                            color,
                        },
                        ...extra
                    })
                )
            },
        });
    }

    let xAxis = [
        {
            show: showAxisX,
            type: 'category',
            name: config.xLabel || "",
            axisTick: { show: false },
            data: axisLabels,
            min: undefined,
            max: undefined,
            position: 'center',
        }
    ];

    let yAxis = [
        {
            show: showAxisY,
            type: 'value',
            name: config.yLabel || "",
            min: undefined,
            max: undefined,
            position: 'left',
            axisLabel: {
                formatter: (v) => {
                    return formatValue(config.formatValue, v);
                },
            }
        }
    ];
    let tooltip = {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        }
    };

    if(layoutMode === 'horizontal') {
        let temp = xAxis;
        xAxis = yAxis;
        yAxis = temp;
        labelOption.position = "right";
    }

    switch (chartType) {
        case "pie":
            xAxis = undefined;
            yAxis = undefined;
            showZoom = false;
            tooltip.trigger = "item";
            if(layoutMode === "doughnut") {
                seriesData = seriesData.map(it => ({
                    ...it, 
                    radius: ['35%', '65%'],
                }));
            }

            if (isGroupedData) {
                chartType = 'sunburst';
                data = convertDataToParentChild(initialData);
                seriesData = [{
                    type: chartType,
                    data: data,
                    radius: [0, '90%'],
                    label: {
                        rotate: 'radial'
                    }
                }];
            }
            break;
            case "sunburst":
                tooltip.trigger = "item";
                xAxis = undefined;
                yAxis = undefined;
                showZoom = false;
                data = convertDataToParentChild(initialData);
                seriesData = [{
                    type: 'sunburst',
                    data: data,
                    radius: [0, '90%'],
                    label: {
                        rotate: 'radial'    
                    }
                }];
                break;
            case "heatmap":
                data = convertDataToHeatmap(initialData);
                showZoom = false;
                showColorRange = true;
                seriesData = [{
                    type: 'heatmap',
                    data: data.data,
                    label: {
                        show: true,
                        formatter: (params) => {
                            return formatValue(config.formatValue, params.data[2]);
                        },
                        fontSize: 12,
                        rich: {
                            name: {}
                        }
                    },
                }];

                xAxis = [{
                    type: 'category',
                    data: data.xLabels,
                    splitArea: {
                        show: false
                    },
                    formatter: (v) => {
                        return formatValue(config.formatValue, v);
                    },
                }];
                yAxis = [{
                    type: 'category',
                    data: data.yLabels,
                    splitArea: {
                        show: false
                    },
                    formatter: (v) => {
                        return formatValue(config.formatValue, v);
                    },
                }];
                extras = {
                };
                break;
        default:
    }

    const options = {
        animation: false,
        tooltip: tooltip,
        legend: legend ? {
            top: legendPosition,
            //data: legendLabels
        } : undefined,
        toolbox: {
            show: showToolbox,
            orient: 'horizontal',
            left: 'right',
            top: 'top',
            feature: {
                mark: { show: true },
                dataView: { show: true, readOnly: false },
                magicType: { show: false, type: ['bar', 'line', 'stack'] },
                restore: { show: false },
                saveAsImage: { show: true }
            }
        },
        xAxis: xAxis,
        yAxis: yAxis,
        series: seriesData,
        dataZoom: showZoom ? [
            {
                //type: 'inside',
                // optional; index of dataZoom component; useful for are multiple dataZoom components; 0 by default
                dataZoomIndex: zoomDataIndex,
                // percentage of starting position; 0 - 100
                start: zoomStart,
                // percentage of ending position; 0 - 100
                end: zoomEnd,
                // data value at starting location
                startValue: zoomStartValue,
                // data value at ending location
                endValue: zoomEndValue,
            }
        ] : undefined,
        visualMap: showColorRange ? {
            min: min,
            max: max,
            calculable: true,
            orient: showZoom ? 'vertical' : 'horizontal',
            left: showZoom ? 'right' : 'center',
            top: showZoom ?'center' : 'bottom',
            formatter: (v) => {
                return formatValue(config.formatValue, v);
            },
        } : undefined,
        ...extras
    };
    return options;
};



const BasicChart = (props) => {
    const [theme] = useTheme();
    const { descriptor = {}, onClick } = props;
    const onClickWrapped = React.useCallback((args) => {
        onClick(args?.event?.event, args.data, descriptor);
    }, [onClick, descriptor]);
    if (!props.data || !props.data.length) {
        return <Box p={2} textAlign={"center"}>No Data</Box>;
    }
    let options = {};
    if(!!props.data[0].type) {
        // multi series / visual chart
        props.data.forEach((d, index) => {
            const desc = {
                ...descriptor, 
                label: d.label || descriptor.label,
                type: d.type || descriptor.type,
                config: {
                    ...descriptor.config,
                    ...d.config
                }
            };
            const o = buildConfigFromData(theme, d.value, desc);
            if(index === 0) {
                options = o;
            }
            else {
                o.series.forEach(s => {
                    options.series.push(s);
                });
            }
        });
    }
    else {
        options = buildConfigFromData(theme, props.data, descriptor);
    }

    return (
        <Chart options={options} onClick={onClickWrapped} />
    );
};

export const defaultConfig = (config = {}) => ({
    indexBy: "label",
    xLabel: "",
    yLabel: "Value",
    formatValue: null,
    min: 0,
    max: 1,
    grid: "y",
    groupMode: "grouped",
    groupModeInvert: false,
    layoutMode: "vertical",
    legend: true,
    legendPosition: "top",
    showToolbox: true,
    showZoom: true,
    colorScheme: 'series',
    showColorRange: false,
    showAxisX: true,
    showAxisY: true,
    showValues: true,
    markers: [],
    ...config
});

BasicChart.defaultProps = {
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            ...defaultConfig()
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus: undefined, // (event, value, descriptor) => {}
    onBlur: undefined, // (event, value, descriptor) => {}
    onClick: undefined, // (event, value, descriptor) => {}
    onKeyDown: undefined, // (event, value, descriptor) => {}
};

export default BasicChart;
