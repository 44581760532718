import {ReduceStore} from 'flux/utils';
import Dispatcher from './Dispatcher';
import actions from "./LocationActions";
import history from "../lib/history";
import { matchRoute } from "../../routes";


const initialState = {
    current: {
        location: history.location,
        ...(matchRoute(history.location) || {})
    }
};
initialState.last = initialState.current;


class AppStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {...initialState};
    }

    reduce(state, action) {
        switch(action.type) {
            case actions.LOCATION_CHANGED:
                const last = state.current;
                const current = { location: action.location, ...matchRoute(action.location) };
                return { ...state, current, last };
            default:
                return state;
        }
    }

    getLocation() {
        return this.getState().current.location;
    }

    getMatch() {
        return this.getState().current.match;
    }

    getRouteKey() {
        return (this.getState().current.route || {}).key || "";
    }

    getRoute() {
        return this.getState().current.route;
    }

    getParams() {
        return (this.getState().current.match || {}).params || {};
    }

    getLastLocation() {
        return this.getState().last.location;
    }

    getLastMatch() {
        return this.getState().last.match;
    }

    getLastRouteKey() {
        return (this.getState().last.route || {}).key || "";
    }

    getLastRoute() {
        return this.getState().last.route;
    }

    getLastParams() {
        return (this.getState().last.match || {}).params || {};
    }
}

export default new AppStore();
