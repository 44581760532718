import React from 'react';
import { titleCase, InfoChip } from "@armus/armus-dashboard";

const categoryLabels = {
    pi: "Promoting Interoperability",
    ia: "Improvement Activities",
    quality: "Quality"
};

const MeasureChips = ({measureProxy, measureSetProxy}) => {
    const keys = [
        "subcategory",
        "objective",
        "measureType",
        "primarySteward",
        "weight",
        "isRegistryMeasure",
        "isRiskAdjusted",
        "isInverse",
        "isHighPriority",
        "isRequired",
        "isBonus",
        "exclusion"
    ];
    if(measureSetProxy.id === "review") {
        keys.unshift("category");
    }

    return keys.map(key => {
        let value = measureProxy.meta[key];
        if(key === "category") {
            value = categoryLabels[value];
        }
        if(key === "exclusion") {
            value = measureProxy.meta.reportingCategory === "exclusion";
        }
        if(value === null) return null;
        const isBoolean = typeof value === "boolean";
        const label = isBoolean ? key : value;
        if(isBoolean && !value) {
            return null;
        }
        return (
            <InfoChip
                key={key}
                size={"xs"}
                color={"primary"}
                title={isBoolean ? "" : titleCase(key)}
                variant={isBoolean ? "outlined" : "default"}
                label={titleCase(label)}
            />
        );
    });
};


export default MeasureChips;
