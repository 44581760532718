import React from "react";
import PropTypes from "prop-types";
import { Paper, useIntl } from "@armus/armus-dashboard";
import { getColumn } from "../../../lib/columns";
import DecilesTab from "./TabDeciles";
import CaseVolumeTab from "./TabCaseVolume";
import MeasureGraphTab from "./TabMeasureGraph";
import DescriptionsTab from "./TabDescriptions";
import CalculationTab from "./TabCalculation";
import RecordsTab from "./TabRecords";
import {
    AppBar,
    Tabs,
    Tab,
    Typography,
    Box
} from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    tabPanel: {
        width: "100%"
    },
    paper: {
        padding: 0,
        minHeight: 200
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    const hidden = value !== index;
    const classes = useStyles();
    return (
        <Typography
            className={classes.tabPanel}
            component="div"
            role="tabpanel"
            hidden={hidden}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={1}>{!hidden && (typeof children === "function" ? children() : children)}</Box>
        </Typography>
    );
}

TabPanel.propTypes = {
    children: PropTypes.any,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

const BottomTabs = (props) => {
    const classes = useStyles();
    const intl = useIntl();
    const {activeCell, filters} = props;
    let [activeTab, setActiveTab] = React.useState(0);
    const column = getColumn(activeCell.columnId);

    const tabs = [intl.formatMessage({id: "caseVolumeTabTitle"})];
    if(activeCell && activeCell.x >= 0) {
        // add measure tabs
        tabs.push(intl.formatMessage({id: "descriptionTabTitle"}));
        tabs.push(intl.formatMessage({id: "measureGraphTabTitle"}));
        tabs.push(intl.formatMessage({id: "cmsTabTitle"}));
    }
    if(activeCell && activeCell.x > 0) {
        // add cell tabs
        tabs.push(intl.formatMessage({id: "calculationTabTitle"}, [column.title]));
        tabs.push(intl.formatMessage({id: "recordsTabTitle"}, [column.title]));
    }
    if(activeTab >= tabs.length) {
        activeTab = 0;
    }
    return (
        <Paper className={classes.paper}>
            <AppBar position="static" color="default">
                <Tabs
                    value={activeTab}
                    onChange={(e, v) => {setActiveTab(v);}}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="full width tabs example"
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {tabs.map((t) => <Tab key={t} label={t} />)}
                </Tabs>
            </AppBar>
            <div>
                <TabPanel value={activeTab} index={0}>
                    {() => (
                        <CaseVolumeTab activeCell={activeCell} filters={filters} />
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={1}>
                    {() => (
                        <DescriptionsTab activeCell={activeCell}/>
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={2}>
                    {() => (
                        <MeasureGraphTab activeCell={activeCell} filters={filters} />
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={3}>
                    {() => (
                        <DecilesTab activeCell={activeCell} filters={filters} />
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={4}>
                    {() => (
                        <CalculationTab activeCell={activeCell} />
                    )}
                </TabPanel>
                <TabPanel value={activeTab} index={5}>
                    {() => (
                        <RecordsTab activeCell={activeCell} filters={filters} />
                    )}
                </TabPanel>
            </div>
        </Paper>
    );
};

export default BottomTabs;
