export const asyncStatus = {
    INIT: "INIT",
    LOADING: "LOADING",
    LOADED: "LOADED",
    ERROR: "ERROR"
};
export const asyncInitialState = {
    data: {},
    status: asyncStatus.INIT
};

// creates the generic async actions prefixed with the provided key.
export const buildAsyncActions = (key) => ({
    // sync actions
    RESET: `${key}/RESET`,
    SET: `${key}/SET`,
    // async actions
    LOADING: `${key}/LOADING`,
    LOADED: `${key}/LOADED`,
    ERROR: `${key}/ERROR`
});

export const ASYNC_ACTION_STATUS_KEY = "_async_action_status";
export const asyncActionWrapper = (action, status = asyncStatus.INIT) => {
    return {...action, [ASYNC_ACTION_STATUS_KEY]: status};
};
export const asyncActionStatus = (action) => {
    return action[ASYNC_ACTION_STATUS_KEY];
};
export const asyncReducerStatus = (state) => state.status;
export const isLoading = (status) => status === asyncStatus.LOADING;
export const isError = (status) => status === asyncStatus.ERROR;
export const isLoaded = (status) => status === asyncStatus.LOADED;
export const isInit = (status) => status === asyncStatus.INIT;

// creates the generic async reducer based on the provided actions and initial state.
export const buildAsyncReducer = (actions, initialState = asyncInitialState) =>
    (state = initialState, action) => {
        switch(action.type) {
            // Sync Actions
            case actions.SET:
                return {...state, data: {...state.data, [action.key]: action.data}};
            case actions.RESET:
                return {...initialState};
            // Async Actions
            case actions.LOADING:
                return {
                    ...state,
                    status: asyncStatus.LOADING
                };
            case actions.LOADED:
                return {
                    ...state,
                    data: (action.data !== undefined ? action.data : state.data),
                    status: asyncStatus.LOADED
                };
            case actions.ERROR:
                return {
                    ...state,
                    // data: action.data === undefined ? {...initialState.data} : action.data,
                    status: asyncStatus.ERROR
                };
            default:
                return state;
        }
    };
