import React from "react";
import { ChartResolver } from "@armus/armus-dashboard";

const Chart = (props) => {
    const {
        title = "",
        description = "",
        data = [],
        height = 300,
        type = "line",
        format,
        config = {}
    } = props;

    return (
        <div style={{marginTop: 10}}>
            <ChartResolver
                descriptor={{
                    id: title,
                    label: title,
                    description: description,
                    dataKey: "data",
                    type: type,
                    config: {
                        dense: true,
                        height: height,
                        colorScheme: "primary",
                        fullWidth: true,
                        allowTableView: false,
                        legend: true,
                        subLegend: true,
                        min: 0,
                        legendPosition: "bottom",
                        formatValue: format,
                        groupMode: "grouped",
                        layoutMode: "line",
                        ...config
                    }
                }}
                height={height}
                min={0}
                max={100}
                data={{
                    data: data
                }}
            />
        </div>
    );
};
export default Chart;
