import React from "react";
import {AlertBar} from "@armus/armus-dashboard";
import StatusNew from "./StatusNew";
import StatusVerificationPending from "./StatusVerificationPending";

const StatusInvalid = (props) => {
    let body = <StatusNew {...props} />;
    if(props.view === "verification") {
        body = <StatusVerificationPending {...props} />;
    }


    return (
        <>
            <AlertBar severity={"error"} label={"Verification Failed"} open={true}  sx={{maxWidth: "75%", ml: "12.5%", mb: 3}}>
                {props?.data?.verificationMessage || "Your submission was rejected."}
            </AlertBar>

            {body}
        </>
    );
};
export default StatusInvalid;
