import React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Box,
    IconButton,
    Icon,
    Collapse,
} from "@mui/material";
import Spinner from './Spinner';
import makeSxStyles from '../../lib/makeSxStyles';

const useStyles = makeSxStyles(theme => ({
    root: {
        width: '100%',
        marginTop: 0,
        position: "relative",
        "& th": {
            fontWeight: "bold",
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
        },
    },
    loading: {
        position: "absolute",
        width: "100%",
        height: "100%",
        textAlign: "center",
        zIndex: 1000
    },
    table: {
        minWidth: 750,
        height: "100%",
        "& .row-expanded, .row-expanded:hover": {
            backgroundColor: "transparent!important"
        },
        "& .row-collapsed td": {
            border: "none",
        },
        "& td": {
            paddingRight: 2,
        },
        "&.MuiTableExpandable.MuiDataTable-root .MuiTableBody-root": {
            "& .MuiTableRow-root:nth-child(4n-1)": {
                backgroundColor: "transparent"
            },
            "& .MuiTableRow-root:nth-child(4n-1).Mui-selected": {
                backgroundColor: "rgba(0, 174, 255, 0.1)"
            },
            "& .MuiTableRow-root:nth-child(4n-1).Mui-selected:hover": {
                backgroundColor: "rgba(0, 174, 255, 0.2)"
            }
        },
        "&.MuiDataTable-NoHover": {
            "&.MuiDataTable-root .MuiTableBody-root .MuiTableRow-root:hover": {
                backgroundColor: "inherit"
            },
            "&.MuiTableRow-root:nth-child(4n-1).Mui-selected:hover": {
                backgroundColor: "inherit"
            },
            "&.MuiDataTable-root .MuiTableBody-root .MuiTableRow-root:nth-child(odd):hover": {
                backgroundColor: "rgba(127, 127, 127, 0.05)"
            }
        },
    },
    tableWrapper: {
        overflowX: 'auto',
        overflow: "auto",
        height: "100%"
    },
    tableWrapperPagination: {
        overflowX: 'auto',
        overflow: "auto",
        height: "calc(100% - 52px)",
        borderBottom: "1px solid",
        borderBottomColor: theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    headerCell: {
    },
    headerLabel: {
        display: "inline-flex",
        alignItems: "center",
        flexDirection: "inherit",
        justifyContent: "flex-start",
    },
    bodyCell: {
        padding: .25,
    },
    selectedCell: {
        padding: .25,
        position: "relative",
        "&::after": {
            content: '""',
                position: "absolute",
                width: "100%",
                height: "100%",
                boxShadow: "inset 0px 0px 0px 3px #0088cc !important",
                top: 0,
                left: 0,
                pointerEvents: "none",
                zIndex: 1000
        }
        // border: "3px solid #0088cc !important",
    },
    emptyCell: {
        borderBottom: "none"
    }
}));

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, columns, isExpandable } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {isExpandable && <TableCell width={"1%"} />}
                {columns.map(headCell => {
                    const headerCellProps = headCell.props || {};
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headerCellProps.align || "left"}
                            width={headerCellProps.width}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            sx={classes.headerCell}
                            component={"th"}
                        >
                            {!headCell.isSortable ? (
                                <Box sx={classes.headerLabel}>{headCell.label}</Box>
                            ) : (
                                <TableSortLabel
                                    active={orderBy === headCell.id}
                                    direction={order}
                                    onClick={createSortHandler(headCell.id)}
                                >
                                    {headCell.label}
                                    {orderBy === headCell.id ? (
                                        <Box sx={classes.visuallyHidden}>
                                            {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            )}
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    isExpandable: PropTypes.bool,
};

const EnhancedTable = (props) => {
    const classes = useStyles();
    const {
        dataTable = false,
        isLoading = false,
        height = "100%",
        rows = [],
        total = 0,
        columns = [],
        expandedContent = null,
        showHeader = true,
        dense = false,
        rowsPerPage = 25,
        selectedCell = {},
        selectedType = "row",
        page = 0,
        onChangePage = () => {},
        onPageChange,
        onChangeRowsPerPage = () => {},
        onRowsPerPageChange,
        onRequestSort = () => {},
        onRowClick = () => {},
        onCellClick = () => {},
        onExpand = () => {},
        rowsPerPageOptions = [5, 10, 25],
        order = "asc",
        orderBy = "",
        hover = false,
        pagination = false,
        fillEmptyRows = false,
        emptyMessage = "",
        style = {}
    } = props;

    const emptyRows = fillEmptyRows ? rowsPerPage - Math.min(rowsPerPage, rows.length - rowsPerPage) : 0;
    const ExpandedContent = expandedContent || null;
    const isExpandable = !!ExpandedContent;
    return (
        <Box sx={{...classes.root, ...props.sx}} style={{height: height}}>
            {isLoading && (
                <Box sx={classes.loading}><Spinner variant={"linear"} /></Box>
            )}
            <Box sx={pagination ? classes.tableWrapperPagination : classes.tableWrapper} style={style}>
                <Table
                    className={`${!hover ? "MuiDataTable-NoHover" : ""} ${(dataTable ? "MuiDataTable-root" : "")} ${(isExpandable ? "MuiTableExpandable" : "")}`}
                    sx={classes.table}
                    size={dense ? 'small' : 'medium'}
                    stickyHeader
                >
                    {showHeader && <EnhancedTableHead
                        classes={classes}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={onRequestSort}
                        columns={columns}
                        isExpandable={isExpandable}
                    />}
                    <TableBody>
                        {rows.map((row, y) => {
                            const isRowSelected = selectedType !== "none" && selectedCell.y === y;
                            return (
                                <React.Fragment
                                    key={y}
                                >
                                    <TableRow
                                        hover={hover}
                                        onClick={event => onRowClick && onRowClick(event, row, y)}
                                        role="checkbox"
                                        tabIndex={-1}
                                        selected={isRowSelected && selectedType !== "cell"}
                                    >
                                        {isExpandable && (
                                            <TableCell
                                                key={"expandCell"}
                                                onClick={event => onCellClick && onCellClick(event, row, null, 0, y)}
                                            >
                                                <IconButton 
                                                    aria-label="expand row" 
                                                    size="small"
                                                    onClick={event => onExpand && onExpand(event, row, 0, y)}
                                                >
                                                    {isRowSelected ? <Icon>keyboard_arrow_up_icon</Icon> : <Icon>keyboard_arrow_down_icon</Icon>}
                                                </IconButton>
                                            </TableCell>
                                        )}
                                        {columns.map((cell, x) => {
                                            const isColumnSelected = selectedType === "none" ? false : selectedCell.x === x;
                                            const isCellSelected = selectedType !== "cell" || selectedType === "none" ? false :
                                                selectedCell.y === undefined && isColumnSelected ?
                                                    true : selectedCell.x === undefined && isRowSelected ?
                                                        true: isColumnSelected && isRowSelected;
                                            
                                            const cellContentProps = {
                                                row, // row data
                                                cell, // descriptor
                                                x,
                                                y,
                                                isRowSelected,
                                                isColumnSelected,
                                                isCellSelected
                                            };
                                            const Content = cell.Content || cell.content || null;
                                            const cellContent = React.isValidElement(Content) ?
                                                <Content {...cellContentProps} /> :
                                                    typeof Content === "function" ?
                                                        Content(cellContentProps) :
                                                            Content === undefined ? row[cell.id] : Content;
                                            const cellProps = (typeof cell.props === "function" ? cell.props(cellContentProps) : cell.props || {});
                                            // const rowProps = (typeof row.props === "function" ? row.props(cellContentProps) : row.props || {});
                                            const sx = {
                                                ...(isCellSelected ? classes.selectedCell : classes.bodyCell),
                                                //...rowProps.sx,
                                                ...cellProps.sx,
                                            };
                                            const extraProps = {
                                                //...rowProps,
                                                ...cellProps,
                                                sx,
                                            };
                                            return (
                                                <TableCell
                                                    key={cell.id}
                                                    padding={(cell.disablePadding ? "none" : "default")}
                                                    onClick={event => onCellClick && x > 0 && onCellClick(event, row, cell, x, y)}
                                                    {...extraProps}
                                                >
                                                    {cellContent}
                                                </TableCell>
                                            );
                                        })}
                                    </TableRow>

                                    {isExpandable && (
                                        <TableRow className={isRowSelected ? "row-expanded" : "row-collapsed"}>
                                            <TableCell style={{ padding: 0 }} colSpan={columns.length+1}>
                                                <Collapse in={isRowSelected} timeout="auto" unmountOnExit>
                                                    {React.isValidElement(ExpandedContent) ?
                                                        <ExpandedContent {...{
                                                            row,
                                                            y,
                                                            isRowSelected
                                                        }} /> :
                                                        typeof ExpandedContent === "function" ?
                                                            ExpandedContent({
                                                                row,
                                                                y,
                                                                isRowSelected
                                                            }) : ExpandedContent
                                                    }
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            );
                        })}
                        {(rows.length === 0 && !isLoading) && (
                            <TableRow>
                                <TableCell sx={classes.emptyCell} colSpan={columns.length}>
                                    {emptyMessage}
                                </TableCell>
                            </TableRow>
                        )}
                        {emptyRows > 0 && (
                            <TableRow sx={classes.emptyCell}  style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                <TableCell colSpan={columns.length} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Box>
            {pagination && <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onPageChange={onPageChange || onChangePage}
                onRowsPerPageChange={onRowsPerPageChange || onChangeRowsPerPage}
            />}
        </Box>
    );
};

EnhancedTable.defaultProps = {
    isLoading: false,
    dataTable: false,
    showHeader: true,
    rows: [],
    total: 0,
    columns: [],
    expandedContent: undefined,
    dense: false,
    height: undefined,
    selectedCell: {},
    page: 0,
    emptyMessage: "No items to show.",

    // sorting props
    order: "asc",
    orderBy: "",

    // pagination props
    rowsPerPage: 10,
    fillEmptyRows: false,
    rowsPerPageOptions: [10, 25, 50],
    hover: false,
    pagination: false,
};

EnhancedTable.propTypes = {
    isLoading: PropTypes.bool,
    dataTable: PropTypes.bool,
    showHeader: PropTypes.bool,
    rows: PropTypes.array,
    total: PropTypes.number,
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.any,
        Content: PropTypes.any,
        content: PropTypes.any,
        disablePadding: PropTypes.bool,
        isSortable: PropTypes.bool,
        props: PropTypes.shape,
    })),
    expandedContent: PropTypes.any,
    dense: PropTypes.bool,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedType: PropTypes.oneOf(["row", "cell", "none"]),
    selectedCell: PropTypes.shape({
        x: PropTypes.number,
        y: PropTypes.number,
    }),
    page: PropTypes.number,
    emptyMessage: PropTypes.any,
    // row interactions
    onRowClick: PropTypes.func,
    /*
    onCellClick(event, row, cell, x, y)
    */
    onCellClick: PropTypes.func,
    /*
    onExpand(event, row, x, y)
    */
    onExpand: PropTypes.func,

    // sorting props
    onRequestSort: PropTypes.func,
    order: PropTypes.string,
    orderBy: PropTypes.string,

    // pagination props
    rowsPerPage: PropTypes.number,
    fillEmptyRows: PropTypes.bool,
    onChangePage: PropTypes.func,
    onChangeRowsPerPage: PropTypes.func,
    rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
    hover: PropTypes.bool,
    pagination: PropTypes.bool,
};

export default EnhancedTable;