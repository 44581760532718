import React from "react";
import {Box, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Button, Text, Paper} from "@armus/armus-dashboard";

const StatusVerified = ({
    data,
    onClose,
    onSave
}) => {
    const [localData, setLocalData] = React.useState(data);

    const handleChange = (key, value) => {
        setLocalData((old) => ({...old, [key]: value}));
    };

    const handleSubmit = async () => {
        const saveData = {
            ...localData,
            status: "PENDING_SIGNATURE"
        };
        if(localData.entityType === "individual") {
            // add the signerName
            saveData.signerName = data.npiList[0]?.name || "";
        }
        await onSave(saveData);// should automatically advance to next view
    };

    const canSubmit = (localData.entityType === "individual" ? true : !!localData.signerName) && !!localData.signerEmail && localData.signerEmail.includes('@') &&  localData.signerEmail.includes('.');

    const authorizedTitle = localData.entityType === "individual" ? "Physician" : "Authorized Group Representative";

    return (
        <Paper p={4}  sx={{maxWidth: "75%", ml: "12.5%", mb: 3}}>
            <Typography variant={"h2"}>{authorizedTitle}</Typography>
            <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>Please provide the {authorizedTitle.toLowerCase()} email who will be signing the legal documents.</Typography>
            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>{authorizedTitle} Information</Typography>
            <Box ml={4}>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>

                        {localData.entityType === "individual" ? (
                            <Typography variant={"h3"} ml={2}>{data.npiList[0]?.name || ""}</Typography>
                        ) : (
                            <Text
                                label={"Name"}
                                value={localData.signerName || ""}
                                size={"large"}
                                onChange={(e, v) => handleChange("signerName", v)}
                            />
                        )}
                    </Grid>
                    <Grid item xs={7} alignSelf={"center"}>
                        <Box pl={2}>{authorizedTitle} first and last name.</Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} mb={2}>
                    <Grid item xs={5}>
                        <Text
                            label={"Email"}
                            value={localData.signerEmail || ""}
                            size={"large"}
                            onChange={(e, v) => handleChange("signerEmail", v)}
                        />
                    </Grid>
                    <Grid item xs={7} alignSelf={"center"}>
                        <Box pl={2}>Eligible clinician email to which the Docusign link will be sent to complete the online Data Release Consent Form (DRCF).</Box>
                    </Grid>
                </Grid>
            </Box>

            <Box textAlign={"right"} mt={4}>
                <Button
                    color={"secondary"}
                    size={"large"}
                    onClick={onClose}
                >
                    Cancel
                </Button> &nbsp;
                <Button
                    color={"primary-alt"}
                    size={"large"}
                    onClick={handleSubmit}
                    disabled={!canSubmit}
                >
                    Submit
                </Button>
            </Box>
        </Paper>
    );
};
export default StatusVerified;
