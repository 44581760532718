import {cache} from "@armus/armus-dashboard";

// dirt simple debounce
export const debouncePromise = (callback, wait = 250) => {
    let timer;
    return (...args) => new Promise((resolve, reject) => {
        clearTimeout(timer);
        timer = setTimeout(
            () => callback(...args)
                .then((res) => resolve(res))
                .catch((err) => reject(err))
            , wait);
    });
};

// cache promise response
export const cachePromise = (promise) => {
    // wrap a promise with caching of the response
    return (...args) => {
        const key = cache.generateCacheKey({...args});
        if (cache.hasKey(key)) {
            // Visually this is so fast that it looks like nothing has changed
            // when loading from cache. By adding a small delay it make the app
            // feel like it is actually doing.
            const res = cache.load(key);
            return new Promise(resolve => {
                setTimeout(() => resolve(res), 250);
            }) ;
        }
        return promise(...args).then(
            (res) => {
                cache.save(key, res);
                return Promise.resolve(res);
            }
        );
    };
};

export const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
};
