import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {EnhancedTable, formatMessage, FormattedMessage} from "@armus/armus-dashboard";
import { makeStyles } from "@mui/styles";
import SmartTooltip from "../../components/SmartTooltip";
import config from "../../../../config";

const useStyles = makeStyles((theme) => ({
    scoreChip: {
        minWidth: 50,
        fontWeight: "bold",
        fontSize: 14
    },
    enhancedTable: {
        "& > div": {
            minHeight: 0
        }
    }
}));

const buildTableColumns = (classes, type, onReceiptClick) => {
    return [
        {
            id: "tin",
            isSortable: true,
            label: formatMessage({id: "homeTable.headerLabel.tin", defaultMessage: "TIN"}),
            props: { align: "left", sx: {pl: 2} },
            Content: ({row, cell}) => {
                return (
                    <strong>{row[cell.id]}</strong>
                );
            }
        },
        {
            id: "npi",
            isSortable: type === "individual",
            label: (
                <SmartTooltip
                    arrow
                    useIcon={true}
                    title={(
                        <FormattedMessage
                            id="homeTable.tooltip.npiTip"
                            defaultMessage="Click the NPI to visit CMS's website to determine eligibility for this physician."
                        />
                    )}
                >
                    {formatMessage({id: "homeTable.headerLabel.npi", defaultMessage: "NPI"})}
                </SmartTooltip>
            ),
            props: { align: "left" },
            Content: ({row, cell}) => {
                return row.individuals.map(it => (
                    <div key={it.npi}>
                        {it[cell.id] && <strong>
                            <Link href={"https://qpp.cms.gov/participation-lookup?npi=" + it[cell.id]} target="_blank">{it[cell.id]}</Link>
                        </strong>}
                    </div>
                ));
            }
        },
        {
            id: "nppesName",
            isSortable: type === "individual",
            label: (
                <SmartTooltip
                    arrow
                    useIcon={true}
                    title={(
                        <FormattedMessage
                            id="homeTable.tooltip.nppesTip"
                            defaultMessage="CMS Name is the name of the provider as it appears in CMS's NPI lookup tool."
                        />
                    )}
                >
                    {formatMessage({id: "homeTable.headerLabel.cmsName", defaultMessage: "CMS Name"})}
                </SmartTooltip>
            ),
            props: { align: "left" },
            Content: ({row, cell}) => {
                return row.individuals.map(it => <div key={it.npi}>{it[cell.id]}</div>);
            }
        },
        {
            id: "physicianName",
            isSortable: type === "individual",
            label: formatMessage({id: "homeTable.headerLabel.registryName", defaultMessage: "Registry Name"}),
            props: { align: "left" },
            Content: ({row, cell}) => {
                const npiNames = row.individuals.map(it => <div key={it.npi}>{it[cell.id]}</div>);
                return (
                    <>
                        {!!row.groupName && <strong>{row.groupName}</strong>}
                        {npiNames}
                    </>
                );
            }
        },
        {
            id: "lastSubmitted",
            isSortable: true,
            label: formatMessage({id: "homeTable.headerLabel.lastSubmitted", defaultMessage: "Last Submitted"}),
            props: { align: "right" },
            Content: ({row, cell}) => {
                if(row[cell.id] !== undefined && row[cell.id] !== null) {
                    return (
                        <Link
                            href="src/app/views/Submit/Home#"
                            onClick={(e) => {
                                e.preventDefault();
                                onReceiptClick(row.id, row.lastSubmittedSubmissionSetId, type);
                            }}
                        >
                            {moment(row[cell.id]).format(config.ui.dateTimeFormat)}
                        </Link>
                    );
                }
                return null;
            }
        },
        {
            id: "lastSubmittedScore",
            isSortable: true,
            label: (
                <SmartTooltip
                    arrow
                    useIcon={true}
                    title={(
                        <FormattedMessage
                            id="homeTable.tooltip.scoreTip"
                            defaultMessage="Score according to CMS at the time of submission. Scores are subject to change based on periodic policy updates, eligibility reviews, and technical integration developments."
                        />
                    )}
                >
                    {formatMessage({id: "homeTable.headerLabel.score", defaultMessage: "Provisional Score"})}
                </SmartTooltip>
            ),
            props: { align: "center" },
            Content: ({row, cell}) => {
                const score = row[cell.id];
                if(row[cell.id] != null) {
                    return <Chip className={classes.scoreChip} color="primary" label={score} />;
                }
                return <Chip className={classes.scoreChip}  color="default" label={""} />;
            }
        }
    ];
};

const HomeTable = ({isLoading, npis, canSubmit, onEditClick, onReceiptClick, type = "individual"}) => {
    const classes = useStyles();
    const [sort, setSort] = React.useState({orderBy: undefined, order: "asc"});
    const rows = npis.filter(it => it.entityType === type).map(npi => {
            if(type === "individual") {
                return {
                    ...npi,
                    individuals: [{
                        ...npi,
                        "physicianName": npi.name
                    }]
                };
            }
            else {
                const individuals = npis.filter(it => it.tin === npi.tin).map(it => ({
                    ...it,
                    "physicianName": it.name
                }));
                return {
                    ...npi,
                    individuals
                };
            }
        });
    const columns = buildTableColumns(classes, type, onReceiptClick);

    // there is no longer a gate for editing drafts. The gate is only on the final submit.
    columns.push(
        {
            id: "action",
            isSortable: false,
            label: "",
            props: { align: "right" },
            Content: ({row}) => {
                return (
                    <Button
                        dense="small"
                        color="primary-alt"
                        variant="contained"
                        onClick={() => onEditClick(row.id, type)}
                    >
                        <FormattedMessage
                            id="homeTable.editButton"
                            defaultMessage="Edit Submission"
                        />
                    </Button>
                );
            }
        }
    );

    const handleRequestSort = (e, columnid) => {
        let order = sort.order;
        if(columnid === sort.orderBy && sort.order === "asc") {
            order = "desc";
        }
        else if(columnid === sort.orderBy && sort.order === "desc") {
            // reset sort to provided order.
            order = "asc";
            columnid = undefined;
        }
        else {
            order = "asc";
        }
        setSort({orderBy: columnid, order: order});
    };

    const sortedRows = [...rows];
    if(sort.orderBy) {
        sortedRows.sort(
            (a, b) => {
                const aSortValue = a[sort.orderBy];
                const bSortValue = b[sort.orderBy];
                return (aSortValue || "").toString().localeCompare((bSortValue || "").toString(), navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                });
            }
        );
        if(sort.order === "desc") {
            sortedRows.reverse();
        }
    }

    return (
        <React.Fragment>
            <div className={classes.enhancedTable}>
                <EnhancedTable
                    isLoading={isLoading}
                    rows={sortedRows}
                    showHeader={true}
                    columns={columns}
                    dense={false}
                    hover={true}
                    onRequestSort={handleRequestSort}
                    order={sort.order}
                    orderBy={sort.orderBy}
                    pagination={false}
                />
            </div>
            {sortedRows.length === 0 && (
                <Box textAlign={"center"} pt={2}>
                    <FormattedMessage id="home.noNPIData" defaultMessage="No Individual TIN/NPI records available." />
                </Box>
            )}
        </React.Fragment>
    );
};

HomeTable.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    npis: PropTypes.array.isRequired,
    canSubmit: PropTypes.bool.isRequired,
    onEditClick: PropTypes.func.isRequired,
    onReceiptClick: PropTypes.func.isRequired
};

export default HomeTable;
