import Dispatcher from "./Dispatcher";

const actions = {
    LOCATION_CHANGED: "location/CHANGE"
};
export default actions;

export const dispatchLocationChange = (location) => {
    Dispatcher.dispatch({
        type: actions.LOCATION_CHANGED,
        location
    });
};
