import React from "react";
import {buildPropTypesFromObject, buildPropTypesWithDescriptor} from "../../lib/propTypeHelpers";
import {
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    ListItemText,
    Select as BaseSelect,
    Box,
} from "@mui/material";
import Spinner from "../common/Spinner";
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    formControl: {
        marginTop: 1,
        marginBottom: .5,
        "& legend": {
            visibility: "hidden"
        }
    },
    chips: {
        display: "flex",
        flexWrap: "wrap",
        margin: "-0px 0px"
    },
    denseChips: {
        display: "flex",
        flexWrap: "wrap",
        margin: "-4px 0px",
    },
    chip: {
        marginRight: theme.spacing(1),
        marginLeft: -theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    count: {
        flex: "flex-grow",
        fontSize: 12,
        textAlign: "right",
        flexGrow: 4,
        opacity: .7
    }
}));

const EMPTY_OPTION_VALUE = "EMPTY_OPTION_VALUE";

const Select = ({isLoading, descriptor = {}, disabled, data = [], value, onClick, label, options = [], onChange, onBlur, onFocus, onKeyDown, inputProps = {}, chipProps, required, ...extraProps}) => {
    const classes = useStyles();
    const cfg = defaultConfig(descriptor.config);

    label = descriptor.label || label;
    let mergedOptions = [];
    if(data || options || cfg.options) {
        mergedOptions = (data || []).concat(options || []);
        (cfg.options || []).forEach(a => {
            if (data.findIndex(b => a.label === b.label) !== -1) return;
            mergedOptions.push(a);
        });
    }
    mergedOptions = mergedOptions.map(it => ({...it}));
    const isMultivalued = cfg.multivalued || extraProps.multiple || false;
    // Empty value option work around
    // https://github.com/mui/material-ui/issues/12336
    const rawValue = value;
    if(!value && !isMultivalued) {
        const hasEmptyOption = mergedOptions.find((o) => o.value === "");
        if(hasEmptyOption) {
            hasEmptyOption.value = EMPTY_OPTION_VALUE;
            value = EMPTY_OPTION_VALUE;
        }
    }
    const handleChange = (e) => {
        let v = e.target.value;
        if(!isMultivalued && v === EMPTY_OPTION_VALUE) {
            v = "";
        }
        onChange && onChange(e, v, descriptor);
    };

    required = descriptor.required === true ? true : required;
    disabled = descriptor.enabled === false ? true : disabled;
    if(isLoading) {
        mergedOptions = [];
        disabled = true;
    }

    if(isMultivalued) {
        value = !Array.isArray(value) ? [] : value;
    }
    else {
        value = value === null || value === undefined || Array.isArray(value) ? null : value;
    }

    const handleDelete = (e, v, index) => {
        e.preventDefault();
        e.stopPropagation();
        const newValue = [...value];
        newValue.splice(index, 1);
        onChange && onChange(e, newValue, descriptor);
    };

    const renderValue = isMultivalued ? (selected) => (
        <Box sx={cfg.dense ? classes.denseChips : classes.chips}>
            {selected.map((value, index) => (
                <Chip
                    key={value}
                    label={getOptionByValue(mergedOptions, value).label}
                    color={"default"}
                    size={cfg.dense ? "small" : "medium"}
                    sx={classes.chip}
                    onMouseDown={(event) => {
                        event.stopPropagation();
                    }}
                    onDelete={(e) => handleDelete(e, value, index)}
                    {...chipProps}
                />
            ))}
        </Box>
    ) : (selected) => getOptionByValue(mergedOptions, selected).label;
    return (
        <FormControl
            variant="outlined"
            fullWidth={true}
            sx={classes.formControl}
            {...extraProps}
        >
            {isLoading ? <Spinner size={18} overlay={true} /> : null}
            <InputLabel
                id={descriptor.id + "Label"}
                htmlFor={descriptor.id}
                required={required}
            >
                {label}
            </InputLabel>
            <BaseSelect
                id={descriptor.id}
                labelId={descriptor.id + "Label"}
                label={label}
                name={descriptor.id}
                native={cfg.native}
                title={descriptor.description}
                required={required}
                disabled={disabled}
                key={descriptor.id+(isMultivalued ? "multi" : "single")}
                multiple={isMultivalued}
                value={value || ""}
                onClick={(e) => onClick && onClick(e, rawValue, descriptor)}
                onChange={handleChange}
                onBlur={(e) => onBlur && onBlur(e, rawValue, descriptor)}
                onFocus={(e) => onFocus && onFocus(e, rawValue, descriptor)}
                onKeyDown={(e) => onKeyDown && onKeyDown(e, rawValue, descriptor)}
                renderValue={renderValue}
                {...inputProps}
            >
                {mergedOptions.map(option => (
                    cfg.native ? (
                        <option key={option.label} value={option.value} disabled={option.disabled}>{option.label}</option>
                    ) : (
                        <MenuItem key={option.label} value={option.value} disabled={option.disabled}>
                            <ListItemText primary={option.label} />
                            {(cfg.showCounts) && <Box sx={classes.count}>{option.count || (cfg.hideZeroCounts ?  "" : "0")}</Box>}
                        </MenuItem>
                    )
                ))}
            </BaseSelect>
        </FormControl>
    );
};

const getOptionByValue = (options, value) => {
    let option = options.find((opt) => opt.value === value);
    if(!option) {
        return {label: value, value:value};
    }
    return option;
};

Select.defaultProps = {
    value: null,
    data: [],
    options: [],
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            multivalued: false,
            options: [],
            dense: true,
            showCounts: false,
            hideZeroCounts: false,
            allowClear: true,
            native: false,
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus:  undefined, // (event, value, descriptor) => {}
    onBlur:  undefined, // (event, value, descriptor) => {}
    onClick:  undefined, // (event, value, descriptor) => {}
    onKeyDown:  undefined, // (event, value, descriptor) => {}
};

export const defaultConfig = (config = {}) => ({
    ...Select.defaultProps.descriptor.config,
    ...config
});

Select.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject(defaultConfig()));

export default Select;