import MeasureFilterBar from "./MeasureFilterBar";
import MeasureResolver from "./MeasureResolver";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {
    buildMeasureFiltersObject,
    filterMeasureProxies
} from "../../../../data/submit/selectors/measures";
import React from "react";
import { alpha } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { AlertBar } from "@armus/armus-dashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    disabledOverlay: {
        position: "absolute",
        top: 0,
        left: "-16px",
        width: "calc(100% + 32px)",
        height: "100%",
        backgroundColor: alpha(theme.palette.background.paper, .75),
        zIndex: 3
    },
    disabledMessage: {
        width: "calc(100% - "+ 8*8 +"px)",
        marginLeft: 8*4,
        position: "fixed",
        padding: theme.spacing(0, 4)
    },
    measuresContainer: {
        marginTop: 2,
        paddingBottom: theme.spacing(2),
        marginRight: -theme.spacing(2),
        marginLeft: -theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    }
}));

const handleFilterChange = (filters, filterKey, valueKey, value = null, callback) => {
    const nextFilters = {
        ...filters,
        [filterKey]: {
            ...filters[filterKey],
            value: value !== null ? value : filters[filterKey].value
        }
    };
    if(valueKey !== null) {
        nextFilters[filterKey].values = {
            ...filters[filterKey].values,
            [valueKey]: {
                ...filters[filterKey].values[valueKey],
                selected: !filters[filterKey].values[valueKey].selected
            }
        };
    }
    callback(nextFilters);
};

const MeasuresContainer = ({
    isReview = false,
    measureSetProxy,
    stickyRef,
    onMeasureChange
}) => {
    const classes = useStyles();
    const measureProxies = measureSetProxy.getMeasures(isReview);
    let [filters, setFilters] = React.useState();
    filters = buildMeasureFiltersObject(measureProxies, filters);
    const filteredMeasures = filterMeasureProxies(filters, measureProxies);
    const disabled = !measureSetProxy.isValid() || isReview;
    return (
        <div className={classes.root}>
            {disabled && !isReview && <div className={classes.disabledOverlay}>
                <div className={classes.disabledMessage}>
                    <AlertBar
                        severity={"warning"}
                        label={"Please fix the required fields above before continuing."}
                    />
                </div>
            </div>}
            <MeasureFilterBar
                isReview={isReview}
                disabled={disabled}
                measureSetProxy={measureSetProxy}
                stickyRef={stickyRef}
                length={measureProxies.length}
                filteredLength={filteredMeasures.length}
                filters={filters}
                onChange={(filterKey, valueKey, value) => handleFilterChange(filters, filterKey, valueKey, value, setFilters)}
            />
            <div className={classes.measuresContainer}>
                {filteredMeasures.map((m) => (
                    <MeasureResolver
                        isReview={isReview}
                        key={m.id}
                        measureSetProxy={measureSetProxy}
                        measureProxy={m}
                        onChange={onMeasureChange}
                        disabled={disabled}
                    />
                ))}
                {filteredMeasures.length === 0 && (
                    <Box p={5} textAlign={"center"}>
                        No measures to show.
                        {filteredMeasures.length !== measureProxies.length && (
                            <div>
                                Please check the applied filters or clear them. <br/>
                                <Button
                                    color={"secondary"}
                                    variant={"contained"}
                                    disableElevation
                                    onClick={() => setFilters(buildMeasureFiltersObject(measureProxies))}
                                >
                                    Clear All Filters
                                </Button>
                            </div>
                        )}
                    </Box>
                )}
            </div>
        </div>
    );
};

export default MeasuresContainer;
