import {ReduceStore} from 'flux/utils';
import MeasuresMetaActions from './MeasuresMetaActions';
import FiltersActions from './FiltersActions';
import FiltersStore from './FiltersStore';
import Dispatcher from '../Dispatcher';
import api from '../../lib/api';
import debug from '../../lib/debug';
import AsyncStatusHelpers, {AsyncStatusTypes} from './utils/AsyncStatusHelpers';
import LocationStore from "../LocationStore";
import LocationActions from "../LocationActions";

class MeasuresMetaStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            data: {},
            status: "init" // "loading", "loaded", "error", "init"
        };
    }

    reduce(state, action) {
        let match;
        switch (action.type) {
            case FiltersActions.change:
            case FiltersActions.load:
            case LocationActions.LOCATION_CHANGED: {
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                if(!["DASHBOARD"].includes(LocationStore.getRouteKey())) {
                    return state; // we only need this data for the above views.
                }
                this.__dispatcher.waitFor([
                    FiltersStore.getDispatchToken()
                ]);

                match = LocationStore.getMatch();
                if (match && match.params) {
                    const year = match.params.year;
                    const orgKey = match.params.orgKey;
                    const implKey = match.params.implKey;
                    if (
                        FiltersStore.isLoaded() &&
                        (action.type === FiltersActions.load || action.filterKey === "year")
                    ) {
                        state = {...state, status: AsyncStatusTypes.loading};
                        const filters = FiltersStore.getAllFiltersValue(true);
                        api.loadDashboardMeasuresMeta(year, orgKey, implKey, filters).then((res) => {
                            Dispatcher.dispatch({
                                type: MeasuresMetaActions.load,
                                data: res.data
                            });
                            return Promise.resolve(res);
                        })
                        .catch((error) => {
                            Dispatcher.dispatch({
                                type: MeasuresMetaActions.error
                            });
                            return Promise.reject(error);
                        });
                    }
                }
                return state;
            }
            case MeasuresMetaActions.load: {
                state = {
                    data: action.data,
                    status: AsyncStatusTypes.loaded
                };
                return state;
            }
            case FiltersActions.error:
            case MeasuresMetaActions.error: {
                state = {...state, status: AsyncStatusTypes.error};
                return state;
            }
            default:
                return state;
        }
    }

    isLoaded() {
        return AsyncStatusHelpers.isLoaded(this.getState());
    }

    isError() {
        return AsyncStatusHelpers.isError(this.getState());
    }

    getAllMeasuresMeta() {
        const measuresMap = this.getState().data;
        return Object.keys(measuresMap).map(key => measuresMap[key]);
    }

    getMeasureMetaById(id) {
        const measuresMap = this.getState().data;
        if(!measuresMap.hasOwnProperty(id)) {
            debug.log(`MeasuresMetaStore.getMeasureMetaById(): unknown measure id "${id}"`, Object.keys(measuresMap));
            return false;
        }
        return measuresMap[id];
    }
}

export default new MeasuresMetaStore();
