import React from "react";
import ErrorBoundary from "./views/components/ErrorBoundary";
import OuterTemplate from "./OuterTemplate";
import {Router} from "react-router-dom";
import {
    IntlProvider,
    DashboardProvider,
    DatePickersProvider,
    DialogsProvider,
    NotificationsProvider,
    ChartThemeProvider
} from "@armus/armus-dashboard";
import messages from "../messages";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import history from "./lib/history";

/*
    Set up all context providers
*/
const App = () => (
    <IntlProvider locale="en" messages={messages}>
        <DashboardProvider>
            <DatePickersProvider>
                <DialogsProvider>
                    <NotificationsProvider>
                        <ChartThemeProvider>
                            <CssBaseline />
                            <Router history={history}>
                                <ErrorBoundary>
                                    <OuterTemplate />
                                </ErrorBoundary>
                            </Router>
                        </ChartThemeProvider>
                    </NotificationsProvider>
                </DialogsProvider>
            </DatePickersProvider>
        </DashboardProvider>
    </IntlProvider>
);

export default App;
