import React from "react";
import {Box} from "@mui/material";

const RenderObject = ({data, depth = 0, sx}) => {
    if(Array.isArray(data)) {
        return data.map((it, index) => <RenderObject key={index} data={it} depth={depth + 1} sx={{borderBottom: "1px solid rgba(127,127,127,.5)"}}/>);
    }
    else if(data && typeof data === "object") {
        console.log(data, depth);
        return (
            <Box pl={depth * 2} sx={sx}>
                {Object.keys(data).map(key => {
                    let value = data[key];
                    return <Box><strong>{key}:</strong> <RenderObject data={value} depth={depth + 1} /></Box>;
                })}
            </Box>
        );
    }
    return <Box display={"inline-block"} sx={sx}>{data}</Box>;
};

export default RenderObject;
