import React from "react";
import { DatePicker as DatePickerControl } from '@armus/armus-dashboard';

const DatePicker = (props) => {
    const {id, label, onChange, onBlur, onKeyDown, onFocus, value, minDate, maxDate} = props;

    return (
        <DatePickerControl
            descriptor={{
                id: id,
                description: "",
                label: label,
                config: {
                    showToolbar: true,
                    hideTabs: true,
                    disableTime: true,
                    dense: true,
                    openTo: "date",
                    ampm: true
                }
            }}
            minDate={minDate}
            maxDate={maxDate}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
        />
    );
};

export default DatePicker;
