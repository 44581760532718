import React from 'react';
import { makeStyles } from '@mui/styles/';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import config from "../../../../../config";
import { isValidCehrtId } from "../../../../data/submit/utils/MeasureSetProxy";
import { FormattedMessage } from "@armus/armus-dashboard";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        paddingTop: 0,
        textAlign: "left",
        "&.MuiTextField-warning": {
            "& .MuiTextField-root": {
                "& label": {
                    color: theme.palette.warning.dark + " !important"
                },
                "& fieldset": {
                    borderColor: theme.palette.warning.main
                }
            }
        },
        "&.MuiTextField-error": {
            "& .MuiTextField-root": {
                "& label": {
                    color: theme.palette.error.main + " !important"
                },
                "& fieldset": {
                    borderColor: theme.palette.error.main
                }
            }
        },
        "& .MuiTextField-root": {
            width: 226,
            margin: theme.spacing(1)/2,
            marginLeft: 0
        }
    },
    required: {
        color: theme.palette.error.main
    }
}));

const CehrtInput = ({measureSetProxy, onChange}) => {
    const value = measureSetProxy.getValue();
    const [state, setState] = React.useState({cehrtId: value.cehrtId});
    const classes = useStyles();
    const handleChange = (e) => {
        const v = e.target.value === "" ? null : e.target.value;
        setState({
            ...state,
            cehrtId: v
        });
    };
    const errorClass = isValidCehrtId(state.cehrtId, true) ? "" : " MuiTextField-error";
    const requiredLabel = (label) => <React.Fragment>{label} <span className={classes.required}>*</span></React.Fragment>;
    return (
        <Grid container className={classes.root + errorClass}>
            <Grid item xs={6}>
                <FormattedMessage
                    id="draft.cehrtInput.message"
                    defaultMessage="The CMS EHR Certification Identification Number is generated by the CHPL.<br/>This is only applicable to Promoting Interoperability measurement sets."
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    margin="dense"
                    variant="outlined"
                    placeholder={"CEHRT ID"}
                    label={requiredLabel("CEHRT ID")}
                    value={state.cehrtId || ""}
                    onChange={handleChange}
                    onBlur={(e) => {
                        const v = e.target.value === "" ? null : e.target.value;
                        if(v !== value.cehrtId) {
                            onChange(measureSetProxy.id, {cehrtId: isValidCehrtId(v) ? v : null});
                        }
                    }}
                />
                <br/>
                <Link href={config.endpoints.cehrtId} target={"_blank"}>
                    <FormattedMessage
                        id="draft.cehrtInput.lookupLink"
                        defaultMessage="Click here to lookup your CEHRT ID."
                    />
                </Link>
            </Grid>
            <Grid item xs={3} />
        </Grid>
    );
};

export default CehrtInput;
