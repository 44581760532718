import React from 'react';
import { withTheme } from "../../providers/DashboardProvider";
import { dataToTable, dataWithInfo } from "../../../lib/utils";
import { formatValue } from "../../../lib/number";
import { titleCase }  from "../../../lib/string";
import {
    buildPropTypesFromObject,
    buildPropTypesWithDescriptor
} from "../../../lib/propTypeHelpers";
import * as d3 from "d3";
import {
    alpha,
    Table as BaseTable,
    TableBody,
    TableCell,
    TableRow,
    Box,
} from "@mui/material";
import makeSxStyles from '../../../lib/makeSxStyles';

const useStyles = makeSxStyles((theme) => ({
    tableComponent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        fontSize: theme.chartFontSize,
        "& table": {
            borderRadius: 0,
            overflow: "hidden"
        },
        "& .cell-body": {
            borderCollapse: "collapse",
            padding: theme.spacing(.5),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
        },
        "& .cell-header": {
            fontWeight: "bold",
            padding: theme.spacing(.5),
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            whiteSpace: "nowrap",
            textAlign: "left",
            width: "10%",
        },
        "& .cell-body ": {
            textAlign: "right",
        },
        "& .cell-body.cell-child, .cell-body.cell-leaf, .cell-header.cell-child": {
            background: "transparent",
        },
        "& .cell-header:nth-child(n+2)": {
            textAlign: "right",
        },
        "& .cell-legend": {
            width: theme.spacing(2),
            height: theme.spacing(2),
            display: "inline-block",
            marginBottom: "-3px",
        },
        "& .cell-numeric": {
            textAlign: "right",
        },
        "& .cell-child": {
            fontWeight: "normal",
            fontSize: "90%",
        },
        "& .cell-child .cell-legend": {
            marginTop: "2px",
            width: 10,
            height: 10,
            marginBottom: "-1px",
        },
    }
}));

const Table = withTheme((nextProps) => {
    const classes = useStyles();
    const props = buildDefaultProps(nextProps);

    const build = new TableDataBuilder();
    let [rows, topLevelGroups, topLevelValues, layout] = build.build(nextProps);

    let min = props.descriptor.config.min == null ? Math.min(...topLevelValues) : props.descriptor.config.min;
    let max = props.descriptor.config.max == null ? Math.max(...topLevelValues) : props.descriptor.config.max;
    if(props.descriptor.config.formatValue === 'percent') {
        if ((props.descriptor.config.min == null)) {
            min = 0;
        }
        if ((props.descriptor.config.max == null)) {
            max = 1;
        }
    }

    let colorConfig = {
        ...props.descriptor.config,
        colorScheme: props.descriptor.config.colorScheme,
        max: max,
        min: min,
        steps: topLevelGroups.length
    };

    const colorScheme = props.getColorScheme(colorConfig);

    let jsxRows = rows.map((row, i) => {
        return (<TableRow key={i}>{
            row.map((cell, j) => {
                const info = cell[1];
                const CellType = info.header ? "th" : "td";
                const isNumeric = ["percent", "number", "currency"].includes(props.descriptor.config.formatValue) ? true : cell[0] === null || !isNaN(cell[0]);
                const value = info.header ? titleCase(cell[0]) : formatValue(props.descriptor.config.formatValue, cell[0]);
                const tdStyle = {
                    fontColor: props.theme.fontColor,
                    background: info.color ? info.color : info.leaf ? "transparent" : "rgba(127,127,127,.1)",
                    paddingLeft: layout === "vertical" ? 20 * info.indent + 5 : undefined
                };
                const legendInfo = info.parent ? info.parent[0][1] : info;
                const colorMarker = info.legend ?
                    <Box className={"cell-legend"} style={{
                        backgroundColor: alpha(colorScheme(legendInfo), 1 - ((info.indent) / 4))
                    }}/> : null;
                return (
                    <TableCell
                        onClick={(e) => props.onClick && props.onClick(e, props.descriptor, {value, x: j, y: i, header: info.header, parent: legendInfo, color: colorScheme(legendInfo) })}
                        component={CellType}
                        key={j}
                        className={`${info.leaf > 0 ? 'cell-leaf' : ''} ${info.indent > 0 ? 'cell-child' : ''} ${isNumeric ? 'cell-numeric' : ''} ${info.header ? 'cell-header' : 'cell-body'}`}
                        style={tdStyle}
                    >
                        {colorMarker}{" "}{value === null ? "-" : value}
                    </TableCell>
                );
            })
        }</TableRow>);
    });

    return (
        <Box sx={classes.tableComponent} className={`table-component`}>
            <BaseTable size="small">
                <TableBody>
                    {jsxRows}
                </TableBody>
            </BaseTable>
        </Box>
    );
});

export const defaultConfig = (config = {}) => ({
    "colorScheme": "series",
    "colorSchemeReversed": false,
    "layoutMode": "vertical",
    "yLabel": "Value",
    "xLabel": "",
    "legend": true,
    "subLegend": false,
    "headers": true,
    "subTotals": false,
    "flatten": false,
    "formatValue": null,
    ...config
});

Table.defaultProps = {
    data: null,
    isLoading: false,

    // descriptor
    descriptor: {
        id: "",
        label: "",
        description: "",
        type: "",
        dataKey: "",
        items: [],
        enabled: true,
        visible: true,
        config: {
            ...defaultConfig()
        }
    },

    // callbacks
    onChange: undefined, // (event, value, descriptor) => {}
    onFocus: undefined, // (event, value, descriptor) => {}
    onBlur: undefined, // (event, value, descriptor) => {}
    onClick: undefined, // (event, value, descriptor) => {}
    onKeyDown: undefined, // (event, value, descriptor) => {}
};

Table.propTypes = buildPropTypesWithDescriptor(null, buildPropTypesFromObject({
    ...defaultConfig(),
    formatValue: "",
}));

export default Table;

const buildDefaultProps = (props) => {
    const newProps = {...props};
    const descriptor = props.descriptor || {};
    newProps.data = !newProps.data.info ? dataWithInfo(newProps.data) : newProps.data;

    newProps.descriptor = {
        ...descriptor,
        type: descriptor.type || "table",
        config: defaultConfig(descriptor.config)
    };
    const cfg = newProps.descriptor.config;
    switch (newProps.descriptor.type) {
        // for box plots, the points are removed to avoid a giant list of point values
        case "box":
            newProps.data = props.data.map((group) => ({
                ...group,
                value: group.value.map((bar) => {
                    let value = bar.value;
                    const pointsIndex = value.findIndex(metric => metric.label === "points");
                    if (pointsIndex !== -1) {
                        const points = value[pointsIndex].value.map(v => v.value);
                        points.sort((a, b) => a - b);
                        // has points lets calculate values.
                        value = [
                            {
                                label: "q1",
                                value: d3.quantile(points, .25)
                            },
                            {
                                label: "median",
                                value: d3.quantile(points, .50)
                            },
                            {
                                label: "q3",
                                value: d3.quantile(points, .75)
                            },
                            {
                                label: "n",
                                value: points.length
                            }
                        ];
                    }
                    return {
                        ...bar,
                        value: value
                    };
                })
            }));
            cfg.legend = props.descriptor.config.legend || false;
            cfg.subLegend = props.descriptor.config.subLegend || true;
            cfg.subTotals = props.descriptor.config.subTotals || false;
            break;
        default:
            if (newProps.descriptor.type !== "table") {
                // rendering another chart type as a table.
                // this is a fallback
                cfg.legend = true;
                cfg.subTotals = true;
            }
            if (newProps.data.info.maxDepth < 2) {
                cfg.flatten = true;
            }
            break;
    }

    if (cfg.formatValue === "percent") {
        cfg.subTotals = props.descriptor.config.subTotals || false;
    }

    if (cfg.flatten) {
        newProps.data = dataToTable(newProps.data, cfg.yLabel);
    }

    if (newProps.descriptor.type === "bar" && newProps.data.info.maxDepth >= 2) {
        cfg.legend = false;
        cfg.subLegend = true;
    }


    if (newProps.descriptor.type === "line" && newProps.data.info.maxDepth < 2) {
        cfg.legend = false;
        cfg.subLegend = false;
    }

    return newProps;
};

export class TableDataBuilder {
    // some class to help build table columns/rows...

    constructor() {
        this.rows = [];
        this.columnNames = [];
        this.topLevelGroups = [];
        this.topLevelValues = [];
    }

    addWithSubgroups(row, indent, config, parent = null) {
        if (row.value[0] && Array.isArray(row.value[0].value)) {
            if (indent === 0) {
                this.topLevelGroups.push(row.label);
                this.topLevelValues.push(row.info.total);
            }
            const index = row.info.index != null ? row.info.index : row.hasOwnProperty('index') ? row.index : row.info.index;
            const isLeaf = indent+1 === row.info.maxDepth-2;
            let newRow = new Array(this.columnNames.length);
            newRow[0] = [row.label, TableDataBuilder.cellInfo({
                indent: indent,
                index: index,
                total: row.info.total,
                legend: (config.subLegend && indent > 0) || (config.legend && indent === 0),
                header: true,
                parent,
            })];

            this.columnNames.forEach((name, i) => {
                if (row.info.leafLabels.includes(name)) {
                    const leafIndex = row.info.leafLabels.indexOf(name);
                    let val = !isLeaf && !config.subTotals ? "" : row.info.leafTotals[leafIndex];
                    const cell = row.value[leafIndex]?.value[0] || null;
                    if (cell) {
                        val = cell.value;
                        if (cell.info.isNumeric || Array.isArray(val)) {
                            val = !isLeaf && !config.subTotals ? "" : row.info.leafTotals[leafIndex];
                        }
                    }
                    newRow[i + 1] = [val, TableDataBuilder.cellInfo({indent: indent, index: index, total: row.info.total, leaf: isLeaf, color: row.color})];
                } else {
                    newRow[i + 1] = [null, TableDataBuilder.cellInfo({indent: indent, index: index, leaf: isLeaf, color: row.color})];
                }
            });
            this.rows.push(newRow);
            row.value.forEach(sub => {
                this.addWithSubgroups(sub, indent + 1, config, parent || newRow);
            });
        }
    }

    static cellInfo(info = {}) {
        return {
            indent: 0,
            index: 0,
            legend: false,
            total: 0,
            header: false,
            leaf: false,
            ...info
        };
    }

    rotate(matrix) {
        let newMatrix = Array.from(Array(matrix[0].length), () => new Array(matrix.length));
        for (let i = 0; i < matrix.length; i++) {
            for (let j = 0; j < matrix[0].length; j++) {
                newMatrix[j][i] = matrix[i][j];
            }
        }
        return newMatrix;
    }

    build(inputProps, csv = false) {
        const props = buildDefaultProps(inputProps);

        this.rows = [];
        this.topLevelGroups = [];
        this.topLevelValues = [];
        const data = dataWithInfo(dataToTable(props.data, "", true));
        this.columnNames = [...new Set(data.map(it => it.info.leafLabels).flat())];
        this.columnNames = this.columnNames.filter(it => {return it !== null;});

        let layout = props.descriptor.config.layoutMode || "vertical";
        // add header row
        this.rows.push([props.descriptor.config.xLabel, ...this.columnNames].map(v => {

            return [
                v,
                TableDataBuilder.cellInfo({ header: true })
            ];
        }));
        data.forEach(row => this.addWithSubgroups(row, 0, props.descriptor.config));

        if (layout === "horizontal") {
            this.rows = this.rotate(this.rows);
        }
        if(!props.descriptor.config.headers) {
            // top remove header
            this.rows.shift();
        }

        if (csv) {
            return this.rows;
        } else {
            return ([this.rows, this.topLevelGroups, this.topLevelValues, layout]);
        }
    }
}