import React from "react";
import { KeyValueTable, FormattedMessage } from "@armus/armus-dashboard";

const TabDescriptions = (props) => {
    const {activeCell} = props;
    const {measureProxy} = activeCell || {};
    if(!measureProxy) {
        return <FormattedMessage id="insufficientData" />;
    }

    const data = {};
    [
        "title",
        "measureId",
        "description",
        "isHighPriority",
        "isInverse",
        "measureType",
        "primarySteward",
        "metricType",
        "category",
        "isRegistryMeasure",
        "isRiskAdjusted",
        "modality",
        "specificationLink"
    ].forEach(key => {
        data[key] = measureProxy.meta[key];
    });

    if(measureProxy.isMultiComponent()) {
        data["Components"] = measureProxy.getComponents().map((mp) => {
            return (
                <div>
                    <strong>{mp.title()}</strong> - {mp.meta.description}
                </div>
            );
        });
    }

    return (
        <React.Fragment>
            <KeyValueTable
                data={data}
                showHeader={false}
            />

        </React.Fragment>
    );
};

export default TabDescriptions;
