import {ReduceStore} from 'flux/utils';
import Dispatcher from './Dispatcher';
import PerformanceYearActions from "./PerformanceYearActions";
import api from "../lib/api";
import LocationStore from "./LocationStore";
import LocationActions from "./LocationActions";
import UserActions from "./UserActions";

const requestPerformanceYearData = () =>
    api.loadPerformanceYearsMetaData()
        .then((res) => {
            Dispatcher.dispatch({
                type: PerformanceYearActions.LOADED,
                data: res.data
            });
        }).catch((err) => {
            Dispatcher.dispatch({
                type: PerformanceYearActions.ERROR,
                data: err
            });
        });

class PerformanceYearStore extends ReduceStore {
    constructor() {
        super(Dispatcher);
    }

    getInitialState() {
        return {
            isLoading: true,
            data: [],
            selectedYear: null
        };
    }

    reduce(state, action) {
        switch(action.type) {
            case UserActions.LOADED:
            case PerformanceYearActions.LOAD: {
                // kickoff getting performance year
                requestPerformanceYearData();
                return {
                    ...state,
                    isLoading: true
                };
            }
            case PerformanceYearActions.LOADED: {
                return {
                    state,
                    data: action.data || [],
                    isLoading: false
                };
            }
            case PerformanceYearActions.ERROR: {
                console.error("Loading Performance Year Meta Data Failed!", action.data);
                return {
                    isLoading: false
                };
            }
            case LocationActions.LOCATION_CHANGED:
                this.__dispatcher.waitFor([LocationStore.getDispatchToken()]);
                const year = parseInt(LocationStore.getMatch()?.params?.year || "", 10);
                if(!!year && year !== state.selectedYear?.year) {
                    const foundYear = state.data.find(y => y.year === year);
                    if(foundYear) {
                        return {
                            ...state,
                            selectedYear: foundYear
                        };
                    }
                }
                return state;
            default:
                return state;
        }
    }

    isLoading() {
        return this.getState().isLoading;
    }

    getSelectedPerformanceYear() {
        return this.getState().selectedYear || this.getDefaultPerformanceYear();
    }

    getDefaultPerformanceYear() {
        return this.getState().data[0] || null;
    }

    getPerformanceYears() {
        return this.getState().data;
    }
}

export default new PerformanceYearStore();
