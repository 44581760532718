import React from "react";
import {EnhancedTable, KeyValueTable, formatValue, FormattedMessage} from "@armus/armus-dashboard";
import {Equation} from 'react-equation';

const CalculationsTab = (props) => {
    const {activeCell} = props;
    const {measureProxy, columnId, x} = activeCell || {};
    const cell = measureProxy.getColumn(columnId);
    const color = measureProxy.getColumnColor(columnId, measureProxy.isSmartPerformance());
    const cellContent = (props) => props.row.getColumn(columnId)[props.cell.id];
    if(measureProxy && x > 0) {
        if(!cell) {
            return <FormattedMessage id="insufficientData" />;
        }
        const headerCells = [ // Eligible Population, Exclusion, Exception, Performance Not Met, Performance Met
            {
                id: "title",
                props: {align: "left"},
                disablePadding: false,
                isSortable: false,
                label: "Measure",
                Content: (props) => {
                    const mp = props.row;
                    if(mp.id === measureProxy.id && measureProxy.isMultiComponent()) {
                        return (
                            <React.Fragment>
                                <strong>Total</strong> ({mp.title()})
                            </React.Fragment>
                        );
                    }
                    return mp.title();
                }
            },
            {
                id: "eligible",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Eligible Population",
                Content: cellContent
            },
            {
                id: "exclusion",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Exclusion",
                Content: cellContent
            },
            {
                id: "exception",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Exception",
                Content: cellContent
            },
            {
                id: "notMet",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Performance Not Met",
                Content: cellContent
            },
            {
                id: "met",
                props: {align: "center"},
                disablePadding: false,
                isSortable: false,
                label: "Performance Met",
                Content: cellContent
            }
        ];
        const rows = measureProxy.isMultiComponent() ? [...measureProxy.getComponents(), measureProxy] : [measureProxy];
        let prefix = "";
        const keys = ["Data Completeness Rate", "Performance Rate"];
        if(measureProxy.isSmartPerformance()) {
            keys.push("Smart Performance Rate");
            prefix = "Smart ";
        }
        keys.push("Color Rating");

        const data = {
            "Data Completeness Rate": (
                <Equation
                    value={`(Performance Met + Exception + Performance Not Met ) / (Eligible Population) * 100 = Data Completeness Rate =
                                (${cell.met} + ${cell.exception} + ${cell.notMet} ) / ${cell.eligible} * 100 =
                                ${formatValue("percent", cell.reportingRate / 100, "Insufficient Data")}`}
                    style={{fontSize: "115%"}}
                />
            ),
            "Performance Rate": (
                <Equation
                    value={`Performance Met / ( Performance Met + Performance Not Met ) * 100 = Performance Rate =
                                ${cell.met} / (${cell.met} + ${cell.notMet}) * 100 =
                                ${formatValue("percent", cell.performanceRate / 100, "Insufficient Data")}`}
                    style={{fontSize: "115%"}}
                />
            ),
            "Smart Performance Rate": (
                <Equation
                    value={`100% - Performance Rate = Smart Performance Rate =
                                100% - ${formatValue("percent", cell.performanceRate / 100, "Insufficient Data")} =
                                ${formatValue("percent", cell.smartPerformanceRate / 100, "Insufficient Data")}`}
                    style={{fontSize: "115%"}}
                />
            )
        };

        if(measureProxy.getBenchmark() !== null) {
            data["Color Rating"] = (
                <React.Fragment>
                    <Equation
                        value={`(${prefix} Performance Rate / ( CMS Benchmark ${prefix} Performance Rate ) - 1 ) * 100 = Percentage Within CMS Benchmark =
                                    (${formatValue("percent", measureProxy.getPerformanceRate(columnId, measureProxy.isSmartPerformance()) / 100,"Insufficient Data")} / (${formatValue("percent", measureProxy.getBenchmark() / 100, "Insufficient Data")}) - 1 ) * 100 =
                                    (${formatValue("percent", color.value, "Insufficient Data")})`}
                        style={{fontSize: "115%"}}
                    />
                    <div style={{
                        display: "inline-block",
                        backgroundColor: color.backgroundColor,
                        color: color.color,
                        fontWeight: "bold",
                        padding: "5px 10px",
                        top: ".53em",
                        marginLeft: 10,
                        position: "relative"
                    }}>
                        = {color.subTitle} {color.title}
                    </div>
                </React.Fragment>
            );
        }
        else {
            data["Color Rating"] = ( "No CMS benchmark data available for color rating." );
        }

        return (
            <React.Fragment>
                <EnhancedTable
                    rows={rows}
                    columns={headerCells}
                    dense={true}
                    hover={false}
                    pagination={false}
                />
                <KeyValueTable
                    keys={keys}
                    data={data}
                    showHeader={false}
                />
            </React.Fragment>
        );
    }
    return (
        <FormattedMessage id="insufficientData" />
    );
};

export default CalculationsTab;
