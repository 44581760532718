import React from "react";
import TextField from "@mui/material/TextField/TextField";
import Container from "../../../../data/Container";
import MeasuresDraftStore from "../../../../data/submit/MeasuresDraftStore";

export const isProportionValid = (n, d) => {
    if(n == null && d == null) return true;
    if(n == null || d == null) return false;
    if(isNaN(n) || isNaN(d)) return false;
    if(d === 0) return false;
    return n <= d;
};

const buildDefaultDraftData = (measureProxy) => {
    return {
        measure: measureProxy.meta.measureId,
        metricType: measureProxy.meta.metricType,
        numerator: null,
        denominator: null
    };
};

const ProportionControl = ({ disabled, measureProxy, onChange, draftData, onValidate }) => {
    if(draftData === null) {
        draftData = buildDefaultDraftData(measureProxy);
    }
    const numeratorRef = React.useRef();
    const denominatorRef = React.useRef();
    const [controlState, setControlState] = React.useState(draftData);

    React.useEffect(() => {
        setControlState({
            numerator: draftData.numerator,
            denominator: draftData.denominator
        });
    },[draftData.numerator, draftData.denominator]);

    React.useEffect(() => {
        onValidate(isProportionValid(controlState.numerator, controlState.denominator));
    });

    const handleChange = (key, value) => {
        value = parseInt(value, 10);
        value = isNaN(value) ? null : value;
        const nextState = {...controlState, [key]: value};
        setControlState(nextState);
    };
    const handleBlur = (e) => {
        if([numeratorRef.current, denominatorRef.current].indexOf(e.relatedTarget) !== -1) return;
        let v;
        if(isProportionValid(controlState.numerator, controlState.denominator)) {
            v = (controlState.numerator != null && controlState.denominator != null) ? controlState : null;
        }
        else {
            v = null;
        }
        const isChangingFromEmpty = (v === null && (draftData.numerator !== null && draftData.denominator !== null));
        const valueHasChanged = (v !== null && (v.numerator !== draftData.numerator || v.denominator !== draftData.denominator));
        if(
            isChangingFromEmpty || valueHasChanged
        ) {
            // only commit value when leaving measure and has changed
            onChange(e, v);
        }
    };

    return (
        <div>
            <TextField
                inputRef={numeratorRef}
                error={(controlState.numerator == null && controlState.denominator != null)}
                margin="dense"
                placeholder={"Numerator >= 0"}
                label={"Numerator"}
                disabled={disabled}
                onChange={(e) => handleChange("numerator", e.target.value)}
                onBlur={handleBlur}
                variant="outlined"
                value={controlState.numerator != null ? controlState.numerator : ""}
            />
            <TextField
                inputRef={denominatorRef}
                error={!isProportionValid(controlState.numerator, controlState.denominator)}
                margin="dense"
                variant="outlined"
                placeholder={"Denominator > 0"}
                label={"Denominator"}
                disabled={disabled}
                onChange={(e) => handleChange("denominator", e.target.value)}
                onBlur={handleBlur}
                value={controlState.denominator != null ? controlState.denominator : ""}
            />
        </div>
    );
};

export default Container(
    ProportionControl,
    () => [
        MeasuresDraftStore
    ],
    (state, props) => {
        return {
            ...props,
            draftData: props.measureProxy.getValue()
        };
    }
);
