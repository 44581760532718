import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import SwitchFlux from "./views/components/SwitchFlux";
import MenuIcon from "@mui/icons-material/Menu";
import Dialog from "./views/components/Dialog";
import {
    withTheme,
    formatMessage,
    useActivityMonitor,
    useDialogs,
    Header,
    Footer,
    LaunchLayout
} from "@armus/armus-dashboard";
import config from "../config";
import Notifications from "./views/components/Notifications";
import Container from "./data/Container";
import UserStore from "./data/UserStore";
import api from "./lib/api";
import { asyncStatus } from "./data/submit/utils/utils/asyncReducer";
import {getApplicationRoutes, buildUrl, getRouteKeyFromImplKey} from "../routes";
import LocationStore from "./data/LocationStore";
import { dispatchLogout } from "./data/UserActions";
import PerformanceYearStore from "./data/PerformanceYearStore";
import {Box} from "@mui/material";


const OuterTemplate = (props) => {
    const {
        userStatus,
        user,
        currentOrg,
        currentImpl,
        defaultOrgKey,
        getDefaultImplKey,
        onLogout,
        selectedYear,
        performanceYears,

        themes,
        setTheme
    } = props;

    const {onShow} = useDialogs();

    const userName = user.firstname || user.username || null;
    const userLinks = user.navTree ? (user.navTree.items || []) : [];

    useActivityMonitor(
        () => { // ping check that the user is still active
            api.loadUserData();
        },
        () => { // user is not active log them out
            onLogout();
        },
        config.session
    );

    const leftNavLinks = [];
    const rightNavLinks = [];

    if(process.env.NODE_ENV === "development") {
        if (config.mocks.showMockMenu) {
            // add development links
            rightNavLinks.push(require("./lib/mocks").mockNavLinks());
        }
    }

    const profileNavLinks = [
        {
            label: formatMessage({id: "about", defaultMessage: "About"}),
            onClick: () => {
                let mockMessage = "";
                if(process.env.NODE_ENV === "development") {
                    const mockSet = require("./lib/mocks").getCurrentMock();
                    mockMessage = <React.Fragment><strong>Mock Set:</strong> {mockSet.label}</React.Fragment>;
                }
                onShow({
                    title: `${formatMessage({
                        id: "about",
                        defaultMessage: "About"
                    })} ${formatMessage({id: "title"})}`,
                    content: (
                        <React.Fragment>
                            <code>
                                Name: {process.env.REACT_APP_NAME} <br/>
                                Version: {process.env.REACT_APP_VERSION}
                                <br/>
                                {mockMessage}
                            </code>
                        </React.Fragment>
                    )
                });
            }
        },
        null,
        ...themes.map((theme, themeIndex) => ({
            label: formatMessage({id: "themeLabel"+themeIndex, defaultMessage: theme.label}),
            onClick: () => {
                setTheme(themeIndex);
            }
        })),
        null,
        {
            label: formatMessage({id: "armusSupport", defaultMessage: "ARMUS Support"}),
            url: config.endpoints.support
        },
        null, // divider
        {
            label: formatMessage({id: "logout", defaultMessage: "Logout"}),
            onClick: onLogout
        }
    ];

    let defaultUrl = null;

    switch(userStatus) {
        case asyncStatus.LOADED:
            const defaultImplKey = getDefaultImplKey(defaultOrgKey);
            const view = getRouteKeyFromImplKey(defaultImplKey);
            defaultUrl = buildUrl(view, {
                view: view.toLowerCase(),
                year: selectedYear.year.toString(),
                orgKey: defaultOrgKey,
                implKey: defaultImplKey
            });
            // successfully logged in
            if(userLinks.length) {
                // append the users other application links
                rightNavLinks.push({
                    icon: <MenuIcon />,
                    items: userLinks
                });
            }

            let organizationLabel = "Organizations";
            if(currentOrg) {
                organizationLabel = currentOrg.name || currentOrg.key;
            }
            const orgLinks = {
                label: organizationLabel, //+ ( : ""),
                url: "",
                items: user.organizations ? user.organizations.map((org) => {
                    const implKey = getDefaultImplKey(org.key);
                    const view = getRouteKeyFromImplKey(implKey);
                    return ({
                        label: org.name || org.key,
                        url: buildUrl(view, {
                            view: view.toLowerCase(),
                            year: selectedYear.year.toString(),
                            orgKey: org.key,
                            implKey: implKey
                        })
                    });
                }) : []
            };

            // sort organizations list
            orgLinks.items.sort((a, b) =>
                a.label.toString().localeCompare(b.label.toString(), navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                })
            );

            if(orgLinks && orgLinks.items.length) {
                // prepend the users available stream links
                leftNavLinks.push(orgLinks);
            }


            let implementationLabel = "Implementations";
            if(currentOrg && currentImpl) {
                implementationLabel = currentImpl.name || currentImpl.key;
            }

            const implLinks = {
                label: implementationLabel,
                url: "",
                items: currentOrg ? currentOrg.implementations.map((impl) => {
                    const view = getRouteKeyFromImplKey(impl.key);
                    return ({
                        label: impl.name || impl.key,
                        url: buildUrl(view, {
                            view: view.toLowerCase(),
                            year: selectedYear.year.toString(),
                            orgKey: currentOrg.key,
                            implKey: impl.key
                        }),
                        view: view
                    });
                }) : []
            };

            // sort organizations list
            implLinks.items.sort((a, b) =>
                a.label.toString().localeCompare(b.label.toString(), navigator.languages[0] || navigator.language, {
                    numeric: true,
                    ignorePunctuation: true
                })
            );

            if(implLinks && implLinks.items.length) {
                // prepend the users available stream links
                leftNavLinks.push(implLinks);
            }

            if(selectedYear) {
                leftNavLinks.unshift({
                    label: selectedYear.year.toString(),
                    url: "",
                    items: (currentOrg && currentImpl) ? performanceYears.map((yearInfo) => {
                        const view = getRouteKeyFromImplKey(currentImpl.key);
                        return ({
                            label: yearInfo.year.toString(),
                            url: buildUrl(view, {
                                view: view.toLowerCase(),
                                year: yearInfo.year.toString(),
                                orgKey: currentOrg.key,
                                implKey: currentImpl.key
                            })
                        });
                    }) : undefined
                });
            }

            break;
        // wait for user to successfully load before rendering the app.
        case asyncStatus.ERROR:
        case asyncStatus.LOADING:
        default:
            return <LaunchLayout />;
    }
    return (
        <React.Fragment>
            <Header
                leftNav={leftNavLinks}
                rightNav={rightNavLinks}
                profileNav={profileNavLinks}
                userName={userName}
                loginUrl={config.endpoints.login}
                logoUrl={<>MIPS&nbsp;Submit</>}
            />
            <SwitchFlux>
                {/* Add All Application Routes */}
                {getApplicationRoutes().map((it) => <Route key={it.key} {...it} />)}

                {/* root redirect to users default org */}
                {defaultOrgKey && <Redirect exact from="/(api)?" to={defaultUrl} />}

                <Route path="/" exact={true} component={LaunchLayout} />

                {/* Catchall Error Page */}
                <Route path="/*" component={() => <Box p={16} textAlign={"center"}>
                    Please use the top navigation above to navigate to your desired view.
                </Box>} />
            </SwitchFlux>

            <Footer navLinks={leftNavLinks} />
            <Dialog />
            <Notifications />
        </React.Fragment>
    );
};

OuterTemplate.propTypes = {
    user: PropTypes.object.isRequired,
    currentOrg: PropTypes.object,
    currentImpl: PropTypes.object,
    defaultOrgKey: PropTypes.string,
    getDefaultImplKey: PropTypes.func,
    userStatus: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired
};

export default withTheme(Container(
    OuterTemplate,
    () => [
        UserStore,
        PerformanceYearStore,
        LocationStore
    ],
    (state, props) => {
        const params = LocationStore.getParams();
        return {
            ...props,
            user: UserStore.getUser(),
            currentOrg: UserStore.getOrganization(params.orgKey),
            currentImpl: UserStore.getImplementation(params.orgKey, params.implKey),
            defaultOrgKey: UserStore.getDefaultOrgKey(),
            getDefaultImplKey: (orgKey) => UserStore.getDefaultImplementationKey(orgKey),
            userStatus: PerformanceYearStore.isLoading() ? asyncStatus.LOADING : UserStore.getStatus(),
            selectedYear: PerformanceYearStore.getSelectedPerformanceYear(),
            performanceYears: PerformanceYearStore.getPerformanceYears(),
            onLogout: dispatchLogout
        };
    }
));
