import React from "react";
import Chart from "../../components/Chart";
import { Grid } from "@mui/material";
import { KeyValueTable, formatValue, FormattedMessage } from "@armus/armus-dashboard";
import { getColumns } from "../../../lib/columns";

const MeasuresGraphTab = (props) => {
    const {activeCell} = props;
    const columns = getColumns("months");
    const {measureProxy} = activeCell || {};

    if(!measureProxy) {
        return <FormattedMessage id="insufficientData" />;
    }
    const tableData = [];
    const chartData = [];
    const aggCell = measureProxy.getColumn("agg");
    const colors = [];
    const isSmartPerformance = measureProxy.isSmartPerformance();
    columns.forEach((col) => {
        const cell = measureProxy.getColumn(col.key);
        const color = measureProxy.getColumnColor(col.key);
        const smartColor = measureProxy.getColumnColor(col.key, isSmartPerformance);
        colors.push(smartColor.backgroundColor);
        chartData.push({
            label: col.shortTitle,
            value: ((isSmartPerformance ? cell.smartPerformanceRate : cell.performanceRate) || 0)/100
        });
        const row = {
            Month: col.title,
            "Eligible": formatValue(null, cell.eligible),
            "Exclusion": formatValue(null, cell.exclusion),
            "Exception": formatValue(null, cell.exception),
            "Performance Met": formatValue(null, cell.met),
            "Performance Not Met": formatValue(null, cell.notMet),
            "Data Completeness Rate": formatValue("percent", cell.reportingRate/100),
            "Performance Rate": (
                <div style={{textAlign: "right"}}>
                    {formatValue("percent", cell.performanceRate /100)}
                    <div style={{
                        backgroundColor: color.backgroundColor,
                        width: 16,
                        height: 16,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: 8
                    }}
                    />
                </div>
            )
        };
        if(isSmartPerformance) {
            row.smartPerformanceRate = (
                <div style={{textAlign: "right"}}>
                    {formatValue("percent", cell.smartPerformanceRate / 100)}
                    <div style={{
                        backgroundColor: smartColor.backgroundColor,
                        width: 16,
                        height: 16,
                        display: "inline-block",
                        verticalAlign: "middle",
                        marginLeft: 8
                    }}
                    />
                </div>
            );
        }
        tableData.push(row);
    });
    return (
        <React.Fragment>
            <Grid
                container
                spacing={2}
            >
                <Grid item xs={12}>
                    <Chart
                        title={(isSmartPerformance ? "Smart " : "") + "Performance Rate"}
                        type={"bar"}
                        data={chartData}
                        format={"percent"}
                        config={{
                            colorScheme: colors,
                            markers: [
                                {
                                    label: "CMS Benchmark " + formatValue("percent", (isSmartPerformance ? aggCell.smartPerformanceRate : aggCell.performanceRate) / 100),
                                    value: ((isSmartPerformance ? aggCell.smartPerformanceRate : aggCell.performanceRate) || 0)/100,
                                    visible: true
                                }
                            ],
                            legend: false,
                            subLegend: false,
                            yLabel: "",
                            xLabel: ""
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <KeyValueTable
                        data={tableData}
                        dataArray={true}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default MeasuresGraphTab;
