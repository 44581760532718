import React from "react";
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Button, Paper} from "@armus/armus-dashboard";
import {alpha} from "@mui/material/styles";

const StatusSignaturePending = ({
    data,
    onSave,
    onClose
}) => {

    const handleEdit = () => {
        const saveData = {
            ...data,
            status: "VERIFIED"
        };
        onSave(saveData);
    };
    const authorizedTitle = data.entityType === "individual" ? "Physician" : "Authorized Group Representative";

    return (
        <Paper p={4}  sx={{maxWidth: "75%", ml: "12.5%", mb: 3}}>
            <Typography variant={"h2"}>Awaiting Signature</Typography>
            <Typography variant={"subtitle1"} sx={{opacity: .5, mb: 2}}>Awaiting {authorizedTitle.toLowerCase()} to sign the legal documents.</Typography>
            <Typography variant={"h3"} fontWeight={500} sx={{my: 2}}>{authorizedTitle} Information</Typography>
            <Box sx={{
                border: "1px solid #dddddd",
                background: alpha("#888888", .05),
                py: 2,
                px: 4,
                borderRadius: 4,
                textAlign: "left",
                mb: 2,
                overflow: "hidden"
            }}>
                <h4>Name: {data.signerName}</h4>
                Email: {data.signerEmail}<br/><br/>
                <Box sx={{
                    opacity: .5
                }}>
                    * Please notify the {authorizedTitle.toLowerCase()} that they will be receiving an email from Docusign.
                </Box>
            </Box>
            <Box textAlign={"right"} mt={4}>
                <Button
                    color={"secondary"}
                    size={"large"}
                    onClick={onClose}
                >
                    Cancel
                </Button> &nbsp;
                <Button
                    color={"primary-alt"}
                    size={"large"}
                    onClick={handleEdit}
                >
                    Edit Signer Info
                </Button>
            </Box>
        </Paper>
    );
};
export default StatusSignaturePending;
