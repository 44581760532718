export const AsyncStatusTypes = {
    loading: 'LOADING',
    loaded: 'LOADED',
    error: 'ERROR'
};

export default {
    isLoaded: (state) => {
        return state.status === AsyncStatusTypes.loaded;
    },
    hasValue: (state) => {
        return state.status !== AsyncStatusTypes.loaded && state.data !== undefined;
    },
    isLoading: (state) => {
        return state.status === AsyncStatusTypes.loading;
    },
    isError: (state) => {
        return state.status === AsyncStatusTypes.error;
    }
};


