import React from "react";
import {colorBuckets} from "../../../lib/color";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { FormattedMessage } from "@armus/armus-dashboard";

const useStyles = makeStyles((theme) => ({
    legendContainer: {
        ...theme.typography.body1,
        fontSize: "80%",
        color: theme.palette.text.primary,
        marginTop: -theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    legendTable: {
        float: "right"
    },
    legendCell: {
        padding: "1px 5px",
        fontWeight: "bold"
    },
    legendWithin: {
        borderLeft: "2px solid " + theme.palette.text.primary,
        borderRight: "2px solid " + theme.palette.text.primary
    },
    legendLabels: {
        "& td": {
            fontWeight: "bold",
            color: theme.palette.text.primary
        }
    },
    legendDescription: {
        fontSize: "100%"
    }
}));
const ColorLegend = ({filters}) => {
    const classes = useStyles();
    return (
        <div className={classes.legendContainer}>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <p className={classes.legendDescription}>
                        {filters.smartPerformance ? (
                            <FormattedMessage id="smartPerformanceDescriptionOn" />
                        ) : (
                            <FormattedMessage id="smartPerformanceDescriptionOff" />
                        )}
                    </p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <table className={classes.legendTable} cellPadding={0} cellSpacing={0}>
                        <tbody>
                            <tr>
                            {colorBuckets.map((colorBucket, index) => (
                                <td
                                    key={index}
                                    align="center"
                                    style={{
                                        color: colorBucket.color,
                                        backgroundColor: colorBucket.backgroundColor
                                    }}
                                    className={classes.legendCell}
                                    title={colorBucket.subTitle + " " + colorBucket.title + " of benchmark"}
                                >
                                    {colorBucket.title}
                                </td>
                            ))}
                            </tr>
                            <tr className={classes.legendLabels}>
                                <td colSpan={4} align="center">worse by</td>
                                <td align="center" className={classes.legendWithin}>within</td>
                                <td colSpan={4} align="center">better by</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
            </Grid>
        </div>
    );
};

export default ColorLegend;
