import React from "react";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';

export const scrollToTop = () => {
    const anchor = document.querySelector('#top');
    if (anchor) {
        anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
};

const ScrollTop = ({className}) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100
    });

    return (
        <Zoom in={trigger} style={{zIndex: 10}}>
            <div onClick={scrollToTop} role="presentation" className={className}>
                <Fab color="primary" size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon />
                </Fab>
            </div>
        </Zoom>
    );
};

export default ScrollTop;
