export const columns = {
    jan: {
        "key": "jan",
        "title": "January",
        "shortTitle": "Jan"
    },
    feb: {
        "key": "feb",
        "title": "February",
        "shortTitle": "Feb"
    },
    mar: {
        "key": "mar",
        "title": "March",
        "shortTitle": "Mar"
    },
    q1: {
        "key": "q1",
        "title": "Q1",
        "shortTitle": "Q1"
    },
    apr: {
        "key": "apr",
        "title": "April",
        "shortTitle": "Apr"
    },
    may: {
        "key": "may",
        "title": "May",
        "shortTitle": "May"
    },
    jun: {
        "key": "jun",
        "title": "June",
        "shortTitle": "Jun"
    },
    q2: {
        "key": "q2",
        "title": "Q2",
        "shortTitle": "Q2"
    },
    jul: {
        "key": "jul",
        "title": "July",
        "shortTitle": "Jul"
    },
    aug: {
        "key": "aug",
        "title": "August",
        "shortTitle": "Aug"
    },
    sep: {
        "key": "sep",
        "title": "September",
        "shortTitle": "Sep"
    },
    q3: {
        "key": "q3",
        "title": "Q3",
        "shortTitle": "Q3"
    },
    oct: {
        "key": "oct",
        "title": "October",
        "shortTitle": "Oct"
    },
    nov: {
        "key": "nov",
        "title": "November",
        "shortTitle": "Nov"
    },
    dec: {
        "key": "dec",
        "title": "December",
        "shortTitle": "Dec"
    },
    q4: {
        "key": "q4",
        "title": "Q4",
        "shortTitle": "Q4"
    },
    ytd: {
        key: "ytd",
        title: "Year To Date",
        shortTitle: "YTD"
    },
    agg: {
        key: "agg",
        title: "Registry Benchmark",
        shortTitle: "Registry Benchmark"
    },
    cmsBenchmark: {
        key: "cmsBenchmark",
        title: "CMS Benchmark",
        shortTitle: "CMS Benchmark"
    },
    sufficient: {
        key: "sufficient",
        title: "Is Sufficient",
        shortTitle: "Sufficient"
    }
};

export const getColumn = (key) => {
    return columns[key];
};

export const getColumns = (filter) => {
    return Object.keys(columns).map(key => columns[key]).filter((col) => {
        if (filter === "quarters" && ["q1", "q2", "q3", "q4"].includes(col.key)) {
            return true;
        }
        else if (filter === "months" && ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"].includes(col.key)) {
            return true;
        }
        else if (filter === "monthsQuarters" && ["jan", "feb", "mar", "q1", "apr", "may", "jun", "q2", "jul", "aug", "sep", "q3", "oct", "nov", "dec", "q4"].includes(col.key)) {
            return true;
        }
        return false;
    });
};
