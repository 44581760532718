import React from "react";
import PropTypes from "prop-types";
import { IconButton, Chip, Icon, lighten, darken, Alert, AlertTitle } from "@mui/material";
import SuccessOutlinedIcon from '@mui/material/internal/svg-icons/SuccessOutlined';
import ReportProblemOutlinedIcon from '@mui/material/internal/svg-icons/ReportProblemOutlined';
import ErrorOutlineIcon from '@mui/material/internal/svg-icons/ErrorOutline';
import InfoOutlinedIcon from '@mui/material/internal/svg-icons/InfoOutlined';
import makeSxStyles from "../../lib/makeSxStyles";

const useStyles = makeSxStyles((theme) => ({
    content: {
        marginTop: theme.spacing(1),
        //marginBottom: theme.spacing(1),
        "& .MuiAlert-action": {
            alignItems: "start",
        },
        "& .MuiAlert-message": {
            padding: "10px 0"
        },
        "&.closed:hover": {
            textDecoration: "underline",
            cursor: "pointer"
        },
        "&.MuiAlertSmall": {
            paddingTop: 0,
            paddingBottom: 0,
        },
        "&.MuiAlertSmall .MuiAlert-action": {
            alignItems: "center",
        },
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        "&:hover": {
            color: theme.palette.primary.main,
        }
    }
}));

const defaultIconMapping = {
    success: SuccessOutlinedIcon,
    warning: ReportProblemOutlinedIcon,
    error: ErrorOutlineIcon,
    info: InfoOutlinedIcon,
};

export const getAlertIcon = (type) => {
    return defaultIconMapping[type];
};

const AlertBar = ({label, open = false, children, severity = "info", variant, size, sx = {}, ...extraProps}) => {
    let [expanded, setExpanded] = React.useState(open);
    const classes = useStyles();
    let variantOverride = variant;
    if(variantOverride === "default") {
        variantOverride = undefined;
    }
    const outerSx = {
        ...classes.content,
        ...sx
    };
    if(!children) {
        return (
            <Alert
                {...extraProps}
                variant={variantOverride}
                size={size}
                className={(size === "small" ? " MuiAlertSmall" : "")}
                sx={outerSx}
                severity={severity}
            >
                {label}
            </Alert>
        );
    }
    return (
        <React.Fragment>
            {!expanded && <Alert
                {...extraProps}
                variant={variantOverride}
                size={size}
                className={"closed " + (size === "small" ? " MuiAlertSmall" : "")}
                sx={outerSx}
                severity={severity}
                onClick={() => {
                    setExpanded(!expanded);
                }}
            >
                {label}
            </Alert>}
            {expanded && (
                <Alert
                    {...extraProps}
                    variant={variantOverride}
                    size={size}
                    className={(size === "small" ? " MuiAlertSmall" : "")}
                    sx={outerSx}
                    severity={severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size={size}
                            onClick={() => {
                                setExpanded(false);
                            }}
                        >
                            <Icon fontSize="inherit">close_icon</Icon>
                        </IconButton>
                    }
                >
                    <AlertTitle>{label}</AlertTitle>
                    {children}
                </Alert>
            )}
        </React.Fragment>
    );
};

AlertBar.defaultProps = {
    severity: "info",
    size: "medium",
    variant: "default",
};

AlertBar.propTypes = {
    ...Chip.propTypes,
    size: PropTypes.oneOf(['small', 'medium']),
    severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    variant: PropTypes.oneOf(['standard','outlined', 'filled']),
};

export default AlertBar;




export const useAlertStyles = makeSxStyles((theme) => {
    const types = ["info", "success", "warning", "error"];
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;
    const styles = {};
    types.forEach((color) => {
        styles[color + "AlertText"] = {
            color: getColor(theme.palette[color].main, 0.6),
        };
        styles[color + "AlertBackground"] = {
            backgroundColor: getBackgroundColor(theme.palette[color].main, .9),
        };
        styles[color + "AlertIcon"] = {
            color: theme.palette[color].main,
        };
        styles[color + "AlertBorder"] = {
            borderColor: theme.palette[color].main,
            borderWidth: 1,
            borderStyle: "solid",
        };

        const baseAlertChip = {
            paddingLeft: .25,
            ...styles[color + "AlertBackground"],
            ...styles[color + "AlertText"],
            "& svg": {
                ...styles[color + "AlertIcon"],
            }
        };

        styles[color + "AlertChip-default"] = {
            "&.MuiChip-root": {
                ...baseAlertChip,
            }
        };

        styles[color + "AlertChip-filled"] = {
            "&.MuiChip-root": {
                ...baseAlertChip,
                borderColor: theme.palette[color].main,
                backgroundColor: theme.palette[color].main,
                color: "white",
                "& svg": {
                    color: "white"
                }
            }
        };
        styles[color + "AlertChip-outlined"] = {
            "&.MuiChip-root": {
                ...baseAlertChip,
                backgroundColor: "none",
                ...styles[color + "AlertBorder"]
            }
        };
    });
    return styles;
});

export const AlertChip = ({label, severity = "info", size = "medium", variant = "default", classNames = "", ...extraProps}) => {
    const classes = useAlertStyles();
    const Icon = getAlertIcon(severity);
    const variantClasses = classes[severity + "AlertChip-" + variant];
    return (
        <Chip
            {...extraProps}
            className={classNames}
            icon={<Icon />}
            label={label}
            size={size}
            variant={variant}
            sx={variantClasses}
        />
    );
};

AlertChip.defaultProps = {
    severity: "info",
    size: "medium",
    variant: "default",
    label: "",
};

AlertChip.propTypes = {
    ...Chip.propTypes,
    size: PropTypes.oneOf(['small', 'medium']),
    severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
    variant: PropTypes.oneOf(['default','outlined', 'filled']),
};