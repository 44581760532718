const defaultMessages = {
    /*
        APP LEVEL MESSAGES
     */
    "title": {
        defaultMessage: "ARMUS MIPS"
    },
    "about": {
        defaultMessage: "About"
    },
    "themeLabel0": {
        defaultMessage: "Light Mode"
    },
    "themeLabel1": {
        defaultMessage: "Dark Mode"
    },
    "armusSupport": {
        defaultMessage: "ARMUS Support"
    },
    "logout": {
        defaultMessage: "Logout"
    },
    "errorMessage": {
        defaultMessage: "A problem has occurred, please try again later."
    },
    "apiErrorMessage": {
        defaultMessage: "Something unexpected happened with the request, Please try again in a few moments."
    },

    /*
        HOME VIEW MESSAGES
     */
    "home.breadcrumb": {
        defaultMessage: "{year} {orgName} {implName}"
    },
    "home.title": {
        defaultMessage: "{year} {orgName} {implName}"
    },
    "home.individual.tab.label": {
        defaultMessage: "Individual"
    },
    "home.group.tab.label": {
        defaultMessage: "Group"
    },
    "home.noNPIData": {
        defaultMessage: "No TIN/NPI records available."
    },
    "home.welcome": {
        defaultMessage: "Please use the table below to select which NPI/TIN you would like to submit for {year}."
    },
    "home.submissionWindowOpen": {
        defaultMessage: "You have {days} days remaining before the submission window is closed on {endDate}."
    },
    "home.submissionWindowClosed": {
        defaultMessage: "The submission window for {year} has ended. You can review your final submissions in the table below."
    },
    "home.conflict": {
        defaultMessage: "There are TIN/NPI pair conflicts."
    },
    "home.conflict.details": {
        defaultMessage: "The following TIN/NPI pairs could not be processed due to a conflict."
    },
    "home.whereAreMyProvidersContent": {
        defaultMessage: `If you don't see the expected providers in this list, please
<ol>
<li>Go to Hybrid Data Entry.</li>
<li>Go to Data Quality Report.</li>
<li>Select 'Illegal values' from the search conditions.</li>
<li>Provide the performance year for the date range.</li>
<li>Click search.</li>
<li>Look for illegal NPI or TIN values and ensure they are correct.</li>
<ol>`
    },
    "homeTable.tooltip.npiTip": {
        defaultMessage: "Click the NPI to visit CMS's website to determine eligibility for this physician."
    },
    "homeTable.tooltip.nppesTip": {
        defaultMessage: "CMS Name is the name of the provider as it appears in CMS's NPI lookup tool."
    },
    "homeTable.tooltip.scoreTip": {
        defaultMessage: "Score according to CMS at the time of submission. Scores are subject to change based on periodic policy updates, eligibility reviews, and technical integration developments."
    },
    "home.whereAreMyProviders": {
        defaultMessage: "Where are my providers?"
    },
    "homeTable.headerLabel.tin": {
        defaultMessage: "TIN"
    },
    "homeTable.headerLabel.npi": {
        defaultMessage: "NPI"
    },
    "homeTable.headerLabel.cmsName": {
        defaultMessage: "CMS Name"
    },
    "homeTable.headerLabel.registryName": {
        defaultMessage: "Registry Name"
    },
    "homeTable.headerLabel.lastSubmitted": {
        defaultMessage: "Last Submitted"
    },
    "homeTable.headerLabel.score": {
        defaultMessage: "Provisional Score"
    },
    "homeTable.editButton": {
        defaultMessage: "Edit Submission"
    },

    /*
        DRAFT VIEW MESSAGES
     */
    "draft.individual.breadcrumb": {
        defaultMessage: "TIN: {tin} NPI: {npi} - {name}"
    },
    "draft.individual.title": {
        defaultMessage: "{name}"
    },
    "draft.group.breadcrumb": {
        defaultMessage: "TIN: {tin}"
    },
    "draft.group.title": {
        defaultMessage: "TIN: {tin}"
    },
    "draft.cehrtInput.message": {
        defaultMessage: "The CMS EHR Certification Identification Number is generated by the CHPL.<br/>This is only applicable to Promoting Interoperability measurement sets."
    },
    "draft.cehrtInput.lookupLink": {
        defaultMessage: "Click here to lookup your CEHRT ID."
    },
    "draft.performancePeriodInput.message": {
        defaultMessage: "Start by selecting your performance period."
    },
    "draft.filterBar.onlySelectedButton": {
        defaultMessage: "Only Selected"
    },
    "draft.filterBar.onlyPickedButton": {
        defaultMessage: "Only Picked"
    },
    "draft.filterBar.search": {
        defaultMessage: "Search..."
    },
    "draft.filterBar.moreFiltersButton": {
        defaultMessage: "More Filters"
    },

    /*
        RECEIPT VIEW MESSAGES
     */
    "receipt.individual.breadcrumb": {
        defaultMessage: "TIN: {tin} NPI: {npi} - {name} Receipt"
    },
    "receipt.individual.title": {
        defaultMessage: "{name}"
    },
    "receipt.group.breadcrumb": {
        defaultMessage: "TIN: {tin} Receipt"
    },
    "receipt.group.title": {
        defaultMessage: "TIN: {tin}"
    },
    "receipt.subTitle": {
        defaultMessage: "Submission Results"
    },
    "receipt.processing.message": {
        defaultMessage: "Please wait while your submission is being processed.<br/>Do not navigate away from this page until the submission is complete."
    },
    "receipt.success.alert": {
        defaultMessage: "Submission Success!"
    },
    "receipt.success.message": {
        defaultMessage: "This submission was processed on {date}."
    },
    "receipt.success.disclaimer": {
        defaultMessage: "*Please note that scores are subject to change based on periodic policy updates, eligibility reviews, and technical integration developments."
    },
    "receipt.failed.alert": {
        defaultMessage: "Submission Failed!"
    },
    "receipt.failed.message": {
        defaultMessage: "There was a problem with this submission on {date}."
    },
    "receipt.failed.retryMessage": {
        defaultMessage: `Your submission could not be processed at this time. If the problem persists please contact support.<br/><br/>Would you like to try again?`
    },
    "receipt.failed.retryButton": {
        defaultMessage: "Submission Failed!"
    },

    // DASHBOARD MESSAGES
    insufficientData: {
        defaultMessage: "Not enough data available."
    },
    insufficientDataShort: {
        defaultMessage: "Insufficient Data"
    },
    smartPerformanceDescriptionOn: {
        defaultMessage: "With smart performance on, inverse measures' performance rates are shown as 100% - (performance rate). <br /> This means for all measures higher numbers are better and lower numbers are worse."
    },
    smartPerformanceDescriptionOff: {
        defaultMessage: "With smart performance off, inverse measures are shown without any adjustments. <br /> This means for inverse measures, higher numbers are worse and lower numbers are better."
    },
    decileSmartPerformanceWarning: {
        defaultMessage: "*This chart shows the performance rate as-is without any adjustments.<br/>"
    },
    decileInverseMessageOn: {
        defaultMessage: "This measure is an inverse measure so lower performance rates are better."
    },
    decileInverseMessageOff: {
        defaultMessage: "This measure shows higher performance rates as better."
    },
    caseVolumeTabTitle: {
        defaultMessage: "Case Volume"
    },
    descriptionTabTitle: {
        defaultMessage: "Measure Description"
    },
    measureGraphTabTitle: {
        defaultMessage: "Measure Graph"
    },
    cmsTabTitle: {
        defaultMessage: "CMS Measure Comparison"
    },
    calculationTabTitle: {
        defaultMessage: "{0} Calculation"
    },
    recordsTabTitle: {
        defaultMessage: "{0} Records"
    }
};

// allow custom messages to overload default messages.
const appMessages = {...defaultMessages, ...window.AppMessages};

export default appMessages;
