import React from "react";
import PropTypes from "prop-types";
import {
  Box,
} from '@mui/material';

const DataIcon = ({dataUrl = null, size = "medium", ...extra}) => {
  if(!dataUrl) {
    return null;
  }
  let height = 20;
  let width = 20;
  switch(size) {
    case "inherit":
      width = 14;
      height = 14;
      break;
    case "small":
      width = 16;
      height = 16;
      break;
    case "medium":
      width = 20;
      height = 20;
      break;
    case "large":
      width = 36;
      height = 36;
      break;
    default:
  }
  return (
    <Box sx={{
      height: height,
      width: width,
      display: "inline-block",
      textAlign: "center",
      backgroundImage: "url(" +dataUrl + ")",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "contain",
    }}>
    </Box>
  );
};

DataIcon.propTypes = {
  dataUrl: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["inherit", "small", "medium", "large"])
};
DataIcon.defaultProps = {
  size: "medium"
};
export default DataIcon;