const dataInfoHelper = (it, index, info) => {
    const ret = dataWithInfo(it, index, info.depth+1);
    if(ret === undefined || ret == null) {
        return ret;
    }
    info.totalRows += ret.info.totalRows;
    if(typeof ret.info.total === "number") {
        info.total += ret.info.total;
    }
    if (ret.info.max > info.max) {
        info.max = ret.info.max;
    }
    if (ret.info.min < info.min) {
        info.min = ret.info.min;
    }
    if (ret.info.total > info.stackedMax) {
        info.stackedMax = ret.info.total;
    }
    if (ret.info.total < info.stackedMin) {
        info.stackedMin = ret.info.total;
    }
    if (ret.info.maxDepth > info.maxDepth) {
        info.maxDepth = ret.info.maxDepth;
    }
    info.average = info.total / info.length;
    info.childLabels.push(it.label);
    return ret;
};

export const dataWithInfo = (data, index = 0, depth = 0, maintainIndex = true) => {
    let newData;
    if(data == null || data === undefined) {
        // nothing to do
        return data;
    }
    const info = {
        index: maintainIndex && data.info ? data.info.index : index, // maintain index position if already exists.
        min: Number.MAX_SAFE_INTEGER,
        max: Number.MIN_SAFE_INTEGER,
        stackedMin: Number.MAX_SAFE_INTEGER,
        stackedMax: Number.MIN_SAFE_INTEGER,
        totalRows: 0,
        total: 0,
        average: 0,
        depth: depth,
        maxDepth: depth,
        length: 0,
        childLabels: [],
        leafLabels: [],
        leafTotals: [],
        isNumeric: false,
        isLeaf: false,
    };
    if(Array.isArray(data)) {
        info.length = data.length;
        newData = data.map((it, index) => dataInfoHelper(it, index, info, maintainIndex));
    }
    else if(typeof data === "object") {
        newData = {...data};
        if(Array.isArray(newData.value)) {
            info.length = newData.value.length;
            newData.value = newData.value.map((it, index) => dataInfoHelper(it, index, info, maintainIndex));
            info.leafLabels.push(newData.value.map( it => it.info.leafLabels).flat());
            info.leafLabels = [...new Set(info.leafLabels.flat())];
            info.leafTotals = info.leafLabels.map(it => {
                const nodeTotals = newData.value.map(t => {
                   return t.info.leafTotals[t.info.leafLabels.indexOf(it)];
                });
                return nodeTotals.filter(t => t !== undefined && typeof t !== "string").reduce((a, b) => a + b, 0);
            });
        }
        else {
            info.totalRows++;
            info.total = data.value;
            info.min = data.value;
            info.max = data.value;
            info.stackedMin = data.value;
            info.stackedMax = data.value;
            info.average = data.value;
            info.childLabels.push(data.label);
            info.leafTotals.push(data.value);
            info.leafLabels.push(data.label);
            info.isNumeric = !isNaN(data.value);
            info.isLeaf = true;
        }
    }
    else {
        // data is non-array or non-object ... do nothing
        return data;
    }
    newData.info = info;
    info.leafLabels = [...new Set(info.leafLabels.flat())];
    return newData;
};

export const dataSort = (sort, data) => {
    if(typeof data === "object" && !data.info) {
        // maintain index positions and totals.
        data = dataWithInfo(data);
    }
    if(sort.by && Array.isArray(data)) {
        data = [...data].map((it,index) => ({...it, index}));
        data.sort((a,b) => {
            if(sort.by === "value") { // sort by value
                const aValue = a.info.total;
                const bValue = b.info.total;
                a.value = dataSort(sort, a.value);
                b.value = dataSort(sort, b.value);
                return sort.dir === "asc" ? aValue - bValue : bValue - aValue;
            }
            else { // dirty sort by label
                const aValue = (a.label || "").toLowerCase();
                const bValue = (b.label || "").toLowerCase();
                a.value = dataSort(sort, a.value);
                b.value = dataSort(sort, b.value);
                return  sort.dir === "asc" ? aValue > bValue ? 1 : -1 : aValue < bValue ? 1 : -1;
            }
        });
    }
    return data;
};

export const dataToTable = (data, valueLabel = "Value", realTable = false) => {
    if(typeof data === "object" && !data.info) {
        // maintain index positions and get totals.
        data = dataWithInfo(data);
    }
    // label value
    if(Array.isArray(data)) {
        const newData = data.map((d) => dataToTable(d, valueLabel, realTable));
        newData.info = data.info;
        return newData;

    }
    if(Array.isArray(data.value)) {
        const newData = {...data};
        newData.value = newData.value.map((d) => dataToTable(d, valueLabel, realTable));
        newData.value.info = data.value.info;
        return newData;
    }
    return {
        ...data,
        value: [
            {
                ...data,
                label: realTable ? data.label : valueLabel,
                value: data.value
            }
        ]
    };
};
