import Checkbox from "@mui/material/Checkbox/Checkbox";
import React from "react";
import { makeStyles } from "@mui/styles/";
import { alpha } from "@mui/material/styles/";
import IconButton from "@mui/material/IconButton";
import YesIcon from "@mui/icons-material/Check";
import NoIcon from "@mui/icons-material/Close";
import Container from "../../../../data/Container";
import MeasuresDraftStore from "../../../../data/submit/MeasuresDraftStore";

const useStyles = makeStyles((theme) => ({
    control: {
        alignItems: "center",
        margin: "auto",
        backgroundColor: alpha(theme.palette.text.primary, .04),
        borderRadius: 35,
        height: 50,
        width: 50,
        "& .MuiSvgIcon-root": {
            width: 35,
            height: 35
        },
        "&.MuiIconButton-root.Mui-disabled": {
            backgroundColor: alpha(theme.palette.text.primary, .04)
        }
    },
    yesNoControl: {
        alignItems: "center",
        margin: "auto",
        border: "2px solid transparent",
        transition: "all .2s",
        backgroundColor: alpha(theme.palette.text.primary, .04),
        borderRadius: 35,
        "&.MuiIconButton-root.MuiIconButton-sizeMedium" : {
            width: 50,
            height: 50
        },
        "& .MuiSvgIcon-root": {
            width: 40,
            height: 40
        },
        "&.MuiIconButton-root.Mui-disabled": {
            backgroundColor: alpha(theme.palette.text.primary, .04)
        }
    },
    yesChecked: {
        color: theme.palette.success.main,
        backgroundColor: alpha(theme.palette.success.main, .08),
        border: "2px solid " + theme.palette.success.main,
        "&.MuiIconButton-root.Mui-disabled": {
            border: "2px solid rgba(127,127,127, .5)"
        }
    },
    noChecked: {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, .08),
        border: "2px solid " + theme.palette.error.main,
        "&.MuiIconButton-root.Mui-disabled": {
            border: "2px solid rgba(127,127,127, .5)"
        }
    }
}));

const buildQualityControl = (classes, disabled, draftData, onChange) => {
    return (
        <Checkbox
            className={classes.control}
            disabled={disabled}
            color={"primary"}
            checked={!!draftData}
            onChange={(e) => onChange(e, e.target.checked ? true : null)}
        />
    );
};

const buildPiControl = (classes, disabled, draftData, onChange, isReview) => {
    return (
        <React.Fragment>
            {((isReview && draftData.booleanAnswer === true) || !isReview) && <IconButton
                disabled={disabled}
                className={classes.yesNoControl + " " + (draftData.booleanAnswer === true ? classes.yesChecked : "")}
                onClick={(e) => onChange(e, draftData.booleanAnswer !== true ? {...draftData, booleanAnswer: true} : null)}
            >
                <YesIcon />
            </IconButton>}
            {((isReview && draftData.booleanAnswer === false) || !isReview) && <IconButton
                disabled={disabled}
                className={classes.yesNoControl + " " + (draftData.booleanAnswer === false ? classes.noChecked : "")}
                onClick={(e) => onChange(e, draftData.booleanAnswer !== false ? {...draftData, booleanAnswer: false} : null)}
            >
                <NoIcon />
            </IconButton>}
        </React.Fragment>
    );
};

const buildIaControl = (classes, disabled, draftData, onChange) => {
    return (
        <Checkbox
            className={classes.control}
            disabled={disabled}
            color={"primary"}
            checked={!!draftData.booleanAnswer}
            onChange={(e) => onChange(e, e.target.checked ? {...draftData, booleanAnswer: true} : null)}
        />
    );
};

const BooleanControl = ({isReview, disabled, measureProxy, onChange, draftData }) => {
    const classes = useStyles();

    const category = measureProxy.isExclusion() ? "" : measureProxy.meta.category;

    switch(category) {
        case "quality":
            const value = measureProxy.getQualityMeasureData();
            if(value) {
                value.metricType = measureProxy.meta.metricType;
            }
            return buildQualityControl(classes, disabled, draftData, onChange);
        case "pi":
            if(draftData === null) {
                draftData = {
                    measure: measureProxy.meta.measureId,
                    metricType: measureProxy.meta.metricType,
                    booleanAnswer: null
                };
            }
            return buildPiControl(classes, disabled, draftData, onChange, isReview);
        case "ia":
        default:
            if(draftData === null) {
                draftData = {
                    measure: measureProxy.meta.measureId,
                    metricType: measureProxy.meta.metricType,
                    booleanAnswer: null
                };
            }
            return buildIaControl(classes, disabled, draftData, onChange);
    }
};

export default Container(
    BooleanControl,
    () => [
        MeasuresDraftStore
    ],
    (state, props) => {
        return {
            ...props,
            draftData: props.measureProxy.getValue()
        };
    }
);
