import cache from "./cache";

export const debugMessage = (type = "log", ...args) => {
    // log only once
    if (process.env.NODE_ENV === 'production') {
        return; // noop in production.
    }
    const key = cache.generateCacheKey(args);
    if(cache.hasCache(key)) {
        return;
    }
    cache.save(key, "");
    return console[type](...args);
};

export default {
    log: (...args) => debugMessage("log", ...args),
    warn: (...args) => debugMessage("warn", ...args),
    error: (...args) => debugMessage("error", ...args)
};
