import { buildAsyncActions } from "./submit/utils/utils/asyncReducer";
import Dispatcher from "./Dispatcher";

const actions = buildAsyncActions("user");
actions.LOAD = "user/START_LOADING";
actions.PING = "user/PING";
actions.LOGOUT = "user/LOGOUT";

export default actions;

export const dispatchLogout = () => {
    Dispatcher.dispatch({
        type: actions.LOGOUT
    });
};
