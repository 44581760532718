import React from "react";
import StatusNew from "./StatusNew";

const StatusComplete = (props) => {

    // for group allow editing of NPIs
    return (
        <StatusNew {...props} />
    );
};
export default StatusComplete;
