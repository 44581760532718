import React, { useEffect } from "react";
import Bar from "../Bar/Bar";
// import Dumbbell from "../Dumbbell/Dumbbell";
// import Confidence from "../Confidence/Confidence";
import Gauge from "../Gauge/Gauge";
import Line from "../Line/Line";
import Pie from "../Pie/Pie";
import Sunburst from "../Sunburst/Sunburst";
import ErrorBoundary from "../../common/ErrorBoundary";
// import BoxPlot from "../BoxPlot/Wrapper";
import Heatmap from "../Heatmap/Heatmap";
import Table, { TableDataBuilder } from "../Table/Table";
import AdvancedTable from "../Table/AdvancedTable";
import { withTheme } from "../../providers/DashboardProvider";
import Spinner from "../../common/Spinner";
import { dataSort, dataWithInfo } from "../../../lib/utils";
import { Box } from "@mui/material";
import { exportImage, exportCSV } from "../../../lib/exportHelpers";
import { buildPropTypesWithDescriptor } from "../../../lib/propTypeHelpers";
import ChartDescription from "./ChartDescription";
import makeSxStyles from "../../../lib/makeSxStyles";

const defaultChartHeight = 450;
const maximizeChartHeight = 600;
const defaultDescriptionHeight = 75;
// const defaultTableHeight = 180;

const useStyles = makeSxStyles((theme) => ({
  root: {
    fontSize: theme.chartFontSize,
    paddingBottom: theme.spacing(1),
    position: "relative",
    transition: ".25s",
    "&:hover .chart-header .chart-tools": {
      opacity: "1",
      transitionDelay: "0s",
    },
    "& .chart-inner-container": {},
    "& .chartInner-container": {
      marginBottom: theme.spacing(2),
    },
    "& .chart-header": {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    "& .chart-title": {
      fontFamily: theme.typography.fontFamily,
      fontWeight: "normal",
      whiteSpace: "nowrap",
      fontSize: "125%",
      textAlign: "center",
    },
    "& .chart-tools": {
      position: "absolute",
      top: theme.spacing(1),
      right: theme.spacing(1),
      textAlign: "right",
      transition: ".2s",
      transitionDelay: ".5s",
      opacity: 0,
    },
    "& .chart": {
      height: defaultChartHeight,
      overflow: "hidden",
      overflowY: "auto",
      overflowX: "auto",
      paddingBottom: theme.spacing(1),
      transition: ".25s",
      "&.chart-section": {
        height: "auto",
        padding: `0 ${theme.spacing(2)}px`,
      },
    },
  },
}));

const ChartResolver = ({
  isLoading,
  descriptor = {},
  data,
  onClick,
  theme,
  height = defaultChartHeight,
}) => {
  const classes = useStyles();
  const containerRef = React.useRef();
  const setStateRef = React.useRef();
  const innerRef = React.useRef();
  const cfg = {
    id: "",
    title: "",
    description: "",
    datakey: "",
    type: "",
    config: {
      allowTableView: false,
      height: null,
      allowedGraphTypes: null,
      showDescription: false,
      showHeader: true,
    },
    ...descriptor,
  };
  const [state, setState] = React.useState({
    renderAsType: cfg.type,
    pivotTable: false,
    maximize: false,
    sort: "None",
    export: "None",
    showDetails:
      cfg.config.showDescription !== undefined
        ? cfg.config.showDescription
        : !!cfg.description,
  });
  setStateRef.current = setState;

  useEffect(() => {
    if (state.export === "Image") {
      sleep(500).then(() => {
        exportImage(
          cfg.label,
          containerRef.current,
          theme.palette.background.paper
        );
        setStateRef.current((state) => ({ ...state, export: "None" }));
      });
    }
  }, [state.export, cfg.label, theme.palette.background.paper]);

  const setStateKeyValue = (key, value) => {
    setState({ ...state, [key]: value });
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const chartConfig = { ...cfg.config };
  if(chartConfig.showHeader === undefined) {
    chartConfig.showHeader = !!descriptor.label;
  }

  let chartData = !!data ? dataWithInfo(data[cfg.dataKey]) : undefined;
  const tools = [];
  let Component = null;
  let canSort = Array.isArray(chartData);
  let canShowDetails = cfg.description !== "";
  let canMaximize = false;
  let renderTypes = [];
  let canPivot = false;
  let canExport = true;
  let canReset = false;
  // const showTable = cfg.config.table && cfg.config.table.show;

  if (cfg.type !== "table" && state.renderAsType === "table") {
    chartConfig.layoutMode = "vertical";
  }
  if (state.pivotTable) {
    chartConfig.layoutMode =
      chartConfig.layoutMode !== "horizontal" ? "horizontal" : "vertical";
  }
  let infoHeight = state.showDetails ? defaultDescriptionHeight : 0;
  // let tableHeight = showTable
  //   ? cfg.config.table.height || defaultTableHeight
  //   : 0;

  const type = state.renderAsType;
  const cfgType = cfg.type;
  useEffect(
    () => {
      if (state.renderAsType !== cfg.type) {
        setState({ ...state, renderAsType: cfgType });
      }
    },
    // eslint-disable-next-line
    [cfgType]
  );
  const generalCharts = ["bar", "line", "pie", "table", "sunburst"];

  if (chartConfig.allowedGraphTypes != null) {
    if (chartConfig.allowedGraphTypes === "general") {
      renderTypes = renderTypes.concat(generalCharts);
    } else if (chartConfig.allowedGraphTypes !== false) {
      renderTypes = renderTypes.concat(chartConfig.allowedGraphTypes);
    }
  }

  switch (type) {
    case "bar":
      renderTypes =
        chartConfig.allowedGraphTypes != null
          ? renderTypes
          : ["bar", "line", "pie", "sunburst", "table"];
      canPivot = true;
      Component = Bar;
      if (
        chartConfig.layoutMode === "horizontal" &&
        chartConfig.groupMode === "grouped"
      ) {
        if (chartData && chartData.info.totalRows > 12) {
          height = Math.max(
            height,
            25 * chartData.info.totalRows,
            defaultChartHeight
          );
        }
      }
      break;
    case "line":
      // line sorting gets weird remove it for now.
      renderTypes =
        chartConfig.allowedGraphTypes != null
          ? renderTypes
          : ["bar", "line", "pie", "sunburst", "gauge", "table"];
      canSort = false;
      Component = Line;
      break;
    case "pie":
      renderTypes =
        chartConfig.allowedGraphTypes != null
          ? renderTypes
          : ["bar", "line", "pie", "sunburst", "gauge", "table"];
      Component = Pie;
      break;
    case "heatmap":
      renderTypes =
        chartConfig.allowedGraphTypes != null
          ? renderTypes
          : [cfg.type, "table"];
      canSort = false;
      Component = Heatmap;
      break;
    case "table":
      renderTypes =
        chartConfig.allowedGraphTypes != null
          ? renderTypes
          : cfg.type !== type
          ? [cfg.type, "table"]
          : [];
      Component = Table;
      chartConfig.layoutMode =
        cfg.type !== type
          ? state.pivotTable
            ? "horizontal"
            : "vertical"
          : chartConfig.layoutMode;
      canPivot = true;
      break;
      case "advancedTable":
        Component = AdvancedTable;
        break;
    case "sunburst":
      renderTypes =
        chartConfig.allowedGraphTypes != null
          ? renderTypes
          : cfg.type !== type
          ? [cfg.type, "sunburst", "table"]
          : ["sunburst", "table"];
      Component = Sunburst;
      break;
    case "gauge":
      renderTypes =
        chartConfig.allowedGraphTypes != null
          ? renderTypes
          : ["bar", "line", "pie", "gauge", "sunburst", "table"];
      canPivot = true;
      Component = Gauge;
      canSort = false;
      break;
    default:
      renderTypes = [];
      canMaximize = false;
      canPivot = false;
      canSort = false;
      Component = buildContentComponent(`Unknown chart type "${type}".`);
  }

  if (canMaximize) {
    tools.push({
      key: "maximize",
      label: state.maximize ? "Minimize" : "Maximize",
      icon: state.maximize ? "fullscreen_exit" : "fullscreen",
      value: state.maximize,
      onClick: (key, value) => {
        setStateKeyValue(key, !value);
      },
    });
    if (state.maximize || state.export === "Image") {
      if (height < maximizeChartHeight) {
        height =
          type === "table"
            ? innerRef.current.scrollHeight
            : maximizeChartHeight;
      }
    }
  }

  if (false && canPivot) {
    tools.push({
      key: "pivotTable",
      label: "Pivot",
      icon: "rotate_90_degrees_ccw",
      value: state.pivotTable,
      onClick: (key, value) => {
        setStateKeyValue(key, !value);
      },
    });
  }
  if (false && renderTypes.length > 1) {
    // disabled for now
    tools.push({
      key: "renderAsType",
      label: "Chart Type",
      value: state.renderAsType,
      icon: "bar_chart",
      options: renderTypes,
      onClick: (key, value) => {
        setStateKeyValue(key, value);
      },
    });
    chartConfig.type = state.renderAsType;
  }
  if (canSort) {
    tools.push({
      key: "sort",
      label: "Sort By",
      value: state.sort,
      icon: "sort",
      options: [
        "None",
        "Label Ascending",
        "Label Descending",
        "Value Ascending",
        "Value Descending",
      ],
      onClick: (key, value) => {
        setStateKeyValue(key, value);
      },
    });
  }
  if (canReset) {
    tools.push({
      key: "reset",
      label: "Reset",
      icon: "restore_outlined_icon",
      onClick: (key, value) => {
        setStateKeyValue(key, value);
      },
    });
  }

  if (Array.isArray(chartData)) {
    const sort = { by: "", dir: "asc" };
    if (canSort && state.sort !== "None") {
      chartConfig.flatten = true;
      const parts = state.sort.split(" ");
      sort.by = parts[0].toLowerCase();
      sort.dir = parts[1].indexOf("Desc") !== -1 ? "desc" : "asc";
    }
    chartData = dataSort(sort, chartData);
  }

  if (!chartData && type !== "section") {
    Component = buildContentComponent("No data to show.");
  }

  if (canExport) {
    tools.push({
      key: "export",
      label: "Export",
      icon: "save_alt",
      options: ["CSV", "Image"],
      value: state.export,
      onClick: (key, value) => {
        if (value === "CSV") {
          const buildTableContents = new TableDataBuilder();
          const tableContents = buildTableContents.build({
            config: chartConfig,
            data: chartData,
          });
          exportCSV(cfg.label, tableContents);
        } else {
          setStateKeyValue(key, value);
        }
      },
    });
  }

  if (canShowDetails) {
    tools.push({
      key: "showDetails",
      label: state.showDetails ? "Hide Details" : "Show Details",
      icon: "info_outlined",
      value: state.maximize || state.showDetails,
      onClick: (key, value) => {
        setStateKeyValue(key, !value);
      },
    });
  }

  return (
    <Box className={`chart-container`} sx={classes.root}>
      <Box className="chart-inner-container" ref={containerRef}>
      {chartConfig.showHeader && (
        <Box className="chart-header">
          <Box className="chart-title" title={cfg.description}>
            {cfg.label} &nbsp;
          </Box>
        </Box>
      )}
      {Component && (
        <Box
          className={`chart chart-${type}`}
          style={{ height: height === "auto" ? "auto" : height + (chartConfig.showHeader ? 0 : 22.5) + (defaultDescriptionHeight - infoHeight) }}
          ref={innerRef}
        >
          <ErrorBoundary>
            {isLoading && type !== "section" ? (
              <Spinner variant={"linear"} />
            ) : (
              <Component
                key={descriptor.id}
                isLoading={isLoading}
                descriptor={{
                  ...descriptor,
                  config: chartConfig,
                }}
                data={chartData}
                onClick={onClick}
              />
            )}
          </ErrorBoundary>
        </Box>
      )}
      <ChartDescription description={cfg.description} height={infoHeight} />
    </Box>
  </Box>);
};
ChartResolver.propTypes = buildPropTypesWithDescriptor();
export default withTheme(ChartResolver);

const buildContentComponent = (children, padding = 2) => {
  const GenericChartContent = () => (
    <Box pl={padding} pr={padding} p={padding}>
      {children}
    </Box>
  );
  return GenericChartContent;
};
