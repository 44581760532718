import moment from 'moment-timezone';

const baseEl = window.document.querySelector('base');
let basePath = ((baseEl && baseEl.href) || '').replace(window.location.origin, '');
if(process.env.NODE_ENV === "development") {
    // context path for local dev is always the root.
    baseEl.href = "/";
    basePath = "/";
}
export const contextPath = basePath;

// these config defaults will be overloaded by "window.AppConfig" which gets
// set by config "public/config.js" included in the index.html file.

const defaultConfig = {
    contextPath: contextPath,
    endpoints: {
        apiRoot: "api/",
        login: "api/",
        logout: "api/logout",
        mock: "",
        support: "https://armussupport.zendesk.com",
        cmsRoot: "https://qpp.cms.gov",
        cehrtId: "https://chpl.healthit.gov/#/search",
        CMSApi: "https://preview.qpp.cms.gov/api/"
    },
    analytics: {},
    cache: {
        useCache: true
    },
    session: {
        timeout: 45 * 60 * 1000,
        interval: 5 * 60 * 1000,
        keepAlive: true,
        autoLogout: true,
        CSRF_COOKIE_KEY: "XSRF-TOKEN",
        CSRF_POST_KEY: "_csrf"
    },
    ui: {
        dateFormat: "MM/DD/YYYY",
        dateTimeFormat: "MM/DD/YYYY h:mm A",
        timeZoneName: undefined,
        signupEnabled: true,
        verificationEnabled: true,
        dashboardEnabled: true,
        submitEnabled: true,
        signupClosedBanner: false
    },
    mocks: {}
};

const config = {...defaultConfig, ...window.AppConfig};

moment.tz.setDefault(config.ui.timeZoneName || Intl.DateTimeFormat().resolvedOptions().timeZone);
moment.defaultFormat = config.ui.dateFormat || moment.defaultFormat;

if(process.env.NODE_ENV === "development") {
    config.session.keepAlive = false;
    config.session.autoLogout = false;
    config.cache.useCache = true;

    config.endpoints.mock = "/static/mocks";
    config.mocks.useMocks = true;
    config.mocks.showMockMenu = true;
    config.mocks.loadingDelay = 1000; // between 0 - 1000 ms

    // For local server-less development
    // initialize with a mockset selected.
    // or use ?mockset=trekkie
    config.mocks.useMocksetKey = "admin";
}

export default config;
